import React, { useEffect, useState } from "react";
import "./DashboardWallet.css";
import WalletBalanceDisplay from "../../components/DashboardWalletsComponents/WalletBalanceDisplay";
import DepositModalComp from "../../components/DashboardWalletsComponents/DepositModalComp";
import WithdrawModalComp from "../../components/DashboardWalletsComponents/WithdrawModalComp";
import { QRCode } from "react-qrcode-logo";
import DepositEgc from "../../components/DashboardWalletsComponents/depositEgc";
import DepositEgcFromUser from "../../components/DashboardWalletsComponents/depositEgcFromUser";
import SendEgc from "../../components/DashboardWalletsComponents/sendEgcInternal";
import SendEgcInternal from "../../components/DashboardWalletsComponents/sendEgcInternal";
import SendEgcExternal from "../../components/DashboardWalletsComponents/sendEgcExternal";
import DepositNairaUser from "../../components/DashboardWalletsComponents/depositNairaUser";
import DepositNairaFromBank from "../../components/DashboardWalletsComponents/depositNairaFromBank";
import WithdrawNairaToBank from "../../components/DashboardWalletsComponents/withdrawNairaToBank";
import WithdrawNairaToUser from "../../components/DashboardWalletsComponents/withdrawNairaToUser";
import DepositUsd from "../../components/DashboardWalletsComponents/depositUsd";
import DepositUsdFromUser from "../../components/DashboardWalletsComponents/depositUsdFromUser";
import SendUsdInternal from "../../components/DashboardWalletsComponents/sendUsdInternal";
import SendUsdExternal from "../../components/DashboardWalletsComponents/sendUsdExternal";
import {
  TablePagination,
  Table,
} from "../../components/CommonUI/Tables/TableComp";
import { FETCH_WALLET_TRANSACTIONS } from "../../services/finance_services";
import Staticdata from "../../assets/json/Static";
import { useSelector } from "react-redux";

const DashboardWallets = () => {
  const { user } = useSelector((state) => state.auth);
  const { data, loading } = useSelector((state) => state.wallet);
  const [nairaBalance, setNairaBalance] = useState("0");
  const [egaxBalance, setEgaxBalance] = useState("0");
  const [egaxBalanceUsd, setEgaxBalanceUsd] = useState("0");
  const [usdBlockchainDeposit, setUsdBlockchainDeposit] = useState(false);
  const [usdBlockchainWithdrawal, setUsdBlockchainWithdrawal] = useState(false);
  const [usdUserWithdrawal, setUsdUserWithdrawal] = useState(false);
  const [usdUserDeposit, setUsdUserDeposit] = useState(false);
  const [egaxBlockchainDeposit, setEGAXBlockchainDeposit] = useState(false);
  const [egaxBlockchainWithdrawal, setEGAXBlockchainWithdrawal] =
    useState(false);
  const [egaxUserWithdrawal, setEGAXUserWithdrawal] = useState(false);
  const [egaxUserDeposit, setEGAXUserDeposit] = useState(false);
  const [estaBlockchainDeposit, setESTABlockchainDeposit] = useState(false);
  const [estaBlockchainWithdrawal, setESTABlockchainWithdrawal] =
    useState(false);
  const [estaUserWithdrawal, setESTAUserWithdrawal] = useState(false);
  const [estaUserDeposit, setESTAUserDeposit] = useState(false);
  const [depositMoneyNaira, setDepositMoneyNaira] = useState(false);
  const [depositMoneyUSD, setDepositMoneyUSD] = useState(false);
  const [depositMoneyEGAX, setDepositMoneyEGAX] = useState(false);
  const [depositMoneyESTA, setDepositMoneyESTA] = useState(false);
  const [depositMoneyNairaBank, setDepositMoneyNairaBank] = useState(false);
  const [depositMoneyNairaUser, setDepositMoneyNairaUser] = useState(false);
  const [withdrawMoneyNaira, setWithdrawMoneyNaira] = useState(false);
  const [withdrawMoneyUSD, setWithdrawMoneyUSD] = useState(false);
  const [withdrawMoneyEGAX, setWithdrawMoneyEGAX] = useState(false);
  const [withdrawMoneyESTA, setWithdrawMoneyESTA] = useState(false);
  const [nairaBankWithdrawal, setNairaBankWithdrawal] = useState(false);
  const [nairaUserWithdrawal, setNairaUserWithdrawal] = useState(false);
  const [contentLoadingTable, setContentLoadingTable] = useState(true);
  const [usdtBalance, setUsdtBalance] = useState("0");
  const [estaBalance, setEstaBalance] = useState("0");
  const [tableData, setTableData] = useState([]);

  const ToggleDepositMoneyNairaModal = () => {
    setDepositMoneyNaira(!depositMoneyNaira);
  };

  const ToggleWithdrawMoneyNairaModal = () => {
    setWithdrawMoneyNaira(!withdrawMoneyNaira);
  };

  const ToggleDepositMoneyUSDModal = () => {
    setDepositMoneyUSD(!depositMoneyUSD);
  };
  const ToggleDepositMoneyEGAXModal = () => {
    setDepositMoneyEGAX(!depositMoneyEGAX);
  };
  const ToggleDepositMoneyESTAModal = () => {
    setDepositMoneyESTA(!depositMoneyESTA);
  };

  const ToggleWithdrawMoneyUSDModal = () => {
    setWithdrawMoneyUSD(!withdrawMoneyUSD);
  };
  const ToggleWithdrawMoneyEGAXModal = () => {
    setWithdrawMoneyEGAX(!withdrawMoneyEGAX);
  };
  const ToggleWithdrawMoneyESTAModal = () => {
    setWithdrawMoneyESTA(!withdrawMoneyESTA);
  };

  const ToggleUSDBlockchainDepositModal = () => {
    setUsdBlockchainDeposit(!usdBlockchainDeposit);
    setDepositMoneyUSD(!depositMoneyUSD);
  };

  const ToggleUSDBlockchainWithdrawModal = () => {
    setUsdBlockchainWithdrawal(!usdBlockchainWithdrawal);
    setWithdrawMoneyUSD(!withdrawMoneyUSD);
  };

  const ToggleUSDUserDepositModal = () => {
    setUsdUserDeposit(!usdUserDeposit);
    setDepositMoneyUSD(!depositMoneyUSD);
  };

  const ToggleUSDUserWithdrawtModal = () => {
    setUsdUserWithdrawal(!usdUserWithdrawal);
    setWithdrawMoneyUSD(!withdrawMoneyUSD);
  };
  const ToggleEGAXBlockchainDepositModal = () => {
    setEGAXBlockchainDeposit(!egaxBlockchainDeposit);
    setDepositMoneyEGAX(!depositMoneyEGAX);
  };

  const ToggleEGAXBlockchainWithdrawModal = () => {
    setEGAXBlockchainWithdrawal(!egaxBlockchainWithdrawal);
    setWithdrawMoneyEGAX(!withdrawMoneyEGAX);
  };

  const ToggleEGAXUserDepositModal = () => {
    setEGAXUserDeposit(!egaxUserDeposit);
    setDepositMoneyEGAX(!depositMoneyEGAX);
  };

  const ToggleEGAXUserWithdrawtModal = () => {
    setEGAXUserWithdrawal(!egaxUserWithdrawal);
    setWithdrawMoneyEGAX(!withdrawMoneyEGAX);
  };
  const ToggleESTABlockchainDepositModal = () => {
    setESTABlockchainDeposit(!estaBlockchainDeposit);
    setDepositMoneyESTA(!depositMoneyESTA);
  };

  const ToggleESTABlockchainWithdrawModal = () => {
    setESTABlockchainWithdrawal(!estaBlockchainWithdrawal);
    setWithdrawMoneyESTA(!withdrawMoneyESTA);
  };

  const ToggleESTAUserDepositModal = () => {
    setESTAUserDeposit(!estaUserDeposit);
    setDepositMoneyESTA(!depositMoneyESTA);
  };

  const ToggleESTAUserWithdrawtModal = () => {
    setESTAUserWithdrawal(!estaUserWithdrawal);
    setWithdrawMoneyESTA(!withdrawMoneyESTA);
  };

  useEffect(() => {
    // console.log(data);
    for (let i = 0; i < data.length; i++) {
      switch (data[i].name) {
        case "Egochain":
          setEgaxBalance(data[i]?.value === null ? "0" : data[i]?.value);
          setEgaxBalanceUsd(data[i]?.usd_bal === null ? "0" : data[i]?.usd_bal);
          break;
        case "Dollar":
          // console.log(data[i]?.value, "jjjjjjj");
          setUsdtBalance(data[i]?.value === null ? "0" : data[i]?.value);
          break;
        case "Ego-station-eth":
          setEstaBalance(data[i]?.value === null ? "0" : data[i]?.value);
          break;
      }
    }
  }, [data, user]);
  const fetchWalletTransactions = async () => {
    setContentLoadingTable(true);
    const response = await FETCH_WALLET_TRANSACTIONS();
    if (response.success === true) {
      setContentLoadingTable(false);
      setTableData(response.data);
      // console.log("====================================");
      // console.log(response.data);
      // console.log("====================================");
    } else {
      setContentLoadingTable(true);
      //  setTableData([]);
    }
    //// // console.logog(response.data);
    //// // console.logog(response);
  };
  useEffect(() => {
    fetchWalletTransactions();
  }, []);

  return (
    <section className="DashboardWalletsSection">
      <div className="container">
        <div className="DashboardWalletsDiv">
          <div className="DashboardWalletsDiv_body">
            <WalletBalanceDisplay
              walletBal={parseFloat(
                parseFloat(
                  parseFloat(egaxBalanceUsd) * parseFloat(egaxBalance)
                ) + parseFloat(usdtBalance)
              ).toFixed(4)}
              walletsymbol={"  usd"}
              loading={loading}
            />
            <div className="DashboardWalletsDiv_area_coins_div">
              <div className="DashboardWalletsDiv_area_coins_div_cont1">
                Tokens
              </div>
              <div className="DashboardWalletsDiv_area_coins_div_cont2">
                <div className="DashboardWalletsDiv_area_coins_div_cont2_head">
                  <div className="DashboardWalletsDiv_area_coins_div_cont2_head_1_first">
                    Coins
                  </div>
                  <div className="DashboardWalletsDiv_area_coins_div_cont2_head_1">
                    Amount
                  </div>
                  <div className="DashboardWalletsDiv_area_coins_div_cont2_head_1">
                    Amount (USD)
                  </div>
                  <div className="DashboardWalletsDiv_area_coins_div_cont2_head_1_last">
                    Actions
                  </div>
                </div>
                <div className="DashboardWalletsDiv_area_coins_div_cont2_body">
                  <div className="DashboardWalletsDiv_area_coins_div_cont2_body_1">
                    <div className="DashboardWalletsDiv_area_coins_div_cont2_body_1_cont1">
                      <img
                        src="/img/egax_logo.png"
                        alt=""
                        className="DashboardWalletsDiv_area_coins_div_cont2_body_1_cont1_img"
                      />
                      <div className="DashboardWalletsDiv_area_coins_div_cont2_body_1_cont1_txt">
                        <div className="DashboardWalletsDiv_area_coins_div_cont2_body_1_cont1_txt_title">
                          EGAX
                        </div>
                        <div className="DashboardWalletsDiv_area_coins_div_cont2_body_1_cont1_txt_para">
                          Ego Chain
                        </div>
                      </div>
                    </div>
                    <div className="DashboardWalletsDiv_area_coins_div_cont2_body_1_cont1_div1">
                      {parseFloat(egaxBalance).toFixed(4)}
                    </div>
                    <div className="DashboardWalletsDiv_area_coins_div_cont2_body_1_cont1_div1">
                      ${" "}
                      {parseFloat(
                        parseFloat(egaxBalance) * parseFloat(egaxBalanceUsd)
                      ).toFixed(4)}
                    </div>
                    <div className="DashboardWalletsDiv_area_coins_div_cont2_body_1_cont1_div1_actions">
                      <button
                        className="DashboardWalletsDiv_area_coins_div_cont2_body_1_cont1_div1_actions_btn1"
                        // disabled
                        onClick={ToggleDepositMoneyEGAXModal}
                      >
                        Deposit
                      </button>
                      <button
                        className="DashboardWalletsDiv_area_coins_div_cont2_body_1_cont1_div1_actions_btn2"
                        onClick={ToggleWithdrawMoneyEGAXModal}
                      >
                        Withdraw
                      </button>

                      <a href="/">
                        <button className="DashboardWalletsDiv_area_coins_div_cont2_body_1_cont1_div1_actions_btn3">
                          Swap
                        </button>
                      </a>
                    </div>
                  </div>
                  {/* ========= */}
                  {/* ========= */}
                  {/* ========= */}
                  {/* ========= */}
                  <div className="DashboardWalletsDiv_area_coins_div_cont2_body_1">
                    <div className="DashboardWalletsDiv_area_coins_div_cont2_body_1_cont1">
                      <img
                        src="/img/usdt_img.png"
                        alt=""
                        className="DashboardWalletsDiv_area_coins_div_cont2_body_1_cont1_img"
                      />
                      <div className="DashboardWalletsDiv_area_coins_div_cont2_body_1_cont1_txt">
                        <div className="DashboardWalletsDiv_area_coins_div_cont2_body_1_cont1_txt_title">
                          USDT
                        </div>
                        <div className="DashboardWalletsDiv_area_coins_div_cont2_body_1_cont1_txt_para">
                          Tether USDT
                        </div>
                      </div>
                    </div>
                    <div className="DashboardWalletsDiv_area_coins_div_cont2_body_1_cont1_div1">
                      {parseFloat(usdtBalance).toFixed(4)}
                    </div>
                    <div className="DashboardWalletsDiv_area_coins_div_cont2_body_1_cont1_div1">
                      $ {parseFloat(usdtBalance).toFixed(4)}
                    </div>
                    <div className="DashboardWalletsDiv_area_coins_div_cont2_body_1_cont1_div1_actions">
                      <button
                        className="DashboardWalletsDiv_area_coins_div_cont2_body_1_cont1_div1_actions_btn1"
                        onClick={ToggleDepositMoneyUSDModal}
                      >
                        Deposit
                      </button>
                      <button
                        className="DashboardWalletsDiv_area_coins_div_cont2_body_1_cont1_div1_actions_btn2"
                        onClick={ToggleWithdrawMoneyUSDModal}
                      >
                        Withdraw
                      </button>
                      <a href="/">
                        <button className="DashboardWalletsDiv_area_coins_div_cont2_body_1_cont1_div1_actions_btn3">
                          Swap
                        </button>
                      </a>
                    </div>
                  </div>
                  {/* ========= */}
                  {/* ========= */}
                  {/* ========= */}
                  {/* ========= */}
                  <div className="DashboardWalletsDiv_area_coins_div_cont2_body_1">
                    <div className="DashboardWalletsDiv_area_coins_div_cont2_body_1_cont1">
                      <img
                        src="/img/esta_logo.jpeg"
                        alt=""
                        className="DashboardWalletsDiv_area_coins_div_cont2_body_1_cont1_img"
                      />
                      <div className="DashboardWalletsDiv_area_coins_div_cont2_body_1_cont1_txt">
                        <div className="DashboardWalletsDiv_area_coins_div_cont2_body_1_cont1_txt_title">
                          ESTA
                        </div>
                        <div className="DashboardWalletsDiv_area_coins_div_cont2_body_1_cont1_txt_para">
                          Egostation
                        </div>
                      </div>
                    </div>
                    <div className="DashboardWalletsDiv_area_coins_div_cont2_body_1_cont1_div1">
                      {parseFloat(estaBalance).toFixed(4)}
                    </div>
                    <div className="DashboardWalletsDiv_area_coins_div_cont2_body_1_cont1_div1">
                      $ {parseFloat(estaBalance).toFixed(4)}
                    </div>
                    <div className="DashboardWalletsDiv_area_coins_div_cont2_body_1_cont1_div1_actions">
                      <button
                        className="DashboardWalletsDiv_area_coins_div_cont2_body_1_cont1_div1_actions_btn1"
                        onClick={ToggleDepositMoneyESTAModal}
                      >
                        Deposit
                      </button>
                      <button
                        className="DashboardWalletsDiv_area_coins_div_cont2_body_1_cont1_div1_actions_btn2"
                        onClick={ToggleWithdrawMoneyESTAModal}
                      >
                        Withdraw
                      </button>
                      <a href="/">
                        <button className="DashboardWalletsDiv_area_coins_div_cont2_body_1_cont1_div1_actions_btn3">
                          Swap
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="DashboardWalletsDiv_area3">
              <Table
                tableTitle={"Wallet Transactions"}
                TableData={tableData
                  .filter((data) => data.type !== "PURCHASE")
                  .slice(0, 7)}
                contentLoading={contentLoadingTable}
                dummyData={Staticdata.productsTableData.slice(0, 8)}
                userName={user?.username}
              />
            </div>
          </div>

          {/* ============= */}
          {/* ============= */}
          {/* ============= */}
          {/* ============= */}
          {/* ============= */}
        </div>
      </div>
      <div class="background_gradient-parent">
        <div class="background_gradient"></div>
      </div>
      <img
        src="/img/dark_home_sec_bg.svg"
        alt=""
        className="home_div_section1_bg"
      />
      {depositMoneyUSD ? (
        <DepositModalComp
          symbol={"USD"}
          DynamicFunc1={ToggleUSDBlockchainDepositModal}
          DynamicFunc2={ToggleUSDUserDepositModal}
          DynamicPara1={"Add funds directly from a blockachain account "}
          DynamicTitle1={"Deposit via blockchain "}
          closeModal={ToggleDepositMoneyUSDModal}
          DepositModaldiv={"USD"}
        />
      ) : null}
      {withdrawMoneyUSD ? (
        <WithdrawModalComp
          symbol={"USDT"}
          DynamicFunc1={ToggleUSDBlockchainWithdrawModal}
          DynamicFunc2={ToggleUSDUserWithdrawtModal}
          DynamicPara1={
            "Transfer your USDT funds with an array of swift and efficient transfer options!"
          }
          DynamicTitle1={"USDT Wallet Withdrawal "}
          closeModal={ToggleWithdrawMoneyUSDModal}
          WithdrawModaldiv={"USDT"}
        />
      ) : null}
      {depositMoneyEGAX ? (
        <DepositModalComp
          symbol={"EGAX"}
          DynamicFunc1={ToggleEGAXBlockchainDepositModal}
          DynamicFunc2={ToggleEGAXUserDepositModal}
          DynamicPara1={"Add funds directly from a blockachain account "}
          DynamicTitle1={"Deposit via blockchain "}
          closeModal={ToggleDepositMoneyEGAXModal}
          DepositModaldiv={"EGAX"}
        />
      ) : null}
      {depositMoneyESTA ? (
        <DepositModalComp
          symbol={"ESTA"}
          DynamicFunc1={ToggleESTABlockchainDepositModal}
          DynamicFunc2={ToggleESTAUserDepositModal}
          DynamicPara1={"Add funds directly from a blockachain account "}
          DynamicTitle1={"Deposit via blockchain "}
          closeModal={ToggleDepositMoneyESTAModal}
          DepositModaldiv={"ESTA"}
        />
      ) : null}
      {withdrawMoneyEGAX ? (
        <WithdrawModalComp
          symbol={"EGAX"}
          DynamicFunc1={ToggleEGAXBlockchainWithdrawModal}
          DynamicFunc2={ToggleEGAXUserWithdrawtModal}
          DynamicPara1={
            "Transfer your EGAX funds with an array of swift and efficient transfer options!"
          }
          DynamicTitle1={"EGAX Wallet Withdrawal "}
          closeModal={ToggleWithdrawMoneyEGAXModal}
          WithdrawModaldiv={"EGAX"}
        />
      ) : null}
      {withdrawMoneyESTA ? (
        <WithdrawModalComp
          symbol={"ESTA"}
          DynamicFunc1={ToggleESTABlockchainWithdrawModal}
          DynamicFunc2={ToggleESTAUserWithdrawtModal}
          DynamicPara1={
            "Transfer your ESTA funds with an array of swift and efficient transfer options!"
          }
          DynamicTitle1={"ESTA Wallet Withdrawal "}
          closeModal={ToggleWithdrawMoneyESTAModal}
          WithdrawModaldiv={"ESTA"}
        />
      ) : null}
      {usdBlockchainDeposit ? (
        <DepositUsd
          symbol="USD"
          symbol2="USDT"
          img="/img/usdt_img.png"
          tokenName="Tether USD"
          ToggleEgcBlockchainDepositModal={ToggleUSDBlockchainDepositModal}
        />
      ) : null}
      {usdBlockchainWithdrawal ? (
        <SendUsdExternal
          symbol="USD"
          symbol2="USDT"
          ToggleEgcBlockchainWithdrawModal={ToggleUSDBlockchainWithdrawModal}
          balance={parseFloat(usdtBalance).toFixed(4)}
        />
      ) : null}
      {usdUserDeposit ? (
        <DepositUsdFromUser
          symbol2="USDT"
          img="/img/usdt_img.png"
          tokenName="Tether USD"
          ToggleEgcUserDepositModal={ToggleUSDUserDepositModal}
        />
      ) : null}
      {usdUserWithdrawal ? (
        <SendUsdInternal
          symbol="USD"
          symbol2="USDT"
          img="/img/usdt_img.png"
          tokenName="Tether USDT"
          ToggleEgcUserWithdrawtModal={ToggleUSDUserWithdrawtModal}
          balance={parseFloat(usdtBalance).toFixed(4)}
        />
      ) : null}
      {egaxBlockchainDeposit ? (
        <DepositUsd
          symbol="EGAX"
          symbol2="EGAX"
          img="/img/egax_logo.png"
          tokenName="Ego Chain"
          ToggleEgcBlockchainDepositModal={ToggleEGAXBlockchainDepositModal}
        />
      ) : null}
      {egaxBlockchainWithdrawal ? (
        <SendUsdExternal
          symbol="EGAX"
          symbol2="EGAX"
          ToggleEgcBlockchainWithdrawModal={ToggleEGAXBlockchainWithdrawModal}
          balance={parseFloat(egaxBalance).toFixed(4)}
        />
      ) : null}
      {egaxUserDeposit ? (
        <DepositUsdFromUser
          symbol2="EGAX"
          img="/img/egax_logo.png"
          tokenName="Ego Chain"
          ToggleEgcUserDepositModal={ToggleEGAXUserDepositModal}
        />
      ) : null}
      {egaxUserWithdrawal ? (
        <SendUsdInternal
          symbol="EGAX"
          symbol2="EGAX"
          img="/img/egax_logo.png"
          tokenName="Ego Chain"
          ToggleEgcUserWithdrawtModal={ToggleEGAXUserWithdrawtModal}
          balance={parseFloat(egaxBalance).toFixed(4)}
        />
      ) : null}

      {estaBlockchainDeposit ? (
        <DepositUsd
          symbol="ESTA"
          symbol2="ESTA"
          img="/img/esta_logo.jpeg"
          tokenName="Egostation"
          ToggleEgcBlockchainDepositModal={ToggleESTABlockchainDepositModal}
        />
      ) : null}
      {estaBlockchainWithdrawal ? (
        <SendUsdExternal
          symbol="ESTA"
          symbol2="ESTA"
          ToggleEgcBlockchainWithdrawModal={ToggleESTABlockchainWithdrawModal}
          balance={parseFloat(estaBalance).toFixed(4)}
        />
      ) : null}
      {estaUserDeposit ? (
        <DepositUsdFromUser
          symbol2="ESTA"
          img="/img/esta_logo.jpeg"
          tokenName="Egostation"
          ToggleEgcUserDepositModal={ToggleESTAUserDepositModal}
        />
      ) : null}
      {estaUserWithdrawal ? (
        <SendUsdInternal
          symbol="ESTA"
          symbol2="ESTA"
          img="/img/esta_logo.jpeg"
          tokenName="Egostation"
          ToggleEgcUserWithdrawtModal={ToggleESTAUserWithdrawtModal}
          balance={parseFloat(estaBalance).toFixed(4)}
        />
      ) : null}
    </section>
  );
};

export default DashboardWallets;
