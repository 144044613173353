import React, { useEffect, useState } from "react";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  SEND_CRYPTO_FUNDS_EXTERNAL,
  SEND_CRYPTO_FUNDS_INTERNAL,
} from "../../services/finance_services";
import WebPin from "../../components/WebPin/WebPin";
import { ToastContainer, toast } from "react-toastify";
import SuccessModal from "../../components/SuccessModal/SuccessModal";
const SendUsdExternal = ({
  symbol,
  symbol2,
  ToggleEgcBlockchainWithdrawModal,
  balance,
}) => {
  const [loading, setLoading] = useState(false);
  const [pinModal, setPinModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [pin, setPin] = useState("");
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [networkDiv, setNetworkDiv] = useState(false);
  const Network = [
    {
      id: "1",
      network: "BNB Smart Chain (BEP20)",
      name: "Binance Smart Chain",
      img: "/img/bsc_icon.png",
    },
    {
      id: "2",
      network: "Egochain",
      name: "Egochain",
      img: "/img/egax_logo.png",
    },
  ];
  const Network2 = [
    {
      id: "1",
      network: "Ethereum (ERC20)",
      name: "Ethereum",
      img: "/img/eth_icon.png",
    },
    {
      id: "2",
      network: "Egochain",
      name: "Egochain",
      img: "/img/egax_logo.png",
    },
  ];
  const [payload, setPayload] = useState({
    symbol: symbol2 === "EGAX" ? "EGAX" : "",
    username_email: "",
    amount: "",
    type: "external_send",
    network: symbol2 === "EGAX" ? "Egochain" : "",
    wallet_address: "",
  });

  console.log("====================================");
  console.log(payload);
  console.log("====================================");

  // network: "BNB Smart Chain (BEP20)",
  const toggleNetworkDiv = () => {
    setNetworkDiv(!networkDiv);
  };
  const selectNetwork = (value) => {
    setPayload({
      ...payload,
      network: value.network,
      symbol:
        value.network === "Ethereum (ERC20)" && symbol2 === "ESTA"
          ? "ESTA"
          : value.network === "Egochain" && symbol2 === "ESTA"
          ? "ESTAE"
          : value.network === "BNB Smart Chain (BEP20)" && symbol2 === "USDT"
          ? "USD"
          : value.network === "Egochain" && symbol2 === "USDT"
          ? "USDE"
          : symbol,
    });
    setSelectedNetwork(value);
    toggleNetworkDiv();
    console.log("====================================");
    console.log(value);
    console.log("====================================");
  };

  const sendFunds = async () => {
    console.log("====================================");
    console.log(payload);
    console.log("====================================");
    setLoading(true);
    const response = await SEND_CRYPTO_FUNDS_EXTERNAL({
      ...payload,
      pin_code: pin,
    });

    console.log(response);
    setLoading(false);
    console.log(response.data);
    console.log(response);
    if (!response.success) {
      setPinModal(false);
      toast.warn(response.data.errorMessage);
      // alert(response.data.errorMessage);
      return;
    }
    setSuccessMsg("Transaction succesful");
    setPinModal(false);
    // toast.success("Transaction successful");
    setSuccessModal(true);
  };

  const processSend = () => {
    const { wallet_address, amount } = payload;
    if (wallet_address === "" || amount === "") {
      toast.warn("Some fields are empty");
      return;
    }
    setPinModal(true);
  };

  useEffect(() => {
    const { wallet_address, amount, symbol, network, type, username_email } =
      payload;

    console.log("====================================");
    console.log(wallet_address, amount, symbol, network, type, username_email);
    console.log("====================================");
  }, [payload]);

  const handleOnChange = (e) => {
    const { id, value } = e.target;
    setPayload({ ...payload, [id]: value });
  };
  const AddMax = () => {
    setPayload({ ...payload, amount: balance });
  };
  return (
    <div className="depositMoneyDiv">
      <div className="depositMoneyDiv_cont">
        <ArrowBackOutlinedIcon
          className="depositMoneyDiv_icon"
          onClick={ToggleEgcBlockchainWithdrawModal}
        />
        <div className="depositMoneyDiv_cont_1">
          <div className="depositMoneyDiv_cont_title_cont">
            <div className="depositMoneyDiv_cont_title_cont_title">
              Send {symbol2}
            </div>
            <div className="depositMoneyDiv_cont_title_cont_para">
              Send funds directly to a blockchain account
            </div>
          </div>
          <div className="depositMoneyDiv_cont_body">
            <div className="depositMoneyDiv_cont_body_wallet_addr_divb">
              <div className="depositMoneyDiv_cont_body_wallet_addr_div_title">
                WalletAddress:
              </div>
              <input
                type="text"
                placeholder="0xXXXXXXXXXXXXXXX"
                id="wallet_address"
                // value={"0x3dE79168402278C0DA2Bf9A209C3A91d755790FC"}
                value={payload.wallet_address}
                onChange={handleOnChange}
                className="depositMoneyDiv_cont_body_wallet_addr_div_input"
              />
            </div>
            <div className="depositMoneyDiv_cont_body_input_div2">
              <div className="depositMoneyDiv_cont_body_input_div_title">
                Network:
              </div>

              {symbol2 === "USDT" ? (
                <>
                  <div
                    className="depositMoneyDiv_cont_body_input_div_div"
                    onClick={toggleNetworkDiv}
                  >
                    <div className="depositMoneyDiv_cont_body_input_div_div_div">
                      {selectedNetwork === null ? (
                        <>Select Network</>
                      ) : (
                        <>
                          {" "}
                          <div className="depositMoneyDiv_cont_body_input_div_div_cont1">
                            <img
                              src={selectedNetwork.img}
                              alt=""
                              className="depositMoneyDiv_cont_body_input_div_div_cont1_img"
                            />
                            {selectedNetwork.name}
                          </div>
                          <div className="depositMoneyDiv_cont_body_input_div_div_cont2">
                            {selectedNetwork.network}
                          </div>
                        </>
                      )}
                    </div>
                    <KeyboardArrowDownIcon />
                  </div>

                  {networkDiv ? (
                    <div className="network_select_div">
                      {Network.map((data, key) => (
                        <div
                          className="network_select_div_cont1"
                          key={key}
                          id={data.id}
                          onClick={() => selectNetwork(data)}
                        >
                          <img
                            src={data.img}
                            alt=""
                            className="network_select_div_cont1_img"
                          />
                          <div className="network_select_div_cont1_txt">
                            {data.name}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : null}
                </>
              ) : symbol2 === "ESTA" ? (
                <>
                  <div
                    className="depositMoneyDiv_cont_body_input_div_div"
                    onClick={toggleNetworkDiv}
                  >
                    <div className="depositMoneyDiv_cont_body_input_div_div_div">
                      {selectedNetwork === null ? (
                        <>Select Network</>
                      ) : (
                        <>
                          {" "}
                          <div className="depositMoneyDiv_cont_body_input_div_div_cont1">
                            <img
                              src={selectedNetwork.img}
                              alt=""
                              className="depositMoneyDiv_cont_body_input_div_div_cont1_img"
                            />
                            {selectedNetwork.name}
                          </div>
                          <div className="depositMoneyDiv_cont_body_input_div_div_cont2">
                            {selectedNetwork.network}
                          </div>
                        </>
                      )}
                    </div>
                    <KeyboardArrowDownIcon />
                  </div>

                  {networkDiv ? (
                    <div className="network_select_div">
                      {Network2.map((data, key) => (
                        <div
                          className="network_select_div_cont1"
                          key={key}
                          id={data.id}
                          onClick={() => selectNetwork(data)}
                        >
                          <img
                            src={data.img}
                            alt=""
                            className="network_select_div_cont1_img"
                          />
                          <div className="network_select_div_cont1_txt">
                            {data.name}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : null}
                </>
              ) : (
                <div className="depositMoneyDiv_cont_body_input_div_div">
                  <div className="depositMoneyDiv_cont_body_input_div_div_cont1">
                    <img
                      src="/img/egax_logo.png"
                      alt=""
                      className="depositMoneyDiv_cont_body_input_div_div_cont1_img"
                    />
                    Ego Chain
                  </div>
                  <div className="depositMoneyDiv_cont_body_input_div_div_cont2">
                    Egochain
                  </div>
                </div>
              )}
            </div>
            <div className="depositMoneyDiv_cont_body_wallet_addr_divb">
              <div className="depositMoneyDiv_cont_body_wallet_addr_div_title">
                Withdrawal Amount:
              </div>
              <div className="depositMoneyDiv_cont_body_wallet_addr_div_input_div">
                <input
                  type="number"
                  id="amount"
                  placeholder="0.00"
                  value={payload.amount}
                  onChange={handleOnChange}
                  // value={"0x3dE79168402278C0DA2Bf9A209C3A91d755790FC"}
                  className="depositMoneyDiv_cont_body_wallet_addr_div_input"
                />
                <button
                  className="depositMoneyDiv_cont_body_wallet_addr_div_btn"
                  onClick={AddMax}
                >
                  Max
                </button>
              </div>
              <div className="availegc_bal_div">
                <div className="availegc_bal_div_title">Available</div>
                <div className="availegc_bal_div_amount">
                  {balance}
                  {symbol2}
                </div>
              </div>
            </div>
            <div className="depositMoneyDiv_cont_body_wallet_addr_divb">
              <div className="depositMoneyDiv_cont_body_wallet_addr_div_title">
                Withdrawal Remarks (optional):
              </div>
              <input
                type="text"
                // value={"0x3dE79168402278C0DA2Bf9A209C3A91d755790FC"}
                className="depositMoneyDiv_cont_body_wallet_addr_div_input"
              />
            </div>

            <div className="depositMoneyDiv_cont_body_tips_divb">
              <div className="depositMoneyDiv_cont_body_tips_div_1">
                <InfoOutlinedIcon className="depositMoneyDiv_cont_body_tips_div_1_icon" />
                <div className="depositMoneyDiv_cont_body_tips_div_1_txt">
                  Minimum single withdrawal amount: 0.5 {symbol2}
                </div>
              </div>
              <div className="depositMoneyDiv_cont_body_tips_div_1">
                <InfoOutlinedIcon className="depositMoneyDiv_cont_body_tips_div_1_icon" />
                <div className="depositMoneyDiv_cont_body_tips_div_1_txt">
                  Maximum single withdrawal amount: 2,000,000 {symbol2}
                </div>
              </div>
              <div className="depositMoneyDiv_cont_body_tips_div_1">
                <InfoOutlinedIcon className="depositMoneyDiv_cont_body_tips_div_1_icon" />
                <div className="depositMoneyDiv_cont_body_tips_div_1_txt">
                  Make sure the the receiver's wallet is a{" "}
                  {symbol2 === "USDT" ? "BNB Smart Chain (BEP20)" : "Egochain"}{" "}
                  wallet
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="depositMoneyDiv_cont_2">
          {symbol2 === "EGAX" ? (
            <>
              {" "}
              {loading ? (
                <p>Loading ..</p>
              ) : (
                <button
                  className="depositMoneyDiv_cont_2_btn"
                  onClick={processSend}
                >
                  Send Funds
                </button>
              )}
            </>
          ) : (
            <>
              {" "}
              {selectedNetwork == null ? (
                <button className="depositMoneyDiv_cont_2_btn" disabled>
                  Select Network
                </button>
              ) : (
                <>
                  {" "}
                  {loading ? (
                    <p>Loading ..</p>
                  ) : (
                    <button
                      className="depositMoneyDiv_cont_2_btn"
                      onClick={processSend}
                    >
                      Send Funds
                    </button>
                  )}
                </>
              )}
            </>
          )}
        </div>

        {pinModal ? (
          <WebPin
            isLoading={loading}
            btnFunc={sendFunds}
            pinTitle="Enter Pin to validate Transaction"
            pinPara="Input your transaction pin to complete this transaction"
            btnFuncTxt="Proceed"
            handleOnComplete={(e) => {
              const a = e.join("");
              setPin(a);
              return;
            }}
            toggleWebpin={() => {
              setPinModal(false);
            }}
          />
        ) : null}

        {successModal ? (
          <SuccessModal
            SuccesTxt={successMsg}
            successFunc={() => {
              window.location.href = "/wallet";
            }}
            txnHashDiv={false}
          />
        ) : null}
      </div>

      <ToastContainer />
    </div>
  );
};

export default SendUsdExternal;
