import React, { useState, useEffect } from "react";
import WebPin from "../../components/CommonUI/Modals/WebPin";
import { toast, ToastContainer } from "react-toastify";
import { SET_USER_PIN, UPDATE_USER_PIN } from "../../services/auth";
import { useNavigate } from "react-router-dom";
import WebpinComp from "../../components/CommonUI/Modals/WebpinComp";
import "./ResetPin.css";
import ErrorModal from "../../components/CommonUI/Modals/ErrorModal/ErrorModal";
import SuccessModal from "../../components/CommonUI/Modals/SuccessModal/SuccessModal";

const ResetPin = () => {
  const navigate = useNavigate();
  const [confirmPinModal, setConfirmPinModal] = useState(false);
  const [pinModal, setPinModal] = useState(false);
  const [pinProcessing, setPinProcessing] = useState(false);
  const [pin, setPinVal] = useState("");
  const [confirmPin, setConfirmPinVal] = useState("");
  const [successTxt, setSuccessTxt] = useState("");
  const [errorTxt, setErrorTxt] = useState("");
  const [errorModal, setErrorModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  const handleOnComplete1 = (e) => {
    const value = e.join("");
    setPinVal(value);
    console.log("====================================");
    console.log(value);
    console.log("====================================");
  };
  const setPin = async () => {
    setPinModal(true);
  };
  const processPinRequest = async () => {
    if (pin !== confirmPin) {
      toast.warn("Pin does not match");
      //// // console.logog("pin does not match");
      return;
    }

    setPinProcessing(true);
    const response = await UPDATE_USER_PIN({
      code: pin,
    });

    //// // console.logog(response);

    if (response.success) {
      setSuccessModal(true);
      setSuccessTxt("You have successfully reset your pin");
      return;
    }
    setErrorModal(true);
    setErrorTxt(response.errorMessage);
    // toast.warn(response.errorMessage);
  };
  const handleOnComplete2 = (e) => {
    const value = e.join("");
    setConfirmPinVal(value);
  };
  const proceedToConfirm = () => {
    setConfirmPinModal(true);
  };
  console.log("====================================");
  console.log(pin);
  console.log("====================================");
  //   useEffect(() => {
  //     if (pin.length < 4) {
  //       setDisabled(true);
  //       return;
  //     }
  //   }, [pin]);

  return (
    <div className="reset_pin_div">
      {" "}
      <div className="container">
        <div className="reset_pin_div_area">
          {confirmPinModal === true ? null : (
            <WebpinComp
              btnFunc={proceedToConfirm}
              btnFuncTxt={"Next"}
              handleOnComplete={handleOnComplete1}
              pinTitle={"Reset  Your Pin"}
              pinPara={
                "Reset your pin if you have forgotten your pin  to carry out transactions on the platform"
              }
              newUser={true}
              disabled={pin.length < 4 ? true : false}
            />
          )}

          {confirmPinModal === true ? (
            <WebpinComp
              btnFunc={processPinRequest}
              isLoading={pinProcessing}
              btnFuncTxt={"Confirm"}
              handleOnComplete={handleOnComplete2}
              pinTitle={"Please Confirm Your Pin"}
              pinPara={"Just to be sure, we'll want you to confirm your pin "}
              newUser={true}
              pin_state={"confirm"}
              cancelConfirm={() => {
                setConfirmPinModal(false);
                setPinVal("");
              }}
              disabled={
                confirmPin.length < 4 || confirmPin != pin ? true : false
              }
            />
          ) : null}
        </div>
      </div>
      <div class="background_gradient-parent">
        <div class="background_gradient"></div>
      </div>
      <img
        src="/img/dark_home_sec_bg.svg"
        alt=""
        className="home_div_section1_bg"
      />
      {successModal ? (
        <SuccessModal
          SuccesTxt={successTxt}
          successFunc={() => {
            window.location.href = "/";
          }}
          txnHashDiv={false}
        />
      ) : null}
      {errorModal ? (
        <ErrorModal
          ErrorTxt={errorTxt}
          errorFunc={() => {
            setErrorModal(false);
          }}
        />
      ) : null}
      <ToastContainer />
    </div>
  );
};

export default ResetPin;
