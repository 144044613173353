import { useState, useEffect, useRef } from "react";
import { ShimmerButton } from "react-shimmer-effects-18";
import CheckIcon from "@mui/icons-material/Check";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ClipLoader from "react-spinners/ClipLoader";
import ScaleLoader from "react-spinners/ScaleLoader";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import CloseIcon from "@mui/icons-material/Close";
import ErrorModal from "../../../components/ErrorModal/ErrorModal";
import { numberWithCommas } from "../../../assets/js/numberWithCommas";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import JoinFullIcon from "@mui/icons-material/JoinFull";
import BridgeExecution from "./BridgeExecution";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Timer from "./Timer";
import {
  GET_SWAP_PRICE,
  INITIALIZE_BRIDGE_ROUTE,
  FETCH_BRIDGE_TX,
  SENT_FUNDS,
  ABORT,
} from "../../../services/swap_services";
import Swap from "../../Swap/Swap";
const BridgeModal = () => {
  const { data } = useSelector((state) => state.wallet);
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [priceLoading, setPriceLoading] = useState(true);
  const [copiedAlert, setCopiedAlert] = useState(false);
  const [SwapAmount, setSwapAmount] = useState("");
  const [AmountOut, setAmountOut] = useState("");
  const [loading, setLoading] = useState(false);
  const [marketPrice, setMarketPrice] = useState(0.0);
  const [tokenModal1, setTokenModal1] = useState(false);
  const [tokenModal2, setTokenModal2] = useState(false);
  const [abortModal, setAbortModal] = useState(false);
  const [abortLoading, setAbortLoading] = useState(false);
  const [abortdisabled, setAbortdisabled] = useState(false);
  const [proceddTransacModal, setProceddTransacModal] = useState(false);
  const [proceddTransacLoading, setProceddTransacLoading] = useState(false);
  const [proceddTransacdisabled, setProceddTransacdisabled] = useState(false);
  // const [tokenBridgeModal, setTokenBridgeModal] = useState(false);
  const [BridgeExchange, setTokenBridgeExchange] = useState(true);
  const [bridgeCheckout, setBridgeCheckout] = useState(false);
  const [bridgeCheckoutAddress, setBridgeCheckoutAddress] = useState(false);
  const [bridgeExecution, setBridgeExecution] = useState(false);
  const [currentStep, setCurrentStep] = useState(2);
  const [errorModal, setErrorModal] = useState(false);
  const [bridgeTxDetails, setBridgeTxDetails] = useState([]);
  const [errorTxt, setErrorTxt] = useState("");
  const [ReceiverAddress, setReceiverAddress] = useState("");
  const [selectedToken1, setSelectedToken1] = useState({
    id: "0",
    name: "Tether USD",
    name2: "Dollar",
    symbol: "USDE",
    symbol2: "USDT",
    blockchain: "EGOCHAIN",
    blockchain2: "Ego20",
    img: "/img/usdt_img.png",
    balance: 0.0,
  });
  const [selectedToken2, setSelectedToken2] = useState({
    id: "1",
    name: "Tether USD",
    name2: "Dollar",
    symbol: "USD",
    symbol2: "USDT",
    blockchain: "BINANCE",
    blockchain2: "Bep20",
    img: "/img/usdt_img.png",
    balance: 0.0,
  });
  // console.log("====================================");
  // console.log(selectedToken1);
  // console.log("====================================");

  const getSwapPrice = async () => {
    setPriceLoading(true);
    if (selectedToken1 === null || selectedToken2 === null) {
      return;
    }

    if (selectedToken1.symbol === "ESTAE" && selectedToken2.symbol === "ESTA") {
      // // console.log("USD/EGC");
      setPriceLoading(true);
      const response = await GET_SWAP_PRICE({
        tokenA: "ESTA",
        tokenB: "ESTAE",
      });
      // console.log(response, "lahshs");

      // console.log(response);
      if (!response.success) {
        setPriceLoading(false);
        return;
      }
      setMarketPrice(response.data.price);
      setPriceLoading(false);
      return;
    }
    if (selectedToken1.symbol === "ESTA" && selectedToken2.symbol === "ESTAE") {
      // // console.log("USD/EGC");
      setPriceLoading(true);
      const response = await GET_SWAP_PRICE({
        tokenA: "ESTA",
        tokenB: "ESTAE",
      });
      // console.log(response, "lahshs");

      // console.log(response);
      if (!response.success) {
        setPriceLoading(false);
        return;
      }
      setMarketPrice(response.data.price);
      setPriceLoading(false);
      return;
    }
    if (selectedToken1.symbol === "USDE" && selectedToken2.symbol === "USD") {
      // // console.log("USD/EGC");
      setPriceLoading(true);
      const response = await GET_SWAP_PRICE({
        tokenA: "USD",
        tokenB: "USDE",
      });
      // console.log(response, "lahshs");

      // console.log(response);
      if (!response.success) {
        setPriceLoading(false);
        return;
      }
      setMarketPrice(response.data.price);
      setPriceLoading(false);
      return;
    }

    if (selectedToken1.symbol === "USD" && selectedToken2.symbol === "USDE") {
      // // console.log("USD/EGC");
      setPriceLoading(true);
      const response = await GET_SWAP_PRICE({
        tokenA: "USD",
        tokenB: "USDE",
      });
      // // console.log(response);
      if (!response.success) {
        setPriceLoading(false);
        return;
      }
      setMarketPrice(1 / response.data.price);
      setPriceLoading(false);

      return;
    }
  };
  const getSwapPrice2 = async () => {
    setPriceLoading(true);
    if (bridgeTxDetails.length <= 0) {
      return;
    }

    if (
      bridgeTxDetails.tickerIn === "ESTAE" &&
      bridgeTxDetails.tickerOut === "ESTA"
    ) {
      // // console.log("USD/EGC");
      setPriceLoading(true);
      const response = await GET_SWAP_PRICE({
        tokenA: "ESTA",
        tokenB: "ESTAE",
      });
      // console.log(response, "lahshs");

      // console.log(response);
      if (!response.success) {
        setPriceLoading(false);
        return;
      }
      setMarketPrice(response.data.price);
      setPriceLoading(false);
      return;
    }

    if (
      bridgeTxDetails.tickerIn === "ESTA" &&
      bridgeTxDetails.tickerOut === "ESTAE"
    ) {
      // // console.log("USD/EGC");
      setPriceLoading(true);
      const response = await GET_SWAP_PRICE({
        tokenA: "ESTA",
        tokenB: "ESTAE",
      });
      // console.log(response, "lahshs");

      // console.log(response);
      if (!response.success) {
        setPriceLoading(false);
        return;
      }
      setMarketPrice(response.data.price);
      setPriceLoading(false);
      return;
    }

    if (
      bridgeTxDetails.tickerIn === "USDE" &&
      bridgeTxDetails.tickerOut === "USD"
    ) {
      // // console.log("USD/EGC");
      setPriceLoading(true);
      const response = await GET_SWAP_PRICE({
        tokenA: "USD",
        tokenB: "USDE",
      });

      // // console.log(response);
      if (!response.success) {
        setPriceLoading(false);
        return;
      }
      setMarketPrice(response.data.price);
      setPriceLoading(false);
      return;
    }

    if (
      bridgeTxDetails.tickerIn === "USD" &&
      bridgeTxDetails.tickerOut === "USDE"
    ) {
      // // console.log("USD/EGC");
      setPriceLoading(true);
      const response = await GET_SWAP_PRICE({
        tokenA: "USD",
        tokenB: "USDE",
      });
      // // console.log(response);
      if (!response.success) {
        setPriceLoading(false);
        return;
      }
      setMarketPrice(1 / response.data.price);
      setPriceLoading(false);

      return;
    }
  };

  const getTxByTXID = async () => {
    // console.log("====================================");
    // console.log("SAUFE8MNTA");
    // console.log("====================================");
    const response = await FETCH_BRIDGE_TX(localStorage.getItem("txid"));

    // console.log("====================================");
    if (response.code === 200) {
      setBridgeTxDetails(response.data);
      // console.log(response);
    } else {
      setBridgeTxDetails([]);
      // console.log(response);
    }

    // console.log("====================================");
  };

  useEffect(() => {
    getSwapPrice();
    getSwapPrice2();
  }, [selectedToken1, selectedToken2, bridgeTxDetails]);

  useEffect(() => {
    // console.log("====================================");
    // console.log(localStorage.getItem("txid"));
    // console.log("====================================");
    if (localStorage.getItem("txid") !== null) {
      getTxByTXID();
      // console.log("====================================");
      // console.log(bridgeTxDetails);
      // console.log("====================================");
      return;
    }
  }, [localStorage.getItem("txid")]);

  const onChangeSwapAmount = (e) => {
    let parseNumber = parseFloat(e.target.value);
    setSwapAmount(e.target.value);
    setAmountOut(parseNumber * marketPrice);
  };
  const ToggleSwapInputs = () => {
    setSwapAmount("");
    setAmountOut("");
    setSelectedToken2(selectedToken1);
    setSelectedToken1(selectedToken2);
  };
  const OpenTokenModal1 = () => {
    setTokenModal1(!tokenModal1);
  };

  const closeTokenModal1 = (token) => {
    setSwapAmount("");
    setTokenModal1(!tokenModal1);
    if (token.id === "2") {
      setSelectedToken2({
        id: "3",
        name: "EgoStation",
        name2: "ESTA",
        symbol: "ESTAE",
        symbol2: "ESTA",
        blockchain: "Egochain",
        blockchain2: "Ego20",
        img: "/img/esta_logo.jpeg",
        balance: 0.0,
      });
    }

    if (token.id === "3") {
      setSelectedToken2({
        id: "2",
        name: "EgoStation",
        name2: "ESTA",
        symbol: "ESTA",
        symbol2: "ESTA",
        blockchain: "Ethereum",
        blockchain2: "Erc20",
        img: "/img/esta_logo.jpeg",
        balance: 0.0,
      });
    }
    if (token.id === "0") {
      setSelectedToken2({
        id: "1",
        name: "Tether USD",
        name2: "Dollar",
        symbol: "USD",
        symbol2: "USDT",
        blockchain: "Binance Smartchain",
        blockchain2: "Bep20",
        img: "/img/usdt_img.png",
        balance: 0.0,
      });
    }
    if (token.id === "1") {
      setSelectedToken2({
        id: "0",
        name: "Tether USD",
        name2: "Dollar",
        symbol: "USDE",
        symbol2: "USDT",
        blockchain: "Egochain",
        blockchain2: "Ego20",
        img: "/img/usdt_img.png",
        balance: 0.0,
      });
    }
    if (selectedToken1 === null && selectedToken2 === null) {
      setSelectedToken1(token);
      return;
    }
    if (selectedToken2 !== null && selectedToken1 === null) {
      if (token.id === selectedToken2.id) {
        setSelectedToken2(selectedToken1);
        setSelectedToken1(selectedToken2);
      } else {
        setSelectedToken1(token);
      }
      return;
    }
    if (selectedToken1 !== null && selectedToken2 === null) {
      setSelectedToken1(token);
      return;
    }
    if (selectedToken1 !== null && selectedToken2 !== null) {
      if (token.id === selectedToken2.id) {
        setSelectedToken2(selectedToken1);
        setSelectedToken1(selectedToken2);
      } else {
        setSelectedToken1(token);
      }
      return;
    }
  };

  // const OpenTokenModal2 = () => {
  //   setTokenModal2(!tokenModal2);
  // };

  // const closeTokenModal2 = (token) => {
  //   setSwapAmount("");
  //   setTokenModal2(!tokenModal2);
  //   // // console.log(token);
  //   // // console.log(selectedToken1);
  //   // // console.log(selectedToken2);
  //   if (selectedToken1 === null && selectedToken2 === null) {
  //     setSelectedToken2(token);
  //     return;
  //   }
  //   if (selectedToken1 !== null && selectedToken2 === null) {
  //     if (token.id === selectedToken1.id) {
  //       setSelectedToken1(selectedToken2);
  //       setSelectedToken2(selectedToken1);
  //     } else {
  //       setSelectedToken2(token);
  //     }
  //     return;
  //   }
  //   if (selectedToken2 !== null && selectedToken1 === null) {
  //     setSelectedToken2(token);
  //     return;
  //   }
  //   if (selectedToken2 !== null && selectedToken1 !== null) {
  //     if (token.id === selectedToken1.id) {
  //       setSelectedToken1(selectedToken2);
  //       setSelectedToken2(selectedToken1);
  //     } else {
  //       setSelectedToken2(token);
  //     }
  //     return;
  //   }
  // };

  const bridgeStep1 = () => {
    setBridgeCheckout(true);
    setBridgeCheckoutAddress(false);
    setTokenBridgeExchange(false);
    localStorage.setItem("BridgeStep", 2);
  };
  const bridgeStep2 = () => {
    setBridgeCheckout(false);
    setBridgeCheckoutAddress(true);
    setTokenBridgeExchange(false);
    setCurrentStep(3);
    localStorage.setItem("BridgeStep", 3);
  };
  const bridgeStep3 = () => {
    setBridgeCheckout(false);
    setBridgeCheckoutAddress(false);
    setTokenBridgeExchange(false);
    setBridgeExecution(true);
    setCurrentStep(4);
    localStorage.setItem("BridgeStep", 4);
  };
  const TokenObject = [
    {
      id: "0",
      name: "Tether USD",
      name2: "Dollar",
      symbol: "USDE",
      symbol2: "USDT",
      blockchain: "Egochain",
      blockchain2: "Ego20",
      img: "/img/usdt_img.png",
      balance: 0.0,
    },
    {
      id: "1",
      name: "Tether USD",
      name2: "Dollar",
      symbol: "USD",
      symbol2: "USDT",
      blockchain: "Binance Smartchain",
      blockchain2: "Bep20",
      img: "/img/usdt_img.png",
      balance: 0.0,
    },
    {
      id: "2",
      name: "EgoStation",
      name2: "ESTA",
      symbol: "ESTA",
      symbol2: "ESTA",
      blockchain: "Ethereum",
      blockchain2: "Erc20",
      img: "/img/esta_logo.jpeg",
      balance: 0.0,
    },
    {
      id: "3",
      name: "EgoStation",
      name2: "ESTA",
      symbol: "ESTAE",
      symbol2: "ESTA",
      blockchain: "Egochain",
      blockchain2: "Ego20",
      img: "/img/esta_logo.jpeg",
      balance: 0.0,
    },
  ];

  useEffect(() => {
    const bridgeStep = localStorage.getItem("BridgeStep");
    // console.log("====================================");
    // console.log(bridgeStep);
    // console.log("====================================");
    if (bridgeStep === "1") {
      setCurrentStep(1);
      return;
    }
    if (bridgeStep === "2") {
      setCurrentStep(2);
      return;
    }
    if (bridgeStep === "3") {
      setCurrentStep(3);
      return;
    }
    if (bridgeStep === "4") {
      setCurrentStep(4);
      return;
    }
  }, []);

  const TokenBridge = async () => {
    setLoading(true);
    if (selectedToken1 === null || selectedToken2 === null) {
      return;
    }

    const payload = {
      tokenIn: selectedToken1.symbol,
      tokenOut: selectedToken2.symbol,
      networkIn: selectedToken1.blockchain,
      networkOut: selectedToken2.blockchain,
      recieving_wallet: ReceiverAddress,
      tokenAamount: SwapAmount,
    };
    // // console.log(payload);
    const response = await INITIALIZE_BRIDGE_ROUTE(payload);
    // console.log(response);
    if (response.code === 200) {
      setLoading(false);
      localStorage.setItem("txid", response.data.code);
      bridgeStep2();
      return;
    }
    if (response.data.code !== 200) {
      setLoading(false);
      setErrorModal(true);
      setErrorTxt(response.data.errorMessage);
      return;
    }

    return;
  };

  useEffect(() => {
    const bridgeStep = localStorage.getItem("BridgeStep");
    // console.log("====================================");
    // console.log(bridgeStep);
    // console.log("====================================");
    if (bridgeStep === "1") {
      return;
    }
    if (bridgeStep === "2") {
      // setTokenBridgeModal(true);
      setTokenBridgeExchange(true);
      return;
    }
    if (bridgeStep === "3") {
      // setTokenBridgeModal(true);
      setBridgeCheckoutAddress(true);
      setTokenBridgeExchange(false);
      return;
    }
    if (bridgeStep === "4") {
      // setTokenBridgeModal(true);
      setBridgeExecution(true);
      setTokenBridgeExchange(false);
      setBridgeCheckoutAddress(false);

      return;
    }
  }, []);

  const onChangeReceiverAddress = (e) => {
    setReceiverAddress(e.target.value);
  };

  const BackHome = () => {
    window.location.reload();
    // setBridgeCheckout(false);
    // setBridgeCheckoutAddress(false);
    // setTokenBridgeExchange(false);
    localStorage.removeItem("BridgeStep");
  };

  const copyAddressToClipboard = () => {
    // Get the text content of the address div
    const address = document.querySelector(
      ".send_funds_required_cont1_content_address"
    ).textContent;

    // Create a temporary textarea element
    const textarea = document.createElement("textarea");
    textarea.value = address;

    // Append the textarea to the body
    document.body.appendChild(textarea);

    // Select the content of the textarea
    textarea.select();
    textarea.setSelectionRange(0, 99999); /* For mobile devices */

    // Copy the selected text
    document.execCommand("copy");

    // Remove the textarea
    document.body.removeChild(textarea);
    setCopiedAlert(true);

    setTimeout(() => {
      setCopiedAlert(false);
    }, 1000);
  };

  const OpenAbortModal = () => {
    setAbortModal(true);
  };
  const CloseAbortModal = () => {
    setAbortModal(false);
  };
  const OpenProceedModal = () => {
    setProceddTransacModal(true);
  };
  const CloseProceedModal = () => {
    setProceddTransacModal(false);
  };

  const abortTransaction = async () => {
    setAbortLoading(true);
    setAbortdisabled(true);
    if (localStorage.getItem("txid")) {
      const response = await ABORT(localStorage.getItem("txid"));
      // console.log("====================================");
      // console.log(response);
      // console.log("====================================");

      if (response.code === 200) {
        window.location.reload();
        setAbortLoading(false);
        setAbortdisabled(false);
        CloseAbortModal();

        // setBridgeCheckout(false);
        // setBridgeCheckoutAddress(false);
        // setTokenBridgeExchange(false);
        localStorage.removeItem("lifecycle");
        localStorage.removeItem("BridgeStep");
        localStorage.removeItem("txid");
        // console.log(response);
      } else {
        // console.log(response);
        setAbortLoading(false);
        setAbortdisabled(false);
      }

      return;
    }
  };

  const SubmitSentFunds = async () => {
    setProceddTransacLoading(true);
    setProceddTransacdisabled(true);
    if (localStorage.getItem("txid")) {
      const response = await SENT_FUNDS(localStorage.getItem("txid"));
      // console.log("====================================");
      // console.log(response);
      if (response.code === 200) {
        bridgeStep3();
        // console.log(response);
        CloseProceedModal();
        setProceddTransacLoading(false);
        setProceddTransacdisabled(false);
      } else {
        // console.log(response);
        setProceddTransacLoading(false);
        setProceddTransacdisabled(false);
      }

      return;
    }
  };

  useEffect(() => {
    if (bridgeTxDetails.length <= 0) {
      return;
    }
    if (new Date() >= new Date(bridgeTxDetails.endTime)) {
      abortTransaction();
      return;
    }
  }, [new Date(), bridgeTxDetails]);

  return (
    <div className="TokenBridgeModal_cont_area">
      <div className="TokenBridgeModal_cont_1">
        <div className="TokenBridgeModal_cont_1_title">
          Provide address information to create a transaction
        </div>
        <div className="TokenBridgeModal_cont_1_body">
          <div
            className={
              currentStep == 1
                ? "TokenBridgeModal_cont_1_body_cont1_active"
                : "TokenBridgeModal_cont_1_body_cont1"
            }
            style={{ opacity: currentStep > 1 ? ".5" : "1" }}
          >
            {currentStep > 1 ? (
              <div className="TokenBridgeModal_cont_1_body_cont1_icon_div">
                <CheckIcon className="TokenBridgeModal_cont_1_body_cont1_icon_div_icon_checked" />
              </div>
            ) : currentStep === 1 ? (
              <div className="TokenBridgeModal_cont_1_body_cont1_icon_div">
                <ArrowForwardIcon className="TokenBridgeModal_cont_1_body_cont1_icon_div_icon_ongoing" />
              </div>
            ) : (
              <div className="TokenBridgeModal_cont_1_body_cont1_icon_div">
                <div className="TokenBridgeModal_cont_1_body_cont1_icon_div_span">
                  1
                </div>
              </div>
            )}

            <div className="TokenBridgeModal_cont_1_body_cont1_txt_div">
              <div className="TokenBridgeModal_cont_1_body_cont1_txt_div_title">
                Bridging pair
              </div>
              <div className="TokenBridgeModal_cont_1_body_cont1_txt_div_para">
                Set the preferred exchange pair.
              </div>
            </div>
          </div>
          <div
            className={
              currentStep == 2
                ? "TokenBridgeModal_cont_1_body_cont1_active"
                : "TokenBridgeModal_cont_1_body_cont1"
            }
            style={{ opacity: currentStep > 2 ? ".5" : "1" }}
          >
            {currentStep > 2 ? (
              <div className="TokenBridgeModal_cont_1_body_cont1_icon_div">
                <CheckIcon className="TokenBridgeModal_cont_1_body_cont1_icon_div_icon_checked" />
              </div>
            ) : currentStep === 2 ? (
              <div className="TokenBridgeModal_cont_1_body_cont1_icon_div">
                <ArrowForwardIcon className="TokenBridgeModal_cont_1_body_cont1_icon_div_icon_ongoing" />
              </div>
            ) : (
              <div className="TokenBridgeModal_cont_1_body_cont1_icon_div">
                <div className="TokenBridgeModal_cont_1_body_cont1_icon_div_span">
                  2
                </div>
              </div>
            )}
            <div className="TokenBridgeModal_cont_1_body_cont1_txt_div">
              <div className="TokenBridgeModal_cont_1_body_cont1_txt_div_title">
                Wallet address
              </div>
              <div className="TokenBridgeModal_cont_1_body_cont1_txt_div_para">
                Fill in the crypto wallet address details.
              </div>
            </div>
          </div>
          <div
            className={
              currentStep == 3
                ? "TokenBridgeModal_cont_1_body_cont1_active"
                : "TokenBridgeModal_cont_1_body_cont1"
            }
            style={{ opacity: currentStep > 3 ? ".5" : "1" }}
          >
            {currentStep > 3 ? (
              <div className="TokenBridgeModal_cont_1_body_cont1_icon_div">
                <CheckIcon className="TokenBridgeModal_cont_1_body_cont1_icon_div_icon_checked" />
              </div>
            ) : currentStep === 3 ? (
              <div className="TokenBridgeModal_cont_1_body_cont1_icon_div">
                <ArrowForwardIcon className="TokenBridgeModal_cont_1_body_cont1_icon_div_icon_ongoing" />
              </div>
            ) : (
              <div className="TokenBridgeModal_cont_1_body_cont1_icon_div">
                <div className="TokenBridgeModal_cont_1_body_cont1_icon_div_span">
                  3
                </div>
              </div>
            )}
            <div className="TokenBridgeModal_cont_1_body_cont1_txt_div">
              <div className="TokenBridgeModal_cont_1_body_cont1_txt_div_title">
                Payment
              </div>
              <div className="TokenBridgeModal_cont_1_body_cont1_txt_div_para">
                Deposit the amount required for the exchange.
              </div>
            </div>
          </div>
          <div
            className={
              currentStep == 4
                ? "TokenBridgeModal_cont_1_body_cont1_active"
                : "TokenBridgeModal_cont_1_body_cont1"
            }
            style={{ opacity: currentStep > 4 ? ".5" : "1" }}
          >
            {currentStep > 4 ? (
              <div className="TokenBridgeModal_cont_1_body_cont1_icon_div">
                <CheckIcon className="TokenBridgeModal_cont_1_body_cont1_icon_div_icon_checked" />
              </div>
            ) : currentStep === 4 ? (
              <div className="TokenBridgeModal_cont_1_body_cont1_icon_div">
                <ArrowForwardIcon className="TokenBridgeModal_cont_1_body_cont1_icon_div_icon_ongoing" />
              </div>
            ) : (
              <div className="TokenBridgeModal_cont_1_body_cont1_icon_div">
                <div className="TokenBridgeModal_cont_1_body_cont1_icon_div_span">
                  4
                </div>
              </div>
            )}
            <div className="TokenBridgeModal_cont_1_body_cont1_txt_div">
              <div className="TokenBridgeModal_cont_1_body_cont1_txt_div_title">
                Bridging
              </div>
              <div className="TokenBridgeModal_cont_1_body_cont1_txt_div_para">
                Wait for your transaction to be completed.
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===== */}
      {/* ===== */}
      {/* ===== */}
      {/* ===== */}
      {/* ===== */}
      {BridgeExchange ? (
        <div className="TokenBridgeModal_cont_2">
          <div className="TokenBridgeModal_cont_2_div1">
            <div className="liquidity_cont">
              <div className="liquidity_cont_body">
                <div className="liquidity_cont_body_conts">
                  <div className="liquidity_cont_body_conts_cont1">
                    <div className="input_amnt_layer">
                      <div className="amnt_input">
                        <div className="amnt_input_layer1">
                          <div className="amnt_input_layer1_input_div">
                            <div className="amnt_input_layer1_input_div_span">
                              You send
                            </div>
                            {priceLoading ? (
                              <ShimmerButton
                                size="lg"
                                className="custom_shimmer"
                              />
                            ) : (
                              <input
                                type="number"
                                name="number"
                                id="number"
                                placeholder="0.00"
                                className="amnt_input_field"
                                autocomplete="off"
                                onChange={onChangeSwapAmount}
                                value={SwapAmount}
                              />
                            )}
                          </div>

                          <div className="Swap_icondropDownDiv">
                            {/* <span className="token_balances_span">
                                    Balance:{" "}
                                    {selectedToken1
                                      ? numberWithCommas(
                                          parseFloat(
                                            selectedToken1.balance == null
                                              ? 0
                                              : selectedToken1.balance
                                          ).toFixed(4)
                                        )
                                      : "0.00"}
                                  </span> */}

                            <button
                              className="display_tokens_drop2"
                              onClick={() => OpenTokenModal1()}
                            >
                              {selectedToken1 ? (
                                <div className="selectedTokenInfo">
                                  <img
                                    src={selectedToken1.img}
                                    alt=""
                                    className="display_tokens_drop_img"
                                  />
                                  <div className="swap_symbol_network_div">
                                    <span className="selectedTokenSymbol">
                                      {selectedToken1.symbol2}/
                                      {selectedToken1.blockchain2}{" "}
                                      <ArrowDropDownIcon className="liquidity_select_div_btn1_icon" />
                                    </span>
                                    <span className="swap_symbol_network_div_network">
                                      {selectedToken1.blockchain}{" "}
                                      <span
                                        className="swap_symbol_network_div_network_span"
                                        style={{
                                          background:
                                            selectedToken1.blockchain2 ===
                                            "Ego20"
                                              ? "#18854f"
                                              : selectedToken1.blockchain2 ===
                                                "Bep20"
                                              ? "#dac00e"
                                              : selectedToken1.blockchain2 ===
                                                "Erc20"
                                              ? "#4a55fe"
                                              : "grey",
                                        }}
                                      >
                                        {selectedToken1.blockchain2}
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                "Select Currency"
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="plus_icon_layer"> */}
                    <SwapVertIcon
                      className="toggle_swap_inputs2"
                      onClick={ToggleSwapInputs}
                    />

                    <div className="input_amnt_layer">
                      <div className="amnt_input">
                        <div className="amnt_input_layer1">
                          <div className="amnt_input_layer1_input_div">
                            <div className="amnt_input_layer1_input_div_span">
                              You receive
                            </div>
                            {priceLoading ? (
                              <ShimmerButton
                                size="lg"
                                className="custom_shimmer"
                              />
                            ) : (
                              <input
                                type="number"
                                name="number"
                                id="number"
                                placeholder="0.00"
                                className="amnt_input_field"
                                autocomplete="off"
                                value={
                                  SwapAmount == ""
                                    ? " "
                                    : AmountOut - AmountOut * 0.01
                                }
                              />
                            )}
                          </div>
                          <div className="Swap_icondropDownDiv">
                            {/* <span className="token_balances_span">
                                    Balance:{" "}
                                    {selectedToken2
                                      ? numberWithCommas(
                                          parseFloat(
                                            selectedToken2.balance == null
                                              ? 0
                                              : selectedToken2.balance
                                          ).toFixed(4)
                                        )
                                      : "0.00"}
                                  </span> */}
                            <button
                              className="display_tokens_drop2"
                              // onClick={() => OpenTokenModal2()}
                            >
                              {selectedToken2 ? (
                                <div className="selectedTokenInfo">
                                  <img
                                    src={selectedToken2.img}
                                    alt=""
                                    className="display_tokens_drop_img"
                                  />
                                  <div className="swap_symbol_network_div">
                                    <span className="selectedTokenSymbol">
                                      {selectedToken2.symbol2}/
                                      {selectedToken2.blockchain2}{" "}
                                      {/* <ArrowDropDownIcon className="liquidity_select_div_btn1_icon" /> */}
                                    </span>
                                    <span className="swap_symbol_network_div_network">
                                      {selectedToken2.blockchain}{" "}
                                      <span
                                        className="swap_symbol_network_div_network_span"
                                        style={{
                                          background:
                                            selectedToken2.blockchain2 ===
                                            "Ego20"
                                              ? "#18854f"
                                              : selectedToken2.blockchain2 ===
                                                "Bep20"
                                              ? "#dac00e"
                                              : selectedToken2.blockchain2 ===
                                                "Erc20"
                                              ? "#4a55fe"
                                              : "grey",
                                        }}
                                      >
                                        {selectedToken2.blockchain2}
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                "Select Currency"
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* </div> */}
                  </div>
                  <div className="swap_price_rate_div">
                    {priceLoading ? (
                      <ShimmerButton size="sm" className="custom_shimmer" />
                    ) : (
                      <>
                        {" "}
                        <div className="swap_price_rate_div1">
                          1
                          {selectedToken1 == null ? (
                            <ShimmerButton
                              size="sm"
                              className="custom_shimmer"
                            />
                          ) : (
                            <> {selectedToken1.symbol}</>
                          )}
                        </div>
                        <span style={{ marginRight: "5px" }}>=</span>
                        <div className="swap_price_rate_div1">
                          {selectedToken2 == null ? (
                            <ShimmerButton
                              size="sm"
                              className="custom_shimmer"
                            />
                          ) : (
                            <>
                              {" "}
                              {numberWithCommas(marketPrice)}{" "}
                              {selectedToken2.symbol}
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="TokenBridgeModal_cont_2_div2_fee_div">
            <div className="TokenBridgeModal_cont_2_div2_fee_div_txt">
              <JoinFullIcon className="TokenBridgeModal_cont_2_div2_fee_div_txt_icon" />{" "}
              Bridge Fees
            </div>
            <div className="TokenBridgeModal_cont_2_div2_fee_div_amount">
              {AmountOut * 0.01} {selectedToken2.symbol2}{" "}
              <span
                className="swap_symbol_network_div_network_span"
                style={{
                  background:
                    selectedToken2.blockchain2 === "Ego20"
                      ? "#18854f"
                      : selectedToken2.blockchain2 === "Bep20"
                      ? "#dac00e"
                      : selectedToken2.blockchain2 === "Erc20"
                      ? "#4a55fe"
                      : "grey",
                }}
              >
                {selectedToken2.blockchain2}
              </span>
            </div>
          </div>
          <div className="TokenBridgeModal_cont_2_div2">
            <div className="TokenBridgeModal_cont_2_div2_title">
              Destination wallet address
            </div>
            <div className="TokenBridgeModal_cont_2_div2_input_div">
              <div className="TokenBridgeModal_cont_2_div2_input_div_title">
                Enter your {selectedToken2.symbol2} {selectedToken2.blockchain2}{" "}
                wallet address
              </div>
              <input
                type="text"
                value={ReceiverAddress}
                onChange={onChangeReceiverAddress}
                placeholder={`Recipient ${selectedToken2.symbol2} ${selectedToken2.blockchain2} wallet address`}
                className="TokenBridgeModal_cont_2_div2_input_div_input"
              />
            </div>
            <div className="aggree_terms_div">
              {" "}
              <input type="checkbox" id="checkbox-1" name="checkbox" />
              <label for="checkbox-1" className="checkBox_agree_div_body_label">
                <div className="checkBox_agree_div_body_txt">
                  I agree with Terms of Use, Privacy Policy.
                </div>
              </label>
            </div>
            <div className="TokenBridgeModal_cont_2_div2_input_div_btn_divs">
              <button className="updatedSwapSwapBtn_abort" onClick={BackHome}>
                Back home
              </button>
              <button
                className="updatedSwapSwapBtn"
                disabled={
                  ReceiverAddress == "" || SwapAmount == "" ? true : false
                }
                onClick={bridgeStep1}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {bridgeCheckout ? (
        <div className="TokenBridgeModal_cont_2">
          <div className="TokenBridgeModal_cont_2_checkout_div1">
            <div className="TokenBridgeModal_cont_2_checkout_div1_title">
              Checkout{" "}
              <button
                onClick={() => {
                  setTokenBridgeExchange(true);
                  setBridgeCheckout(false);
                  setCurrentStep(2);
                }}
                className="TokenBridgeModal_cont_2_checkout_div1_title_btn"
              >
                <ArrowBackIcon className="TokenBridgeModal_cont_2_checkout_div1_title_btn_icon" />
                Back
              </button>
            </div>
            <div className="TokenBridgeModal_cont_2_checkout_div1_cont1">
              <div className="TokenBridgeModal_cont_2_checkout_div1_cont1_div1">
                <div className="TokenBridgeModal_cont_2_checkout_div1_cont1_div1_title">
                  You send
                </div>
                <div className="TokenBridgeModal_cont_2_checkout_div1_cont1_div1_amount">
                  {SwapAmount} {selectedToken1.symbol2}
                  <span
                    className="swap_symbol_network_div_network_span"
                    style={{
                      background:
                        selectedToken1.blockchain2 === "Ego20"
                          ? "#18854f"
                          : selectedToken1.blockchain2 === "Bep20"
                          ? "#dac00e"
                          : selectedToken1.blockchain2 === "Erc20"
                          ? "#4a55fe"
                          : "grey",
                    }}
                  >
                    {selectedToken1.blockchain2}
                  </span>
                </div>
                <div className="TokenBridgeModal_cont_2_checkout_div1_cont1_div1_blockcahin">
                  Blockchain: {selectedToken1.blockchain}
                </div>
              </div>
              <div className="TokenBridgeModal_cont_2_checkout_div1_cont1_div1">
                <div className="TokenBridgeModal_cont_2_checkout_div1_cont1_div1_title">
                  You get
                </div>
                <div className="TokenBridgeModal_cont_2_checkout_div1_cont1_div1_amount">
                  {AmountOut - AmountOut * 0.01} {selectedToken2.symbol2}
                  <span
                    className="swap_symbol_network_div_network_span"
                    style={{
                      background:
                        selectedToken2.blockchain2 === "Ego20"
                          ? "#18854f"
                          : selectedToken2.blockchain2 === "Bep20"
                          ? "#dac00e"
                          : selectedToken2.blockchain2 === "Erc20"
                          ? "#4a55fe"
                          : "grey",
                    }}
                  >
                    {selectedToken2.blockchain2}
                  </span>
                </div>
                <div className="TokenBridgeModal_cont_2_checkout_div1_cont1_div1_blockcahin">
                  Blockchain: {selectedToken2.blockchain}
                </div>
              </div>
            </div>
            {/* ======= */}
            {/* ======= */}
            {/* ======= */}
            <div className="TokenBridgeModal_cont_2_checkout_div1_cont2">
              <div className="TokenBridgeModal_cont_2_checkout_div1_cont2_div1">
                <div className="TokenBridgeModal_cont_2_checkout_div1_cont2_div1_title">
                  Bridge fee
                </div>
                <div className="TokenBridgeModal_cont_2_checkout_div1_cont2_div1_amount">
                  {AmountOut * 0.01} {selectedToken2.symbol2}
                  <span
                    className="swap_symbol_network_div_network_span"
                    style={{
                      background:
                        selectedToken2.blockchain2 === "Ego20"
                          ? "#18854f"
                          : selectedToken2.blockchain2 === "Bep20"
                          ? "#dac00e"
                          : selectedToken2.blockchain2 === "Erc20"
                          ? "#4a55fe"
                          : "grey",
                    }}
                  >
                    {selectedToken2.blockchain2}
                  </span>
                </div>
                <div className="TokenBridgeModal_cont_2_checkout_div1_cont2_div1_para">
                  The exchange fee is already included in the displayed amount
                  you’ll get
                </div>
              </div>
              <div className="TokenBridgeModal_cont_2_checkout_div1_cont2_div1">
                <div className="TokenBridgeModal_cont_2_checkout_div1_cont2_div1_title">
                  Exchange rate
                </div>
                <div className="TokenBridgeModal_cont_2_checkout_div1_cont2_div1_amount">
                  {priceLoading ? (
                    <ShimmerButton size="sm" className="custom_shimmer" />
                  ) : (
                    <>
                      {" "}
                      <div className="swap_price_rate_div1">
                        1
                        {selectedToken1 == null ? (
                          <ShimmerButton size="sm" className="custom_shimmer" />
                        ) : (
                          <> {selectedToken1.symbol2}</>
                        )}
                      </div>
                      <span style={{ marginRight: "5px" }}>=</span>
                      <div className="swap_price_rate_div1">
                        {selectedToken2 == null ? (
                          <ShimmerButton size="sm" className="custom_shimmer" />
                        ) : (
                          <>
                            {" "}
                            {numberWithCommas(marketPrice)}{" "}
                            {selectedToken2.symbol2}
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            {/* ======= */}
            {/* ======= */}
            {/* ======= */}
            <div className="TokenBridgeModal_cont_2_checkout_div1_cont3">
              <div className="TokenBridgeModal_cont_2_checkout_div1_cont3_title">
                Recipient address
              </div>
              <div className="TokenBridgeModal_cont_2_checkout_div1_cont3_para">
                {ReceiverAddress}
              </div>
            </div>
          </div>
          <div className="TokenBridgeModal_cont_2_checkout_div2">
            <button className="updatedSwapSwapBtn" onClick={TokenBridge}>
              {loading ? (
                <ScaleLoader color="#446f59" height={20} />
              ) : (
                " Confirm & make payment"
              )}
            </button>
          </div>
        </div>
      ) : null}

      {bridgeCheckoutAddress ? (
        <div className="TokenBridgeModal_cont_2">
          <div className="TokenBridgeModal_cont_2_checkout_div1">
            <div className="TokenBridgeModal_cont_2_checkout_div1_title2">
              Send funds to the address below
            </div>
            {/* ===== */}
            {/* ===== */}
            {/* ===== */}
            <div className="send_funds_required_cont1">
              <div className="send_funds_required_cont1_title">Amount</div>
              <div className="send_funds_required_cont1_content">
                <div className="send_funds_required_cont1_content_div">
                  {parseFloat(bridgeTxDetails.tokenAamount).toFixed(2)}{" "}
                  {bridgeTxDetails.tickerIn === "USDE"
                    ? "USDT"
                    : bridgeTxDetails.tickerIn === "USD"
                    ? "USDT"
                    : bridgeTxDetails.tickerIn}
                  <span
                    className="swap_symbol_network_div_network_span"
                    style={{
                      background:
                        bridgeTxDetails.networkIn === "EGOCHAIN"
                          ? "#18854f"
                          : bridgeTxDetails.networkIn === "BINANCE"
                          ? "#dac00e"
                          : bridgeTxDetails.networkIn === "ETHEREUM"
                          ? "#4a55fe"
                          : "grey",
                    }}
                  >
                    {bridgeTxDetails.networkIn === "EGOCHAIN"
                      ? "Ego20"
                      : bridgeTxDetails.networkIn === "BINANCE"
                      ? "Bep20"
                      : bridgeTxDetails.networkIn === "ETHEREUM"
                      ? "Erc20"
                      : bridgeTxDetails.networkIn}
                  </span>
                </div>{" "}
              </div>
            </div>
            {/* ===== */}
            {/* ===== */}
            {/* ===== */}
            <div className="send_funds_required_cont1">
              <div className="send_funds_required_cont1_title">
                Egoswap address
                <br /> ({" "}
                {bridgeTxDetails.tickerIn === "USDE"
                  ? "USDT"
                  : bridgeTxDetails.tickerIn === "USD"
                  ? "USDT"
                  : bridgeTxDetails.tickerIn}{" "}
                {bridgeTxDetails.networkIn})
              </div>
              <div className="send_funds_required_cont1_content">
                <div className="send_funds_required_cont1_content_address">
                  {bridgeTxDetails.user_wallet}
                  {copiedAlert && (
                    <div className="send_funds_required_cont1_content_address_alert_div">
                      Address copied to clipboard
                    </div>
                  )}
                </div>
                <div className="send_funds_required_cont1_content_address_chain">
                  blockchain: {bridgeTxDetails.networkIn}
                </div>
                <button
                  className="send_funds_required_cont1_content_address_copy_address"
                  onClick={copyAddressToClipboard}
                >
                  <ContentCopyIcon className="send_funds_required_cont1_content_address_copy_address_icon" />{" "}
                  Copy Address
                </button>
              </div>
            </div>
          </div>
          <div className="TokenBridgeModal_cont_2_checkout_div2">
            <button
              className="updatedSwapSwapBtn_abort"
              onClick={OpenAbortModal}
            >
              Abort
            </button>
            <button className="updatedSwapSwapBtn" onClick={OpenProceedModal}>
              Proceed
            </button>
          </div>
        </div>
      ) : null}
      {bridgeExecution ? (
        <BridgeExecution
          tokenIn={
            bridgeTxDetails.length <= 0
              ? bridgeTxDetails.tickerIn === "USDE"
                ? "USDT"
                : bridgeTxDetails.tickerIn === "USD"
                ? "USDT"
                : bridgeTxDetails.tickerIn
              : selectedToken1.symbol2
          }
          tokenOut={
            bridgeTxDetails.length <= 0
              ? bridgeTxDetails.tickerOut === "USDE"
                ? "USDT"
                : bridgeTxDetails.tickerOut === "USD"
                ? "USDT"
                : bridgeTxDetails.tickerOut
              : selectedToken2.symbol2
          }
          networkIn={
            bridgeTxDetails.length <= 0
              ? bridgeTxDetails.networkIn === "USDE"
                ? "USDT"
                : bridgeTxDetails.networkIn === "USD"
                ? "USDT"
                : bridgeTxDetails.networkIn
              : selectedToken1.blockchain2
          }
          networkOut={
            bridgeTxDetails.length <= 0
              ? bridgeTxDetails.networkOut === "USDE"
                ? "USDT"
                : bridgeTxDetails.networkOut === "USD"
                ? "USDT"
                : bridgeTxDetails.networkOut
              : selectedToken2.blockchain2
          }

          // bridgeTxDetails={bridgeTxDetails}
          // selectedToken1={selectedToken1}
          // selectedToken2={selectedToken2}
        />
      ) : null}

      {/* ===== */}
      {/* ===== */}
      {/* ===== */}
      {/* ===== */}
      {/* ===== */}
      <div className="TokenBridgeModal_cont_3">
        {currentStep != 3 ? null : (
          <div className="TokenBridgeModal_cont_3_div1">
            <div className="TokenBridgeModal_cont_3_div1_cont1">
              <div className="TokenBridgeModal_cont_3_div1_cont1_time">
                <AccessTimeIcon className="TokenBridgeModal_cont_3_div1_cont1_time_icon" />{" "}
                <Timer deadline={new Date(bridgeTxDetails.endTime)} />
              </div>
              <div className="TokenBridgeModal_cont_3_div1_cont1_span">
                Time left to send {SwapAmount} {selectedToken1.symbol2}/
                {selectedToken1.blockchain2}
              </div>
            </div>
            <div className="TokenBridgeModal_cont_3_div1_cont1">
              <div className="TokenBridgeModal_cont_3_div1_cont1_txt_div">
                {localStorage.getItem("txid")}{" "}
                <ContentCopyIcon className="TokenBridgeModal_cont_3_div1_cont1_span_icon" />
              </div>
              <div className="TokenBridgeModal_cont_3_div1_cont1_span">
                Transaction ID{" "}
              </div>
            </div>
          </div>
        )}

        <div className="TokenBridgeModal_cont_3_div2">
          <div className="TokenBridgeModal_cont_3_title">
            Transaction details
          </div>
          <div className="TokenBridgeModal_cont_3_body">
            {bridgeExecution ? (
              <div className="TokenBridgeModal_cont_3_body_cont1">
                <div className="TokenBridgeModal_cont_3_body_cont1_title">
                  Transaction Id
                </div>
                <div className="TokenBridgeModal_cont_3_body_cont1_amounts">
                  {localStorage.getItem("txid")}
                </div>
              </div>
            ) : null}

            {/* === */}
            {/* === */}
            {/* === */}
            <div className="TokenBridgeModal_cont_3_body_cont1">
              <div className="TokenBridgeModal_cont_3_body_cont1_title">
                {bridgeExecution ? "You sent" : "You send"}
              </div>
              <div className="TokenBridgeModal_cont_3_body_cont1_amounts">
                {bridgeTxDetails.length <= 0
                  ? SwapAmount
                  : parseFloat(bridgeTxDetails.tokenAamount).toFixed(2)}{" "}
                {bridgeTxDetails.length <= 0 ? (
                  selectedToken1.symbol2
                ) : (
                  <>
                    {bridgeTxDetails.tickerIn === "USDE"
                      ? "USDT"
                      : bridgeTxDetails.tickerIn === "USD"
                      ? "USDT"
                      : bridgeTxDetails.tickerIn}
                  </>
                )}{" "}
                {bridgeTxDetails.length <= 0 ? (
                  <span
                    className="swap_symbol_network_div_network_span"
                    style={{
                      background:
                        selectedToken1.blockchain2 === "Ego20"
                          ? "#18854f"
                          : selectedToken1.blockchain2 === "Bep20"
                          ? "#dac00e"
                          : selectedToken1.blockchain2 === "Erc20"
                          ? "#4a55fe"
                          : selectedToken1.blockchain2 === "Ethereum"
                          ? "#4a55fe"
                          : selectedToken1.blockchain2 === "ETHEREUM"
                          ? "#4a55fe"
                          : "grey",
                    }}
                  >
                    {selectedToken1.blockchain2}
                  </span>
                ) : (
                  <span
                    className="swap_symbol_network_div_network_span"
                    style={{
                      background:
                        bridgeTxDetails.networkIn === "EGOCHAIN"
                          ? "#18854f"
                          : bridgeTxDetails.networkIn === "Egochain"
                          ? "#18854f"
                          : bridgeTxDetails.networkIn === "BINANCE"
                          ? "#dac00e"
                          : bridgeTxDetails.networkIn === "ETHEREUM"
                          ? "#4a55fe"
                          : bridgeTxDetails.networkIn === "Ethereum"
                          ? "#4a55fe"
                          : "grey",
                    }}
                  >
                    {bridgeTxDetails.networkIn === "EGOCHAIN"
                      ? "Ego20"
                      : bridgeTxDetails.networkIn === "Egochain"
                      ? "Ego20"
                      : bridgeTxDetails.networkIn === "BINANCE"
                      ? "Bep20"
                      : bridgeTxDetails.networkIn === "ETHEREUM"
                      ? "Erc20"
                      : bridgeTxDetails.networkIn === "Ethereum"
                      ? "Erc20"
                      : bridgeTxDetails.networkIn}
                  </span>
                )}
              </div>
            </div>
            {/* === */}
            {/* === */}
            {/* === */}
            <div className="TokenBridgeModal_cont_3_body_cont1">
              <div className="TokenBridgeModal_cont_3_body_cont1_title">
                Exchange rate
              </div>
              {bridgeTxDetails.length <= 0 ? (
                <div className="TokenBridgeModal_cont_3_body_cont1_amounts">
                  {priceLoading ? (
                    <ShimmerButton size="sm" className="custom_shimmer" />
                  ) : (
                    <>
                      {" "}
                      <div className="swap_price_rate_div1">
                        1
                        {selectedToken1 == null ? (
                          <ShimmerButton size="sm" className="custom_shimmer" />
                        ) : (
                          <> {selectedToken1.symbol2}</>
                        )}
                      </div>
                      <span style={{ marginRight: "5px" }}>=</span>
                      <div className="swap_price_rate_div1">
                        {selectedToken2 == null ? (
                          <ShimmerButton size="sm" className="custom_shimmer" />
                        ) : (
                          <>
                            {" "}
                            {numberWithCommas(marketPrice)}{" "}
                            {selectedToken2.symbol2}
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div className="TokenBridgeModal_cont_3_body_cont1_amounts">
                  {priceLoading ? (
                    <ShimmerButton size="sm" className="custom_shimmer" />
                  ) : (
                    <>
                      {" "}
                      <div className="swap_price_rate_div1">
                        1
                        {bridgeTxDetails.length <= 0 ? (
                          <ShimmerButton size="sm" className="custom_shimmer" />
                        ) : (
                          <>
                            {" "}
                            {bridgeTxDetails.tickerIn === "USDE"
                              ? "USDT"
                              : bridgeTxDetails.tickerIn === "USD"
                              ? "USDT"
                              : bridgeTxDetails.tickerIn}
                          </>
                        )}
                      </div>
                      <span style={{ marginRight: "5px" }}>=</span>
                      <div className="swap_price_rate_div1">
                        {bridgeTxDetails.length <= 0 ? (
                          <ShimmerButton size="sm" className="custom_shimmer" />
                        ) : (
                          <>
                            {" "}
                            {numberWithCommas(marketPrice)}{" "}
                            {bridgeTxDetails.tickerOut === "USDE"
                              ? "USDT"
                              : bridgeTxDetails.tickerOut === "USD"
                              ? "USDT"
                              : bridgeTxDetails.tickerOut}
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
            {/* === */}
            {/* === */}
            {/* === */}
            {bridgeTxDetails.length <= 0 ? (
              <div className="TokenBridgeModal_cont_3_body_cont1">
                <div className="TokenBridgeModal_cont_3_body_cont1_title">
                  Bridge fee 1%
                </div>
                <div className="TokenBridgeModal_cont_3_body_cont1_amounts">
                  {AmountOut * 0.01} {selectedToken2.symbol2}{" "}
                  <span
                    className="swap_symbol_network_div_network_span"
                    style={{
                      background:
                        selectedToken2.blockchain2 === "Ego20"
                          ? "#18854f"
                          : selectedToken2.blockchain2 === "Bep20"
                          ? "#dac00e"
                          : selectedToken2.blockchain2 === "Erc20"
                          ? "#4a55fe"
                          : "grey",
                    }}
                  >
                    {selectedToken2.blockchain2}
                  </span>
                </div>
              </div>
            ) : (
              <div className="TokenBridgeModal_cont_3_body_cont1">
                <div className="TokenBridgeModal_cont_3_body_cont1_title">
                  Bridge fee 1%
                </div>
                <div className="TokenBridgeModal_cont_3_body_cont1_amounts">
                  {parseFloat(
                    parseFloat(bridgeTxDetails.tokenBamount) * 0.01
                  ).toFixed(2)}{" "}
                  {bridgeTxDetails.tickerOut === "USDE"
                    ? "USDT"
                    : bridgeTxDetails.tickerOut === "USD"
                    ? "USDT"
                    : bridgeTxDetails.tickerOut}
                  <span
                    className="swap_symbol_network_div_network_span"
                    style={{
                      background:
                        bridgeTxDetails.networkOut === "EGOCHAIN"
                          ? "#18854f"
                          : bridgeTxDetails.networkOut === "Egochain"
                          ? "#18854f"
                          : bridgeTxDetails.networkOut === "BINANCE"
                          ? "#dac00e"
                          : bridgeTxDetails.networkOut === "ETHEREUM"
                          ? "#4a55fe"
                          : bridgeTxDetails.networkOut === "Ethereum"
                          ? "#4a55fe"
                          : "grey",
                    }}
                  >
                    {bridgeTxDetails.networkOut === "EGOCHAIN"
                      ? "Ego20"
                      : bridgeTxDetails.networkOut === "Egochain"
                      ? "Ego20"
                      : bridgeTxDetails.networkOut === "BINANCE"
                      ? "Bep20"
                      : bridgeTxDetails.networkOut === "ETHEREUM"
                      ? "Erc20"
                      : bridgeTxDetails.networkOut === "Ethereum"
                      ? "Erc20"
                      : bridgeTxDetails.networkOut}
                  </span>
                </div>
              </div>
            )}

            {/* === */}
            {/* === */}
            {/* === */}
            {bridgeTxDetails.length <= 0 ? (
              <div className="TokenBridgeModal_cont_3_body_cont1_active">
                <div className="TokenBridgeModal_cont_3_body_cont1_title">
                  You get
                </div>
                <div className="TokenBridgeModal_cont_3_body_cont1_amounts">
                  {SwapAmount == "" ? " " : AmountOut - AmountOut * 0.01}{" "}
                  {selectedToken2.symbol2}{" "}
                  <span
                    className="swap_symbol_network_div_network_span"
                    style={{
                      background:
                        selectedToken2.blockchain2 === "Ego20"
                          ? "#18854f"
                          : selectedToken2.blockchain2 === "Bep20"
                          ? "#dac00e"
                          : selectedToken2.blockchain2 === "Erc20"
                          ? "#4a55fe"
                          : "grey",
                    }}
                  >
                    {selectedToken2.blockchain2}
                  </span>
                </div>
              </div>
            ) : (
              <div className="TokenBridgeModal_cont_3_body_cont1_active">
                <div className="TokenBridgeModal_cont_3_body_cont1_title">
                  You get
                </div>
                <div className="TokenBridgeModal_cont_3_body_cont1_amounts">
                  {bridgeTxDetails.tokenBamount === ""
                    ? 0
                    : parseFloat(
                        parseFloat(bridgeTxDetails.tokenBamount) -
                          parseFloat(bridgeTxDetails.tokenBamount) * 0.01
                      ).toFixed(2)}{" "}
                  {bridgeTxDetails.tickerOut === "USDE"
                    ? "USDT"
                    : bridgeTxDetails.tickerOut === "USD"
                    ? "USDT"
                    : bridgeTxDetails.tickerOut}
                  <span
                    className="swap_symbol_network_div_network_span"
                    style={{
                      background:
                        bridgeTxDetails.networkOut === "EGOCHAIN"
                          ? "#18854f"
                          : bridgeTxDetails.networkOut === "Egochain"
                          ? "#18854f"
                          : bridgeTxDetails.networkOut === "BINANCE"
                          ? "#dac00e"
                          : bridgeTxDetails.networkOut === "ETHEREUM"
                          ? "#4a55fe"
                          : bridgeTxDetails.networkOut === "Ethereum"
                          ? "#4a55fe"
                          : "grey",
                    }}
                  >
                    {bridgeTxDetails.networkOut === "EGOCHAIN"
                      ? "Ego20"
                      : bridgeTxDetails.networkOut === "Egochain"
                      ? "Ego20"
                      : bridgeTxDetails.networkOut === "BINANCE"
                      ? "Bep20"
                      : bridgeTxDetails.networkOut === "ETHEREUM"
                      ? "Erc20"
                      : bridgeTxDetails.networkOut === "Ethereum"
                      ? "Erc20"
                      : bridgeTxDetails.networkOut}
                  </span>
                </div>
              </div>
            )}

            {/* === */}
            {/* === */}
            {/* === */}
          </div>
        </div>
      </div>

      {/* ======== */}
      {/* ======== */}
      {/* ======== */}
      {tokenModal1 ? (
        <div className=" dark updatedTokenModal">
          <div
            className="updatedTokenModal_closeDiv"
            onClick={() => {
              setTokenModal1(!tokenModal1);
            }}
          ></div>
          <div className="updatedTokenModal_area">
            <div className="updatedTokenModal_area1">
              <div className="updatedTokenModal_area1_head">
                <span>Select a token </span>
                <CloseIcon
                  className="updatedTokenModal_area1_head_close_icon"
                  onClick={() => {
                    setTokenModal1(!tokenModal1);
                  }}
                />
              </div>
              <div className="updatedTokenModal_area1_para">
                You can search and select any token on EgoSwap
              </div>
              <div className="updatedTokenModal_area1_search">
                <input
                  type="search"
                  name=""
                  id=""
                  className="updatedTokenModal_area1_search_input"
                />
              </div>
            </div>
            <div className="updatedTokenModal_area_body">
              <div className="updatedTokenModal_area_body_area">
                {selectedToken1 == null ? (
                  <>
                    {" "}
                    {TokenObject.map((token) => (
                      <button
                        id={token.id}
                        className="updatedTokenModal_area_body_area1"
                        onClick={() => closeTokenModal1(token)}
                      >
                        <div className="updatedTokenModal_area_body_area1_cont1">
                          <div className="updatedTokenModal_area_body_area1_cont1_div1">
                            <img
                              src={token.img}
                              alt=""
                              className="updatedTokenModal_area_body_area1_cont1_div1_img"
                            />
                          </div>
                          <div className="updatedTokenModal_area_body_area1_cont1_div2">
                            <div className="updatedTokenModal_area_body_area1_cont1_div2_cont1">
                              {token.symbol2}/{token.blockchain2}
                            </div>
                            <div className="updatedTokenModal_area_body_area1_cont1_div2_cont2">
                              {token.name}{" "}
                              <span
                                className="swap_symbol_network_div_network_span"
                                style={{
                                  background:
                                    token.blockchain2 === "Ego20"
                                      ? "#18854f"
                                      : token.blockchain2 === "Bep20"
                                      ? "#dac00e"
                                      : token.blockchain2 === "Erc20"
                                      ? "#4a55fe"
                                      : "grey",
                                }}
                              >
                                {token.blockchain2}
                              </span>
                            </div>
                          </div>
                        </div>
                      </button>
                    ))}
                  </>
                ) : (
                  <>
                    {TokenObject.map((token) => (
                      <button
                        disabled={token.id === selectedToken1.id ? true : false}
                        id={token.id}
                        className="updatedTokenModal_area_body_area1"
                        onClick={() => closeTokenModal1(token)}
                      >
                        <div className="updatedTokenModal_area_body_area1_cont1">
                          <div className="updatedTokenModal_area_body_area1_cont1_div1">
                            <img
                              src={token.img}
                              alt=""
                              className="updatedTokenModal_area_body_area1_cont1_div1_img"
                            />
                          </div>
                          <div className="updatedTokenModal_area_body_area1_cont1_div2">
                            <div className="updatedTokenModal_area_body_area1_cont1_div2_cont1">
                              {token.symbol2}/{token.blockchain2}
                            </div>
                            <div className="updatedTokenModal_area_body_area1_cont1_div2_cont2">
                              {token.name}{" "}
                              <span
                                className="swap_symbol_network_div_network_span"
                                style={{
                                  background:
                                    token.blockchain2 === "Ego20"
                                      ? "#18854f"
                                      : token.blockchain2 === "Bep20"
                                      ? "#dac00e"
                                      : token.blockchain2 === "Erc20"
                                      ? "#4a55fe"
                                      : "grey",
                                }}
                              >
                                {token.blockchain2}
                              </span>
                            </div>
                          </div>
                        </div>
                      </button>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {/* {tokenModal2 ? (
        <div className=" dark updatedTokenModal">
          <div
            className="updatedTokenModal_closeDiv"
            onClick={() => {
              setTokenModal2(!tokenModal2);
            }}
          ></div>
          <div className="updatedTokenModal_area">
            <div className="updatedTokenModal_area1">
              <div className="updatedTokenModal_area1_head">
                <span>Select a token </span>
                <CloseIcon
                  className="updatedTokenModal_area1_head_close_icon"
                  onClick={() => {
                    setTokenModal2(!tokenModal2);
                  }}
                />
              </div>
              <div className="updatedTokenModal_area1_para">
                You can search and select any token on egoswap
              </div>
              <div className="updatedTokenModal_area1_search">
                <input
                  type="search"
                  name=""
                  id=""
                  className="updatedTokenModal_area1_search_input"
                />
              </div>
            </div>
            <div className="updatedTokenModal_area_body">
              <div className="updatedTokenModal_area_body_area">
                {selectedToken2 == null ? (
                  <>
                    {" "}
                    {TokenObject.map((token) => (
                      <button
                        id={token.id}
                        className="updatedTokenModal_area_body_area1"
                        onClick={() => closeTokenModal2(token)}
                      >
                        <div className="updatedTokenModal_area_body_area1_cont1">
                          <div className="updatedTokenModal_area_body_area1_cont1_div1">
                            <img
                              src={token.img}
                              alt=""
                              className="updatedTokenModal_area_body_area1_cont1_div1_img"
                            />
                          </div>
                          <div className="updatedTokenModal_area_body_area1_cont1_div2">
                            <div className="updatedTokenModal_area_body_area1_cont1_div2_cont1">
                              {token.symbol2} / {token.blockchain2}
                            </div>
                            <div className="updatedTokenModal_area_body_area1_cont1_div2_cont2">
                              {token.name}{" "}
                              <span
                                className="swap_symbol_network_div_network_span"
                                style={{
                                  background:
                                    token.blockchain2 === "Ego20"
                                      ? "#18854f"
                                      : token.blockchain2 === "Bep20"
                                      ? "#dac00e"
                                      : token.blockchain2 === "Erc20"
                                      ? "#4a55fe"
                                      : "grey",
                                }}
                              >
                                {token.blockchain2}
                              </span>
                            </div>
                          </div>
                        </div>
                      </button>
                    ))}
                  </>
                ) : (
                  <>
                    {TokenObject.map((token) => (
                      <button
                        disabled={token.id == selectedToken2.id ? true : false}
                        id={token.id}
                        className="updatedTokenModal_area_body_area1"
                        onClick={() => closeTokenModal2(token)}
                      >
                        <div className="updatedTokenModal_area_body_area1_cont1">
                          <div className="updatedTokenModal_area_body_area1_cont1_div1">
                            <img
                              src={token.img}
                              alt=""
                              className="updatedTokenModal_area_body_area1_cont1_div1_img"
                            />
                          </div>
                          <div className="updatedTokenModal_area_body_area1_cont1_div2">
                            <div className="updatedTokenModal_area_body_area1_cont1_div2_cont1">
                              {token.symbol2}/ {token.blockchain2}
                            </div>
                            <div className="updatedTokenModal_area_body_area1_cont1_div2_cont2">
                              {token.name}{" "}
                              <span
                                className="swap_symbol_network_div_network_span"
                                style={{
                                  background:
                                    token.blockchain2 === "Ego20"
                                      ? "#18854f"
                                      : token.blockchain2 === "Bep20"
                                      ? "#dac00e"
                                      : token.blockchain2 === "Erc20"
                                      ? "#4a55fe"
                                      : "grey",
                                }}
                              >
                                {token.blockchain2}
                              </span>
                            </div>
                          </div>
                        </div>
                      </button>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null} */}

      {errorModal ? (
        <ErrorModal
          ErrorTxt={errorTxt}
          errorFunc={() => {
            setErrorModal(false);
          }}
        />
      ) : null}
      {abortModal ? (
        <div className="AbortModal">
          <div className="AbortModal_cont">
            <div className="AbortModal_cont_title">Abort Transaction</div>
            <div className="AbortModal_cont_txt">
              {" "}
              Are you sure you want to abort this transaction?
            </div>
            <div className="AbortModal_cont_btns">
              <button
                className="updatedSwapSwapBtn_abort"
                onClick={CloseAbortModal}
              >
                No Cancel
              </button>
              <button
                disabled={abortdisabled}
                className="updatedSwapSwapBtn"
                onClick={abortTransaction}
              >
                {abortLoading ? (
                  <ScaleLoader color="#446f59" height={10} />
                ) : (
                  " Yes Abort"
                )}
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {proceddTransacModal ? (
        <div className="AbortModal">
          <div className="AbortModal_cont">
            <div className="AbortModal_cont_title">Proceed</div>
            <div className="AbortModal_cont_txt">
              {" "}
              Are you sure you have confirmed the deposit of{" "}
              {parseFloat(bridgeTxDetails.tokenAamount).toFixed(2)}{" "}
              {bridgeTxDetails.tickerIn === "USDE"
                ? "USDT"
                : bridgeTxDetails.tickerIn === "USD"
                ? "USDT"
                : bridgeTxDetails.tickerIn}
              ({" "}
              {bridgeTxDetails.networkIn === "EGOCHAIN"
                ? "Ego20"
                : bridgeTxDetails.networkIn === "BINANCE"
                ? "Bep20"
                : bridgeTxDetails.networkIn === "ETHEREUM"
                ? "Erc20"
                : bridgeTxDetails.networkIn}
              ) in{" "}
              {`${bridgeTxDetails.user_wallet.slice(
                0,
                8
              )}...${bridgeTxDetails.user_wallet.slice(20, 25)}`}{" "}
            </div>
            <div className="AbortModal_cont_btns">
              <button
                className="updatedSwapSwapBtn_abort"
                onClick={CloseProceedModal}
              >
                Back
              </button>
              <button
                disabled={proceddTransacdisabled}
                className="updatedSwapSwapBtn"
                onClick={SubmitSentFunds}
              >
                {proceddTransacLoading ? (
                  <ScaleLoader color="#446f59" height={10} />
                ) : (
                  " Proceed"
                )}
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default BridgeModal;
