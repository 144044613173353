import React, { useEffect, useState } from "react";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { QRCode } from "react-qrcode-logo";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { GET_WALLET } from "../../services/finance_services";
import { useSelector } from "react-redux";
import SmallCompLoader from "../../components/SmallCompLoader/SmallCompLoader";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const DepositUsd = ({
  symbol,
  symbol2,
  tokenName,
  img,
  ToggleEgcBlockchainDepositModal,
}) => {
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    address: "",
    message: "",
  });
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [networkDiv, setNetworkDiv] = useState(false);
  const Network = [
    {
      id: "1",
      network: "BNB Smart Chain (BEP20)",
      name: "Binance Smart Chain",
      img: "/img/bsc_icon.png",
    },
    {
      id: "2",
      network: "Egochain",
      name: "Egochain",
      img: "/img/egax_logo.png",
    },
  ];
  const Network2 = [
    {
      id: "1",
      network: "Ethereum (ERC20)",
      name: "Ethereum",
      img: "/img/eth_icon.png",
    },
    {
      id: "2",
      network: "Egochain",
      name: "Egochain",
      img: "/img/egax_logo.png",
    },
  ];
  const fetchWallet = async (val) => {
    if (val !== null) {
      setLoading(true);
      const response = await GET_WALLET({
        symbol:
          val.network === "Ethereum (ERC20)" && symbol2 === "ESTA"
            ? "ESTA"
            : val.network === "Egochain" && symbol2 === "ESTA"
            ? "ESTAE"
            : val.network === "BNB Smart Chain (BEP20)" && symbol2 === "USDT"
            ? "USD"
            : val.network === "Egochain" && symbol2 === "USDT"
            ? "USDE"
            : symbol,
      });

      console.log(response);

      if (!response.success) {
        setLoading(false);
        return;
      }

      setPayload(response.data);
      setLoading(false);
      return;
    }
  };

  if (loading) {
    return (
      <div className="depositMoneyDiv">
        <div className="depositMoneyDiv_cont">
          <SmallCompLoader loadingTxt={"Loading please wait"} />
        </div>
      </div>
    );
  }
  const copyText = () => {
    var copyText = document.getElementById("myInput");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);

    var tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "Copied address ";
    tooltip.style.display = "block";
  };
  function outFunc() {
    var tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "Copy to clipboard";
    tooltip.style.display = "none";
  }

  const toggleNetworkDiv = () => {
    setNetworkDiv(!networkDiv);
  };
  const selectNetwork = (value) => {
    fetchWallet(value);
    setPayload({ ...payload, network: value.network });
    setSelectedNetwork(value);
    toggleNetworkDiv();
    console.log("====================================");
    console.log(value);
    console.log("====================================");
  };
  return (
    <div className="depositMoneyDiv">
      <div className="depositMoneyDiv_cont">
        <ArrowBackOutlinedIcon
          className="depositMoneyDiv_icon"
          onClick={ToggleEgcBlockchainDepositModal}
        />
        <div className="depositMoneyDiv_cont_1">
          <div className="depositMoneyDiv_cont_title_cont">
            <div className="depositMoneyDiv_cont_title_cont_title">
              Deposit {symbol2}
            </div>
            <div className="depositMoneyDiv_cont_title_cont_para">
              Add funds directly from a blockchain account
            </div>
          </div>
          <div className="depositMoneyDiv_cont_body">
            <div className="depositMoneyDiv_cont_body_input_div">
              <div className="depositMoneyDiv_cont_body_input_div_title">
                Coin:
              </div>
              <div className="depositMoneyDiv_cont_body_input_div_div">
                <div className="depositMoneyDiv_cont_body_input_div_div_cont1">
                  <img
                    src={img}
                    alt=""
                    className="depositMoneyDiv_cont_body_input_div_div_cont1_img"
                  />
                  {tokenName}
                </div>
                <div className="depositMoneyDiv_cont_body_input_div_div_cont2">
                  {symbol2}
                </div>
              </div>
            </div>
            <div className="depositMoneyDiv_cont_body_input_div2">
              <div className="depositMoneyDiv_cont_body_input_div_title">
                Network:
              </div>
              {symbol2 === "USDT" ? (
                <>
                  <div
                    className="depositMoneyDiv_cont_body_input_div_div"
                    onClick={toggleNetworkDiv}
                  >
                    <div className="depositMoneyDiv_cont_body_input_div_div_div">
                      {selectedNetwork === null ? (
                        <>Select Network</>
                      ) : (
                        <>
                          {" "}
                          <div className="depositMoneyDiv_cont_body_input_div_div_cont1">
                            <img
                              src={selectedNetwork.img}
                              alt=""
                              className="depositMoneyDiv_cont_body_input_div_div_cont1_img"
                            />
                            {selectedNetwork.name}
                          </div>
                          <div className="depositMoneyDiv_cont_body_input_div_div_cont2">
                            {selectedNetwork.network}
                          </div>
                        </>
                      )}
                    </div>
                    <KeyboardArrowDownIcon />
                  </div>

                  {networkDiv ? (
                    <div className="network_select_div">
                      {Network.map((data, key) => (
                        <div
                          className="network_select_div_cont1"
                          key={key}
                          id={data.id}
                          onClick={() => selectNetwork(data)}
                        >
                          <img
                            src={data.img}
                            alt=""
                            className="network_select_div_cont1_img"
                          />
                          <div className="network_select_div_cont1_txt">
                            {data.name}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : null}
                </>
              ) : symbol2 === "ESTA" ? (
                <>
                  <div
                    className="depositMoneyDiv_cont_body_input_div_div"
                    onClick={toggleNetworkDiv}
                  >
                    <div className="depositMoneyDiv_cont_body_input_div_div_div">
                      {selectedNetwork === null ? (
                        <>Select Network</>
                      ) : (
                        <>
                          {" "}
                          <div className="depositMoneyDiv_cont_body_input_div_div_cont1">
                            <img
                              src={selectedNetwork.img}
                              alt=""
                              className="depositMoneyDiv_cont_body_input_div_div_cont1_img"
                            />
                            {selectedNetwork.name}
                          </div>
                          <div className="depositMoneyDiv_cont_body_input_div_div_cont2">
                            {selectedNetwork.network}
                          </div>
                        </>
                      )}
                    </div>
                    <KeyboardArrowDownIcon />
                  </div>

                  {networkDiv ? (
                    <div className="network_select_div">
                      {Network2.map((data, key) => (
                        <div
                          className="network_select_div_cont1"
                          key={key}
                          id={data.id}
                          onClick={() => selectNetwork(data)}
                        >
                          <img
                            src={data.img}
                            alt=""
                            className="network_select_div_cont1_img"
                          />
                          <div className="network_select_div_cont1_txt">
                            {data.name}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : null}
                </>
              ) : (
                <>
                  <div
                    className="depositMoneyDiv_cont_body_input_div_div"
                    onClick={toggleNetworkDiv}
                  >
                    <div className="depositMoneyDiv_cont_body_input_div_div_div">
                      {selectedNetwork === null ? (
                        <>Select Network</>
                      ) : (
                        <>
                          {" "}
                          <div className="depositMoneyDiv_cont_body_input_div_div_cont1">
                            <img
                              src={selectedNetwork.img}
                              alt=""
                              className="depositMoneyDiv_cont_body_input_div_div_cont1_img"
                            />
                            {selectedNetwork.name}
                          </div>
                          <div className="depositMoneyDiv_cont_body_input_div_div_cont2">
                            {selectedNetwork.network}
                          </div>
                        </>
                      )}
                    </div>
                    <KeyboardArrowDownIcon />
                  </div>

                  {networkDiv ? (
                    <div className="network_select_div">
                      {/* {Network[1].map((data, key) => ( */}
                      <div
                        className="network_select_div_cont1"
                        id={Network[1].id}
                        onClick={() => selectNetwork(Network[1])}
                      >
                        <img
                          src={Network[1].img}
                          alt=""
                          className="network_select_div_cont1_img"
                        />
                        <div className="network_select_div_cont1_txt">
                          {Network[1].name}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </>
              )}
            </div>

            {selectedNetwork === null ? null : (
              <>
                {" "}
                <div className="depositMoneyDiv_cont_body_qr_div">
                  <QRCode
                    value={payload?.address}
                    quietZone={5}
                    eyeColor="#fff"
                    bgColor="#161619"
                    fgColor="#fff"
                    logoImage="/img/tether_icon.png"
                    eyeRadius={[
                      [5, 5, 0, 5],
                      [5, 5, 5, 0],
                      [5, 0, 5, 5],
                    ]}
                    removeQrCodeBehindLogo={true}
                    // logoPadding={5}
                    // logoWidth={15}
                    logoPaddingStyle="circle"
                    className="depositMoneyDiv_cont_body_qr_div_qr"
                  />
                  <div className="depositMoneyDiv_cont_body_qr_div_txt">
                    Send only {symbol2} to this deposit address. Ensure the
                    network is{" "}
                    {symbol2 === "EGAX" ? "Egochain" : selectedNetwork.network}{" "}
                    . Do not send NFTs to this address
                  </div>
                </div>
                <div className="depositMoneyDiv_cont_body_wallet_addr_div">
                  <div className="depositMoneyDiv_cont_body_wallet_addr_div_title">
                    WalletAddress:
                  </div>

                  <div className="depositMoneyDiv_cont_body_wallet_addr_div_input_div">
                    <input
                      type="text"
                      value={payload.address}
                      className="depositMoneyDiv_cont_body_wallet_addr_div_input"
                      id="myInput"
                    />
                    <button
                      className="depositMoneyDiv_cont_body_wallet_addr_div_btn"
                      onClick={copyText}
                      onMouseOut={outFunc}
                    >
                      Copy
                      <ContentCopyOutlinedIcon className="depositMoneyDiv_cont_body_wallet_addr_div_btn_icon" />
                      <span className="tooltiptext" id="myTooltip"></span>
                    </button>
                  </div>
                </div>
                <div className="depositMoneyDiv_cont_body_tips_div">
                  <div className="depositMoneyDiv_cont_body_tips_div_1">
                    <InfoOutlinedIcon className="depositMoneyDiv_cont_body_tips_div_1_icon" />
                    <div className="depositMoneyDiv_cont_body_tips_div_1_txt">
                      Send only {symbol2} to this deposit address
                    </div>
                  </div>
                  <div className="depositMoneyDiv_cont_body_tips_div_1">
                    <InfoOutlinedIcon className="depositMoneyDiv_cont_body_tips_div_1_icon" />
                    <div className="depositMoneyDiv_cont_body_tips_div_1_txt">
                      Ensure the network is{" "}
                      {symbol2 === "EGAX"
                        ? "Egochain"
                        : selectedNetwork.network}
                    </div>
                  </div>
                  <div className="depositMoneyDiv_cont_body_tips_div_1">
                    <InfoOutlinedIcon className="depositMoneyDiv_cont_body_tips_div_1_icon" />
                    <div className="depositMoneyDiv_cont_body_tips_div_1_txt">
                      Do not send Nfts to this address
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="depositMoneyDiv_cont_2">
          <button
            className="depositMoneyDiv_cont_2_btn"
            onClick={ToggleEgcBlockchainDepositModal}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default DepositUsd;
