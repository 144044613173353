import { useState, useEffect, useRef } from "react";
import { ShimmerButton } from "react-shimmer-effects-18";
import CheckIcon from "@mui/icons-material/Check";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ClipLoader from "react-spinners/ClipLoader";
import ScaleLoader from "react-spinners/ScaleLoader";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import CloseIcon from "@mui/icons-material/Close";
import ErrorModal from "../../../components/ErrorModal/ErrorModal";
import { numberWithCommas } from "../../../assets/js/numberWithCommas";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import JoinFullIcon from "@mui/icons-material/JoinFull";
import BridgeExecution from "./BridgeExecution";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Timer from "./Timer";
import {
  GET_SWAP_PRICE,
  INITIALIZE_BRIDGE_ROUTE,
  FETCH_BRIDGE_TX,
  SENT_FUNDS,
} from "../../../services/swap_services";
import Swap from "../../Swap/Swap";

const Bridge = ({ OpenBridgeModal, tokenBridgeModal }) => {
  const { data } = useSelector((state) => state.wallet);
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [priceLoading, setPriceLoading] = useState(true);
  const [SwapAmount, setSwapAmount] = useState("");
  const [AmountOut, setAmountOut] = useState("");
  const [loading, setLoading] = useState(false);
  const [marketPrice, setMarketPrice] = useState(0.0);
  const [tokenModal1, setTokenModal1] = useState(false);
  const [tokenModal2, setTokenModal2] = useState(false);
  // const [tokenBridgeModal, setTokenBridgeModal] = useState(false);
  const [BridgeExchange, setTokenBridgeExchange] = useState(true);
  const [bridgeCheckout, setBridgeCheckout] = useState(false);
  const [bridgeCheckoutAddress, setBridgeCheckoutAddress] = useState(false);
  const [bridgeExecution, setBridgeExecution] = useState(false);
  const [currentStep, setCurrentStep] = useState(2);
  const [errorModal, setErrorModal] = useState(false);
  const [bridgeTxDetails, setBridgeTxDetails] = useState([]);
  const [errorTxt, setErrorTxt] = useState("");
  const [ReceiverAddress, setReceiverAddress] = useState("");
  const [selectedToken1, setSelectedToken1] = useState({
    id: "0",
    name: "Tether USD",
    name2: "Dollar",
    symbol: "USDE",
    symbol2: "USDT",
    blockchain: "EGOCHAIN",
    blockchain2: "Ego20",
    img: "/img/usdt_img.png",
    balance: 0.0,
  });
  const [selectedToken2, setSelectedToken2] = useState({
    id: "1",
    name: "Tether USD",
    name2: "Dollar",
    symbol: "USD",
    symbol2: "USDT",
    blockchain: "BINANCE",
    blockchain2: "Bep20",
    img: "/img/usdt_img.png",
    balance: 0.0,
  });
  // console.log("====================================");
  // console.log(selectedToken1);
  // console.log("====================================");

  const getSwapPrice = async () => {
    setPriceLoading(true);
    if (selectedToken1 === null || selectedToken2 === null) {
      return;
    }

    if (selectedToken1.symbol === "ESTAE" && selectedToken2.symbol === "ESTA") {
      // // console.log("USD/EGC");
      setPriceLoading(true);
      const response = await GET_SWAP_PRICE({
        tokenA: "ESTA",
        tokenB: "ESTAE",
      });
      // console.log(response, "lahshs");

      // console.log(response);
      if (!response.success) {
        setPriceLoading(false);
        return;
      }
      setMarketPrice(response.data.price);
      setPriceLoading(false);
      return;
    }
    if (selectedToken1.symbol === "ESTA" && selectedToken2.symbol === "ESTAE") {
      // // console.log("USD/EGC");
      setPriceLoading(true);
      const response = await GET_SWAP_PRICE({
        tokenA: "ESTA",
        tokenB: "ESTAE",
      });
      // console.log(response, "lahshs");

      // console.log(response);
      if (!response.success) {
        setPriceLoading(false);
        return;
      }
      setMarketPrice(response.data.price);
      setPriceLoading(false);
      return;
    }
    if (selectedToken1.symbol === "USDE" && selectedToken2.symbol === "USD") {
      // // console.log("USD/EGC");
      setPriceLoading(true);
      const response = await GET_SWAP_PRICE({
        tokenA: "USD",
        tokenB: "USDE",
      });
      // console.log(response, "lahshs");

      // console.log(response);
      if (!response.success) {
        setPriceLoading(false);
        return;
      }
      setMarketPrice(response.data.price);
      setPriceLoading(false);
      return;
    }

    if (selectedToken1.symbol === "USD" && selectedToken2.symbol === "USDE") {
      // // console.log("USD/EGC");
      setPriceLoading(true);
      const response = await GET_SWAP_PRICE({
        tokenA: "USD",
        tokenB: "USDE",
      });
      // // console.log(response);
      if (!response.success) {
        setPriceLoading(false);
        return;
      }
      setMarketPrice(1 / response.data.price);
      setPriceLoading(false);

      return;
    }
  };

  const getTxByTXID = async () => {
    // console.log("====================================");
    // console.log("SAUFE8MNTA");
    // console.log("====================================");
    const response = await FETCH_BRIDGE_TX(localStorage.getItem("txid"));

    // console.log("====================================");
    if (response.code === 200) {
      setBridgeTxDetails(response.data);
      // console.log(response);
    } else {
      setBridgeTxDetails([]);
      // console.log(response);
    }

    // console.log("====================================");
  };
  useEffect(() => {
    getSwapPrice();
    // getSwapPrice2();
  }, [selectedToken1, selectedToken2]);

  useEffect(() => {
    // console.log("====================================");
    // console.log(localStorage.getItem("txid"));
    // console.log("====================================");
    if (localStorage.getItem("txid") !== null) {
      getTxByTXID();
      // console.log("====================================");
      // console.log(bridgeTxDetails);
      // console.log("====================================");
      return;
    }
  }, [localStorage.getItem("txid")]);

  const onChangeSwapAmount = (e) => {
    let parseNumber = parseFloat(e.target.value);
    setSwapAmount(e.target.value);
    setAmountOut(parseNumber * marketPrice);
  };
  const ToggleSwapInputs = () => {
    setSwapAmount("");
    setAmountOut("");
    setSelectedToken2(selectedToken1);
    setSelectedToken1(selectedToken2);
  };
  const OpenTokenModal1 = () => {
    setTokenModal1(!tokenModal1);
  };

  const closeTokenModal1 = (token) => {
    setSwapAmount("");
    setTokenModal1(!tokenModal1);

    if (token.id === "2") {
      setSelectedToken2({
        id: "3",
        name: "EgoStation",
        name2: "ESTA",
        symbol: "ESTAE",
        symbol2: "ESTA",
        blockchain: "Egochain",
        blockchain2: "Ego20",
        img: "/img/esta_logo.jpeg",
        balance: 0.0,
      });
    }

    if (token.id === "3") {
      setSelectedToken2({
        id: "2",
        name: "EgoStation",
        name2: "ESTA",
        symbol: "ESTA",
        symbol2: "ESTA",
        blockchain: "Ethereum",
        blockchain2: "Erc20",
        img: "/img/esta_logo.jpeg",
        balance: 0.0,
      });
    }
    if (token.id === "0") {
      setSelectedToken2({
        id: "1",
        name: "Tether USD",
        name2: "Dollar",
        symbol: "USD",
        symbol2: "USDT",
        blockchain: "Binance Smartchain",
        blockchain2: "Bep20",
        img: "/img/usdt_img.png",
        balance: 0.0,
      });
    }
    if (token.id === "1") {
      setSelectedToken2({
        id: "0",
        name: "Tether USD",
        name2: "Dollar",
        symbol: "USDE",
        symbol2: "USDT",
        blockchain: "Egochain",
        blockchain2: "Ego20",
        img: "/img/usdt_img.png",
        balance: 0.0,
      });
    }

    if (selectedToken1 === null && selectedToken2 === null) {
      setSelectedToken1(token);
      console.log("====================================");
      console.log(token);
      console.log("====================================");

      return;
    }
    if (selectedToken2 !== null && selectedToken1 === null) {
      if (token.id === selectedToken2.id) {
        setSelectedToken2(selectedToken1);
        setSelectedToken1(selectedToken2);
      } else {
        setSelectedToken1(token);
      }
      return;
    }

    if (selectedToken1 !== null && selectedToken2 === null) {
      setSelectedToken1(token);
      return;
    }

    if (selectedToken1 !== null && selectedToken2 !== null) {
      if (token.id === selectedToken2.id) {
        setSelectedToken2(selectedToken1);
        setSelectedToken1(selectedToken2);
      } else {
        setSelectedToken1(token);
      }
      return;
    }
  };

  const TokenObject = [
    {
      id: "0",
      name: "Tether USD",
      name2: "Dollar",
      symbol: "USDE",
      symbol2: "USDT",
      blockchain: "Egochain",
      blockchain2: "Ego20",
      img: "/img/usdt_img.png",
      balance: 0.0,
    },
    {
      id: "1",
      name: "Tether USD",
      name2: "Dollar",
      symbol: "USD",
      symbol2: "USDT",
      blockchain: "Binance Smartchain",
      blockchain2: "Bep20",
      img: "/img/usdt_img.png",
      balance: 0.0,
    },
    {
      id: "2",
      name: "EgoStation",
      name2: "ESTA",
      symbol: "ESTA",
      symbol2: "ESTA",
      blockchain: "Ethereum",
      blockchain2: "Erc20",
      img: "/img/esta_logo.jpeg",
      balance: 0.0,
    },
    {
      id: "3",
      name: "EgoStation",
      name2: "ESTA",
      symbol: "ESTAE",
      symbol2: "ESTA",
      blockchain: "Egochain",
      blockchain2: "Ego20",
      img: "/img/esta_logo.jpeg",
      balance: 0.0,
    },
  ];

  useEffect(() => {
    const bridgeStep = localStorage.getItem("BridgeStep");
    // console.log("====================================");
    // console.log(bridgeStep);
    // console.log("====================================");
    if (bridgeStep === "1") {
      setCurrentStep(1);
      return;
    }
    if (bridgeStep === "2") {
      setCurrentStep(2);
      return;
    }
    if (bridgeStep === "3") {
      setCurrentStep(3);
      return;
    }
    if (bridgeStep === "4") {
      setCurrentStep(4);
      return;
    }
  }, []);

  useEffect(() => {
    const bridgeStep = localStorage.getItem("BridgeStep");
    // console.log("====================================");
    // console.log(bridgeStep);
    // console.log("====================================");
    if (bridgeStep === "1") {
      return;
    }
    if (bridgeStep === "2") {
      // setTokenBridgeModal(true);
      setTokenBridgeExchange(true);
      return;
    }
    if (bridgeStep === "3") {
      // setTokenBridgeModal(true);
      setBridgeCheckoutAddress(true);
      setTokenBridgeExchange(false);
      return;
    }
    if (bridgeStep === "4") {
      // setTokenBridgeModal(true);
      setBridgeExecution(true);
      setTokenBridgeExchange(false);
      setBridgeCheckoutAddress(false);

      return;
    }
  }, []);

  return (
    <div className="liquidity_cont">
      <div className="liquidity_cont_body">
        <div className="liquidity_cont_body_conts">
          <div className="liquidity_cont_body_conts_cont1">
            <div className="input_amnt_layer">
              <div className="amnt_input">
                <div className="amnt_input_layer1">
                  <div className="amnt_input_layer1_input_div">
                    <div className="amnt_input_layer1_input_div_span">
                      You send
                    </div>
                    {priceLoading ? (
                      <ShimmerButton size="lg" className="custom_shimmer" />
                    ) : (
                      <input
                        type="number"
                        name="number"
                        id="number"
                        placeholder="0.00"
                        className="amnt_input_field"
                        autocomplete="off"
                        onChange={onChangeSwapAmount}
                        value={SwapAmount}
                      />
                    )}
                  </div>

                  <div className="Swap_icondropDownDiv">
                    {/* <span className="token_balances_span">
                                    Balance:{" "}
                                    {selectedToken1
                                      ? numberWithCommas(
                                          parseFloat(
                                            selectedToken1.balance == null
                                              ? 0
                                              : selectedToken1.balance
                                          ).toFixed(4)
                                        )
                                      : "0.00"}
                                  </span> */}

                    <button
                      className="display_tokens_drop2"
                      onClick={() => OpenTokenModal1()}
                    >
                      {selectedToken1 ? (
                        <div className="selectedTokenInfo">
                          <img
                            src={selectedToken1.img}
                            alt=""
                            className="display_tokens_drop_img"
                          />
                          <div className="swap_symbol_network_div">
                            <span className="selectedTokenSymbol">
                              {selectedToken1.symbol2}/
                              {selectedToken1.blockchain2}{" "}
                              <ArrowDropDownIcon className="liquidity_select_div_btn1_icon" />
                            </span>
                            <span className="swap_symbol_network_div_network">
                              {selectedToken1.blockchain}{" "}
                              <span
                                className="swap_symbol_network_div_network_span"
                                style={{
                                  background:
                                    selectedToken1.blockchain2 === "Ego20"
                                      ? "#18854f"
                                      : selectedToken1.blockchain2 === "Bep20"
                                      ? "#dac00e"
                                      : selectedToken1.blockchain2 === "Erc20"
                                      ? "#4a55fe"
                                      : "grey",
                                }}
                              >
                                {selectedToken1.blockchain2}
                              </span>
                            </span>
                          </div>
                        </div>
                      ) : (
                        "Select Currency"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="plus_icon_layer"> */}
            <SwapVertIcon
              className="toggle_swap_inputs2"
              onClick={ToggleSwapInputs}
            />

            <div className="input_amnt_layer">
              <div className="amnt_input">
                <div className="amnt_input_layer1">
                  <div className="amnt_input_layer1_input_div">
                    <div className="amnt_input_layer1_input_div_span">
                      You receive
                    </div>
                    {priceLoading ? (
                      <ShimmerButton size="lg" className="custom_shimmer" />
                    ) : (
                      <input
                        type="number"
                        name="number"
                        id="number"
                        placeholder="0.00"
                        className="amnt_input_field"
                        autocomplete="off"
                        value={
                          SwapAmount == "" ? " " : AmountOut - AmountOut * 0.01
                        }
                      />
                    )}
                  </div>
                  <div className="Swap_icondropDownDiv">
                    {/* <span className="token_balances_span">
                                    Balance:{" "}
                                    {selectedToken2
                                      ? numberWithCommas(
                                          parseFloat(
                                            selectedToken2.balance == null
                                              ? 0
                                              : selectedToken2.balance
                                          ).toFixed(4)
                                        )
                                      : "0.00"}
                                  </span> */}
                    <button
                      className="display_tokens_drop2"
                      // onClick={() => OpenTokenModal2()}
                    >
                      {selectedToken2 ? (
                        <div className="selectedTokenInfo">
                          <img
                            src={selectedToken2.img}
                            alt=""
                            className="display_tokens_drop_img"
                          />
                          <div className="swap_symbol_network_div">
                            <span className="selectedTokenSymbol">
                              {selectedToken2.symbol2}/
                              {selectedToken2.blockchain2}{" "}
                              {/* <ArrowDropDownIcon className="liquidity_select_div_btn1_icon" /> */}
                            </span>
                            <span className="swap_symbol_network_div_network">
                              {selectedToken2.blockchain}{" "}
                              <span
                                className="swap_symbol_network_div_network_span"
                                style={{
                                  background:
                                    selectedToken2.blockchain2 === "Ego20"
                                      ? "#18854f"
                                      : selectedToken2.blockchain2 === "Bep20"
                                      ? "#dac00e"
                                      : selectedToken2.blockchain2 === "Erc20"
                                      ? "#4a55fe"
                                      : "grey",
                                }}
                              >
                                {selectedToken2.blockchain2}
                              </span>
                            </span>
                          </div>
                        </div>
                      ) : (
                        "Select Currency"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* </div> */}
          </div>
          <div className="swap_price_rate_div">
            {priceLoading ? (
              <ShimmerButton size="sm" className="custom_shimmer" />
            ) : (
              <>
                {" "}
                <div className="swap_price_rate_div1">
                  1
                  {selectedToken1 == null ? (
                    <ShimmerButton size="sm" className="custom_shimmer" />
                  ) : (
                    <> {selectedToken1.symbol}</>
                  )}
                </div>
                <span style={{ marginRight: "5px" }}>=</span>
                <div className="swap_price_rate_div1">
                  {selectedToken2 == null ? (
                    <ShimmerButton size="sm" className="custom_shimmer" />
                  ) : (
                    <>
                      {" "}
                      {numberWithCommas(marketPrice)} {selectedToken2.symbol}
                    </>
                  )}
                </div>
              </>
            )}
          </div>

          {user === null || user === undefined || !user ? (
            <button
              id="generate"
              class="updatedSwapSwapBtn"
              onClick={() => {
                localStorage.setItem("RedirectRoute", window.location.href);
                navigate("/login");
              }}
            >
              Login/SignUp
            </button>
          ) : (
            <button className="updatedSwapSwapBtn" onClick={OpenBridgeModal}>
              Bridge
            </button>
          )}
        </div>
      </div>

      {tokenModal1 ? (
        <div className=" dark updatedTokenModal">
          <div
            className="updatedTokenModal_closeDiv"
            onClick={() => {
              setTokenModal1(!tokenModal1);
            }}
          ></div>
          <div className="updatedTokenModal_area">
            <div className="updatedTokenModal_area1">
              <div className="updatedTokenModal_area1_head">
                <span>Select a token </span>
                <CloseIcon
                  className="updatedTokenModal_area1_head_close_icon"
                  onClick={() => {
                    setTokenModal1(!tokenModal1);
                  }}
                />
              </div>
              <div className="updatedTokenModal_area1_para">
                You can search and select any token on EgoSwap
              </div>
              <div className="updatedTokenModal_area1_search">
                <input
                  type="search"
                  name=""
                  id=""
                  className="updatedTokenModal_area1_search_input"
                />
              </div>
            </div>
            <div className="updatedTokenModal_area_body">
              <div className="updatedTokenModal_area_body_area">
                {selectedToken1 == null ? (
                  <>
                    {" "}
                    {TokenObject.map((token) => (
                      <button
                        id={token.id}
                        className="updatedTokenModal_area_body_area1"
                        onClick={() => closeTokenModal1(token)}
                      >
                        <div className="updatedTokenModal_area_body_area1_cont1">
                          <div className="updatedTokenModal_area_body_area1_cont1_div1">
                            <img
                              src={token.img}
                              alt=""
                              className="updatedTokenModal_area_body_area1_cont1_div1_img"
                            />
                          </div>
                          <div className="updatedTokenModal_area_body_area1_cont1_div2">
                            <div className="updatedTokenModal_area_body_area1_cont1_div2_cont1">
                              {token.symbol2}/{token.blockchain2}
                            </div>
                            <div className="updatedTokenModal_area_body_area1_cont1_div2_cont2">
                              {token.name}{" "}
                              <span
                                className="swap_symbol_network_div_network_span"
                                style={{
                                  background:
                                    token.blockchain2 === "Ego20"
                                      ? "#18854f"
                                      : token.blockchain2 === "Bep20"
                                      ? "#dac00e"
                                      : token.blockchain2 === "Erc20"
                                      ? "#4a55fe"
                                      : "grey",
                                }}
                              >
                                {token.blockchain2}
                              </span>
                            </div>
                          </div>
                        </div>
                      </button>
                    ))}
                  </>
                ) : (
                  <>
                    {TokenObject.map((token) => (
                      <button
                        disabled={token.id === selectedToken1.id ? true : false}
                        id={token.id}
                        className="updatedTokenModal_area_body_area1"
                        onClick={() => closeTokenModal1(token)}
                      >
                        <div className="updatedTokenModal_area_body_area1_cont1">
                          <div className="updatedTokenModal_area_body_area1_cont1_div1">
                            <img
                              src={token.img}
                              alt=""
                              className="updatedTokenModal_area_body_area1_cont1_div1_img"
                            />
                          </div>
                          <div className="updatedTokenModal_area_body_area1_cont1_div2">
                            <div className="updatedTokenModal_area_body_area1_cont1_div2_cont1">
                              {token.symbol2}/{token.blockchain2}
                            </div>
                            <div className="updatedTokenModal_area_body_area1_cont1_div2_cont2">
                              {token.name}{" "}
                              <span
                                className="swap_symbol_network_div_network_span"
                                style={{
                                  background:
                                    token.blockchain2 === "Ego20"
                                      ? "#18854f"
                                      : token.blockchain2 === "Bep20"
                                      ? "#dac00e"
                                      : token.blockchain2 === "Erc20"
                                      ? "#4a55fe"
                                      : "grey",
                                }}
                              >
                                {token.blockchain2}
                              </span>
                            </div>
                          </div>
                        </div>
                      </button>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {/* {tokenModal2 ? (
        <div className=" dark updatedTokenModal">
          <div
            className="updatedTokenModal_closeDiv"
            onClick={() => {
              setTokenModal2(!tokenModal2);
            }}
          ></div>
          <div className="updatedTokenModal_area">
            <div className="updatedTokenModal_area1">
              <div className="updatedTokenModal_area1_head">
                <span>Select a token </span>
                <CloseIcon
                  className="updatedTokenModal_area1_head_close_icon"
                  onClick={() => {
                    setTokenModal2(!tokenModal2);
                  }}
                />
              </div>
              <div className="updatedTokenModal_area1_para">
                You can search and select any token on egoswap
              </div>
              <div className="updatedTokenModal_area1_search">
                <input
                  type="search"
                  name=""
                  id=""
                  className="updatedTokenModal_area1_search_input"
                />
              </div>
            </div>
            <div className="updatedTokenModal_area_body">
              <div className="updatedTokenModal_area_body_area">
                {selectedToken2 == null ? (
                  <>
                    {" "}
                    {TokenObject.map((token) => (
                      <button
                        id={token.id}
                        className="updatedTokenModal_area_body_area1"
                        onClick={() => closeTokenModal2(token)}
                      >
                        <div className="updatedTokenModal_area_body_area1_cont1">
                          <div className="updatedTokenModal_area_body_area1_cont1_div1">
                            <img
                              src={token.img}
                              alt=""
                              className="updatedTokenModal_area_body_area1_cont1_div1_img"
                            />
                          </div>
                          <div className="updatedTokenModal_area_body_area1_cont1_div2">
                            <div className="updatedTokenModal_area_body_area1_cont1_div2_cont1">
                              {token.symbol2} / {token.blockchain2}
                            </div>
                            <div className="updatedTokenModal_area_body_area1_cont1_div2_cont2">
                              {token.name}{" "}
                              <span
                                className="swap_symbol_network_div_network_span"
                                style={{
                                  background:
                                    token.blockchain2 === "Ego20"
                                      ? "#18854f"
                                      : token.blockchain2 === "Bep20"
                                      ? "#dac00e"
                                      : token.blockchain2 === "Erc20"
                                      ? "#4a55fe"
                                      : "grey",
                                }}
                              >
                                {token.blockchain2}
                              </span>
                            </div>
                          </div>
                        </div>
                      </button>
                    ))}
                  </>
                ) : (
                  <>
                    {TokenObject.map((token) => (
                      <button
                        disabled={token.id == selectedToken2.id ? true : false}
                        id={token.id}
                        className="updatedTokenModal_area_body_area1"
                        onClick={() => closeTokenModal2(token)}
                      >
                        <div className="updatedTokenModal_area_body_area1_cont1">
                          <div className="updatedTokenModal_area_body_area1_cont1_div1">
                            <img
                              src={token.img}
                              alt=""
                              className="updatedTokenModal_area_body_area1_cont1_div1_img"
                            />
                          </div>
                          <div className="updatedTokenModal_area_body_area1_cont1_div2">
                            <div className="updatedTokenModal_area_body_area1_cont1_div2_cont1">
                              {token.symbol2}/ {token.blockchain2}
                            </div>
                            <div className="updatedTokenModal_area_body_area1_cont1_div2_cont2">
                              {token.name}{" "}
                              <span
                                className="swap_symbol_network_div_network_span"
                                style={{
                                  background:
                                    token.blockchain2 === "Ego20"
                                      ? "#18854f"
                                      : token.blockchain2 === "Bep20"
                                      ? "#dac00e"
                                      : token.blockchain2 === "Erc20"
                                      ? "#4a55fe"
                                      : "grey",
                                }}
                              >
                                {token.blockchain2}
                              </span>
                            </div>
                          </div>
                        </div>
                      </button>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null} */}
    </div>
  );
};

export default Bridge;
