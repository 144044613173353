import React from "react";
import successLoader from "../../assets/icons/LottieSuccess.json";
import "./successModal.css";
import CallMadeIcon from "@mui/icons-material/CallMade";
import Lottie from "lottie-react";
const SuccessModal = ({
  SuccesTxt,
  successFunc,
  txnHashDiv = true,
  TxnHash = "--",
  chainExplorer,
}) => {
  return (
    <div className="successModalDiv">
      <div className="successModalDiv_cont">
        <div className="successModalDiv_cont_animation">
          <Lottie
            animationData={successLoader}
            loop={false}
            autoPlay={true}
            className="successModalDiv_cont_animation_icon"
            preserveAspectRatio="xMidYMid meet"
          />
        </div>
        <div className="successModalDiv_cont_body">
          <div className="successModalDiv_cont_body_title">Success</div>
          <div className="successModalDiv_cont_body_para">{SuccesTxt}</div>
          {txnHashDiv ? (
            <a
              href={chainExplorer}
              className="successModalDiv_cont_body_tx_div"
              target="_blank"
            >
              Txhash: {`${TxnHash.slice(0, 8)}...${TxnHash.slice(24, 32)}`}
              <CallMadeIcon className="successModalDiv_cont_body_tx_div_icon" />
            </a>
          ) : null}
        </div>
        <div className="successModalDiv_cont_button_div">
          <button className="successModalDiv_cont_button" onClick={successFunc}>
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
