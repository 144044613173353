import React, { useState, useEffect, useRef } from "react";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./UpdatedSwap.css";
import "react-awesome-animated-number/dist/index.css";
import "./AddLiquidity.css";
import { toast, ToastContainer } from "react-toastify";
import { ShimmerButton } from "react-shimmer-effects-18";
import JoinFullIcon from "@mui/icons-material/JoinFull";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckIcon from "@mui/icons-material/Check";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ClipLoader from "react-spinners/ClipLoader";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import Bridge from "./BridgeComponents/Bridge";
import BridgeModal from "./BridgeComponents/BridgeModal";
// import "./dashboardAddLiquidity.css";
import {
  GET_SWAP_PRICE,
  SWAP,
  FETCH_SWAP,
} from "../../../Cex/services/swap_services";
import { numberWithCommas } from "../../../Cex/assets/js/numberWithCommas";
import WebPin from "../../../Cex/components/CommonUI/Modals/WebPin";
import { useSelector } from "react-redux";
import SuccessModal from "../../../Cex/components/CommonUI/Modals/SuccessModal/SuccessModal";
import ErrorModal from "../../../Cex/components/CommonUI/Modals/ErrorModal/ErrorModal";
import { Am, To } from "react-country-flags-select";
import CloseIcon from "@mui/icons-material/Close";
import { FETCH_WALLET_TRANSACTIONS } from "../../services/finance_services";
import "./TokenModal/UpatedTokenModal.css";
import { routeHelper } from "../../../../helper";
import { Link } from "react-router-dom";
import SwapButton from "../../components/SwapButton/SwapButton";
import {
  Table,
  TablePagination,
} from "../../components/CommonUI/Tables/TableComp";
import Staticdata from "../../assets/json/Static";
import { SET_USER_PIN } from "../../services/auth";

const UpdatedSwap = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { data } = useSelector((state) => state.wallet);
  const [SwapAmount, setSwapAmount] = useState("");
  const [AmountOut, setAmountOut] = useState("");
  const [loading, setLoading] = useState(false);
  const [pin2, setPin2] = useState("");
  const [swapDisable, setSwapDisable] = useState(true);
  const [pinModal2, setPinModal2] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorModal, setErrorModal] = useState(false);
  const [errorTxt, setErrorTxt] = useState("");
  const [successTxt, setSuccessTxt] = useState("");
  const [tokenModal1, setTokenModal1] = useState(false);
  const [tokenModal2, setTokenModal2] = useState(false);
  const [activeTab, setActiveTab] = useState("bridge");
  const [tokenBridgeModal, setTokenBridgeModal] = useState(false);
  const [BridgeExchange, setTokenBridgeExchange] = useState(true);
  const [bridgeCheckout, setBridgeCheckout] = useState(false);
  const [bridgeCheckoutAddress, setBridgeCheckoutAddress] = useState(false);
  const [bridgeExecution, setBridgeExecution] = useState(false);

  const [selectedToken1, setSelectedToken1] = useState({
    id: "0",
    name: "Egochain",
    name2: "Egochain",
    symbol: "EGAX",
    symbol2: "EGAX",
    img: "/img/egax_logo.png",
    balance: 0.0,
  });
  const [selectedToken2, setSelectedToken2] = useState({
    id: "1",
    name: "Tether USD",
    name2: "Dollar",
    symbol: "USD",
    symbol2: "USDT",
    img: "/img/usdt_img.png",
    balance: 0.0,
  });
  const [marketPrice, setMarketPrice] = useState(0.0);
  const [priceLoading, setPriceLoading] = useState(true);
  const [contentLoadingTable, setContentLoadingTable] = useState(true);
  const [pin, setPinVal] = useState("");
  const [confirmPin, setConfirmPinVal] = useState("");
  const [confirmPinModal, setConfirmPinModal] = useState(false);
  const [pinModal, setPinModal] = useState(false);
  const [pinProcessing, setPinProcessing] = useState(false);

  console.log(data, "{}{}}{}{}{}{}{}{}");
  const getSwapPrice = async () => {
    setPriceLoading(true);
    if (selectedToken1 === null || selectedToken2 === null) {
      return;
    }

    if (selectedToken1.symbol === "EGAX" && selectedToken2.symbol === "USD") {
      // // console.log("USD/EGC");
      setPriceLoading(true);
      const response = await GET_SWAP_PRICE({
        tokenA: "USD",
        tokenB: "EGAX",
      });
      // // console.log(response);
      if (!response.success) {
        setPriceLoading(false);
        return;
      }
      setMarketPrice(response.data.price);
      setPriceLoading(false);
      return;
    }

    if (selectedToken1.symbol === "USD" && selectedToken2.symbol === "EGAX") {
      // // console.log("USD/EGC");
      setPriceLoading(true);
      const response = await GET_SWAP_PRICE({
        tokenA: "USD",
        tokenB: "EGAX",
      });
      // // console.log(response);
      if (!response.success) {
        setPriceLoading(false);
        return;
      }
      setMarketPrice(1 / response.data.price);
      setPriceLoading(false);

      return;
    }

    // if (selectedToken1.symbol === "EGC" && selectedToken2.symbol === "USD") {
    //   // // console.log("USD/EGC");
    //   setPriceLoading(true);
    //   const response = await GET_SWAP_PRICE({
    //     tokenA: "USD",
    //     tokenB: "EGC",
    //   });
    //   // // console.log(response);
    //   if (!response.success) {
    //     setPriceLoading(false);
    //     return;
    //   }
    //   setMarketPrice(response.data.price);
    //   setPriceLoading(false);
    //   return;
    // }

    // if (selectedToken1.symbol === "USD" && selectedToken2.symbol === "EGC") {
    //   // // console.log("USD/EGC");
    //   setPriceLoading(true);
    //   const response = await GET_SWAP_PRICE({
    //     tokenA: "USD",
    //     tokenB: "EGC",
    //   });
    //   // // console.log(response);
    //   if (!response.success) {
    //     setPriceLoading(false);
    //     return;
    //   }
    //   setMarketPrice(1 / response.data.price);
    //   setPriceLoading(false);

    //   return;
    // }

    // if (selectedToken1.symbol === "NGN" && selectedToken2.symbol === "EGC") {
    //   // // console.log("NGN/EGC");
    //   setPriceLoading(true);
    //   const response = await GET_SWAP_PRICE({
    //     tokenA: "NGN",
    //     tokenB: "EGC",
    //   });
    //   // // console.log(response);
    //   if (!response.success) {
    //     setPriceLoading(false);
    //     return;
    //   }
    //   setMarketPrice(1 / response.data.price);
    //   setPriceLoading(false);
    //   return;
    // }

    // if (selectedToken1.symbol === "EGC" && selectedToken2.symbol === "NGN") {
    //   // // console.log("NGN/EGC");
    //   setPriceLoading(true);
    //   const response = await GET_SWAP_PRICE({
    //     tokenA: "NGN",
    //     tokenB: "EGC",
    //   });
    //   // // console.log(response);
    //   if (!response.success) {
    //     setPriceLoading(false);
    //     return;
    //   }
    //   setMarketPrice(response.data.price);
    //   setPriceLoading(false);
    //   return;
    // }

    // if (selectedToken1.symbol === "USD" && selectedToken2.symbol === "NGN") {
    //   // // console.log("USD/NGN");
    //   setPriceLoading(true);
    //   const response = await GET_SWAP_PRICE({
    //     tokenA: "USD",
    //     tokenB: "NGN",
    //   });
    //   // // console.log(response);

    //   if (!response.success) {
    //     setPriceLoading(false);
    //     return;
    //   }
    //   setMarketPrice(1 / response.data.price);
    //   setPriceLoading(false);
    //   return;
    // }

    // if (selectedToken1.symbol === "NGN" && selectedToken2.symbol === "USD") {
    //   // // console.log("USD/NGN");
    //   setPriceLoading(true);
    //   const response = await GET_SWAP_PRICE({
    //     tokenA: "USD",
    //     tokenB: "NGN",
    //   });
    //   // // console.log(response);
    //   if (!response.success) {
    //     setPriceLoading(false);
    //     return;
    //   }
    //   setMarketPrice(response.data.price);
    //   setPriceLoading(false);
    //   return;
    // }
  };
  const getSwapTickers = async () => {
    const response = await FETCH_SWAP();
    // // console.log(response);
  };
  // // console.log(marketPrice);
  // // console.log(user);
  useEffect(() => {
    getSwapTickers();
  }, []);

  const TokenObject = [
    {
      id: "0",
      name: "Egochain",
      name2: "Egochain",
      symbol: "EGAX",
      symbol2: "EGAX",
      img: "/img/egax_logo.png",
      balance: 0.0,
    },
    {
      id: "1",
      name: "Tether USD",
      name2: "Dollar",
      symbol: "USD",
      symbol2: "USDT",
      img: "/img/usdt_img.png",
      balance: 0.0,
    },
  ];

  useEffect(() => {
    getSwapPrice();
  }, [selectedToken1, selectedToken2]);
  // =================
  // =================
  // =================
  // =================
  // =================
  // =================
  // =================
  const process = () => {
    setPinModal2(true);
  };

  // =================
  // =================
  // =================
  // =================
  // =================
  // =================
  // =================

  useEffect(() => {
    if (selectedToken1) {
      // // console.log(parseFloat(SwapAmount), parseFloat(selectedToken1.balance));
      const ParsedSwapAmount = parseFloat(SwapAmount);
      const ParsedTokenBal = parseFloat(
        selectedToken1.balance == null ? 0 : selectedToken1.balance
      );
      if (
        ParsedSwapAmount > ParsedTokenBal ||
        ParsedSwapAmount <= 0 ||
        SwapAmount === ""
      ) {
        setSwapDisable(true);
        return;
      } else {
        setSwapDisable(false);
      }
      return;
    }
  }, [SwapAmount, selectedToken1]);

  // =================
  // =================
  // =================
  // =================
  // =================
  // =================
  // =================
  // =================
  const TokenSwap = async () => {
    setLoading(true);
    if (selectedToken1 === null || selectedToken2 === null) {
      return;
    }

    // if (selectedToken1.symbol === "EGC" && selectedToken2.symbol === "USD") {
    //   const payload = {
    //     pin_code: pin,
    //     ticker: "USD/EGC",
    //     tokenIn: selectedToken1.symbol,
    //     tokenOut: selectedToken2.symbol,
    //     amountIn: SwapAmount,
    //     amountOut: AmountOut,
    //     origin: "CEX",
    //     swapWithMarketPrice: false,
    //   };
    //   //// // console.logog(payload);
    //   const response = await SWAP(payload);
    //   //// // console.logog(response);
    //   if (response.success === true) {
    //     setLoading(false);
    //     setSuccessModal(true);
    //     setPinModal(false);
    //     setSuccessTxt(
    //       ` You have successfully swapped ${SwapAmount} ${selectedToken1.symbol} for ${AmountOut} ${selectedToken2.symbol}`
    //     );
    //     //// // console.logog(response);
    //     return;
    //   }
    //   if (!response?.data?.success || !response?.data) {
    //     setLoading(false);
    //     setPinModal(false);
    //     setErrorModal(true);
    //     setErrorTxt(response.data.errorMessage);
    //     //// // console.logog(response);
    //     return;
    //   }
    //   return;
    // }

    // if (selectedToken1.symbol === "USD" && selectedToken2.symbol === "EGC") {
    //   const payload = {
    //     pin_code: pin,
    //     ticker: "USD/EGC",
    //     tokenIn: selectedToken1.symbol,
    //     tokenOut: selectedToken2.symbol,
    //     amountIn: SwapAmount,
    //     amountOut: AmountOut,
    //     origin: "CEX",
    //     swapWithMarketPrice: false,
    //   };
    //   //// // console.logog(payload);
    //   const response = await SWAP(payload);
    //   //// // console.logog(response);
    //   if (response.success === true) {
    //     setLoading(false);
    //     setSuccessModal(true);
    //     setPinModal(false);
    //     setSuccessTxt(
    //       ` You have successfully swapped ${SwapAmount} ${selectedToken1.symbol} for ${AmountOut} ${selectedToken2.symbol}`
    //     );
    //     //// // console.logog(response);
    //     return;
    //   }
    //   if (!response?.data?.success || !response?.data) {
    //     setLoading(false);
    //     setPinModal(false);
    //     setErrorModal(true);
    //     setErrorTxt(response.data.errorMessage);
    //     //// // console.logog(response);
    //     return;
    //   }
    //   return;
    // }

    // if (selectedToken1.symbol === "NGN" && selectedToken2.symbol === "EGC") {
    //   const payload = {
    //     pin_code: pin,
    //     ticker: "NGN/EGC",
    //     tokenIn: selectedToken1.symbol,
    //     tokenOut: selectedToken2.symbol,
    //     amountIn: SwapAmount,
    //     amountOut: AmountOut,
    //     origin: "CEX",
    //     swapWithMarketPrice: false,
    //   };
    //   //// // console.logog(payload);
    //   const response = await SWAP(payload);
    //   //// // console.logog(response);
    //   if (response.success === true) {
    //     setLoading(false);
    //     setSuccessModal(true);
    //     setPinModal(false);
    //     setSuccessTxt(
    //       ` You have successfully swapped ${SwapAmount} ${selectedToken1.symbol} for ${AmountOut} ${selectedToken2.symbol}`
    //     );
    //     //// // console.logog(response);
    //     return;
    //   }
    //   if (!response?.data?.success || !response?.data) {
    //     setLoading(false);
    //     setPinModal(false);
    //     setErrorModal(true);
    //     setErrorTxt(response.data.errorMessage);
    //     //// // console.logog(response);
    //     return;
    //   }
    //   return;
    // }

    // if (selectedToken1.symbol === "EGC" && selectedToken2.symbol === "NGN") {
    //   const payload = {
    //     pin_code: pin,
    //     ticker: "NGN/EGC",
    //     tokenIn: selectedToken1.symbol,
    //     tokenOut: selectedToken2.symbol,
    //     amountIn: SwapAmount,
    //     amountOut: AmountOut,
    //     origin: "CEX",
    //     swapWithMarketPrice: false,
    //   };
    //   //// // console.logog(payload);
    //   const response = await SWAP(payload);
    //   //// // console.logog(response);
    //   if (response.success === true) {
    //     setLoading(false);
    //     setSuccessModal(true);
    //     setPinModal(false);
    //     setSuccessTxt(
    //       ` You have successfully swapped ${SwapAmount} ${selectedToken1.symbol} for ${AmountOut} ${selectedToken2.symbol}`
    //     );
    //     //// // console.logog(response);
    //     return;
    //   }
    //   if (!response?.data?.success || !response?.data) {
    //     setLoading(false);
    //     setPinModal(false);
    //     setErrorModal(true);
    //     setErrorTxt(response.data.errorMessage);
    //     //// // console.logog(response);
    //     return;
    //   }
    //   return;
    // }

    if (selectedToken1.symbol === "USD" && selectedToken2.symbol === "EGAX") {
      const payload = {
        pin_code: pin2,
        ticker: "USD/EGAX",
        tokenIn: selectedToken1.symbol,
        tokenOut: selectedToken2.symbol,
        amountIn: SwapAmount,
        amountOut: AmountOut,
        origin: "",
        swapWithMarketPrice: false,
      };
      // // console.log(payload);
      const response = await SWAP(payload);
      //// // console.logog(response);
      if (response.success === true) {
        setLoading(false);
        setSuccessModal(true);
        setPinModal2(false);
        setSuccessTxt(
          ` You have successfully swapped ${SwapAmount} ${selectedToken1.symbol} for ${AmountOut} ${selectedToken2.symbol}`
        );
        //// // console.logog(response);
        return;
      }
      if (!response?.data?.success || !response?.data) {
        setLoading(false);
        setPinModal2(false);
        setErrorModal(true);
        setErrorTxt(response.data.errorMessage);
        //// // console.logog(response);
        return;
      }
      return;
    }

    if (selectedToken1.symbol === "EGAX" && selectedToken2.symbol === "USD") {
      const payload = {
        pin_code: pin2,
        ticker: "USD/EGAX",
        tokenIn: selectedToken1.symbol,
        tokenOut: selectedToken2.symbol,
        amountIn: SwapAmount,
        amountOut: AmountOut,
        origin: "",
        swapWithMarketPrice: false,
      };
      // // console.log(payload, "egaxusd");
      const response = await SWAP(payload);
      //// // console.logog(response);
      if (response.success === true) {
        setLoading(false);
        setSuccessModal(true);
        setPinModal2(false);
        setSuccessTxt(
          ` You have successfully swapped ${SwapAmount} ${selectedToken1.symbol} for ${AmountOut} ${selectedToken2.symbol}`
        );
        //// // console.logog(response);
        return;
      }
      if (!response?.data?.success || !response?.data) {
        setLoading(false);
        setPinModal2(false);
        setErrorModal(true);
        setErrorTxt(response.data.errorMessage);
        //// // console.logog(response);
        return;
      }
      return;
    }
  };
  // =================
  // =================
  // =================
  // =================
  // =================

  // ===========Swap Functions Start=====================
  // ================================
  // ================================
  // ================================
  // ================================
  const onChangeSwapAmount = (e) => {
    let parseNumber = parseFloat(e.target.value);
    setSwapAmount(e.target.value);
    setAmountOut(parseNumber * marketPrice);
  };

  // // console.log(SwapAmount);
  const ToggleSwapInputs = () => {
    setSwapAmount("");
    setSelectedToken2(selectedToken1);
    setSelectedToken1(selectedToken2);
  };

  const add25Per = () => {
    const parseNumber = parseFloat(
      (selectedToken1.balance == null ? 0 : selectedToken1.balance) * 0.25
    );
    setSwapAmount(parseNumber);
    setAmountOut(parseNumber * marketPrice);
  };

  const add50Per = () => {
    const parseNumber = parseFloat(
      (selectedToken1.balance == null ? 0 : selectedToken1.balance) * 0.5
    );
    setSwapAmount(parseNumber);
    setAmountOut(parseNumber * marketPrice);
  };

  const add75Per = () => {
    const parseNumber = parseFloat(
      (selectedToken1.balance == null ? 0 : selectedToken1.balance) * 0.75
    );
    setSwapAmount(parseNumber);
    setAmountOut(parseNumber * marketPrice);
  };

  const add100Per = () => {
    const parseNumber = parseFloat(
      (selectedToken1.balance == null ? 0 : selectedToken1.balance) * 1
    );
    setSwapAmount(parseNumber);
    setAmountOut(parseNumber * marketPrice);
  };

  const OpenTokenModal1 = () => {
    setTokenModal1(!tokenModal1);
  };

  const closeTokenModal1 = (token) => {
    setSwapAmount("");
    setTokenModal1(!tokenModal1);
    if (selectedToken1 === null && selectedToken2 === null) {
      setSelectedToken1(token);
      return;
    }
    if (selectedToken2 !== null && selectedToken1 === null) {
      if (token.id === selectedToken2.id) {
        setSelectedToken2(selectedToken1);
        setSelectedToken1(selectedToken2);
      } else {
        setSelectedToken1(token);
      }
      return;
    }
    if (selectedToken1 !== null && selectedToken2 === null) {
      setSelectedToken1(token);
      return;
    }
    if (selectedToken1 !== null && selectedToken2 !== null) {
      if (token.id === selectedToken2.id) {
        setSelectedToken2(selectedToken1);
        setSelectedToken1(selectedToken2);
      } else {
        setSelectedToken1(token);
      }
      return;
    }
  };

  const OpenTokenModal2 = () => {
    setTokenModal2(!tokenModal2);
  };

  const closeTokenModal2 = (token) => {
    setSwapAmount("");
    setTokenModal2(!tokenModal2);
    // // console.log(token);
    // // console.log(selectedToken1);
    // // console.log(selectedToken2);
    if (selectedToken1 === null && selectedToken2 === null) {
      setSelectedToken2(token);
      return;
    }
    if (selectedToken1 !== null && selectedToken2 === null) {
      if (token.id === selectedToken1.id) {
        setSelectedToken1(selectedToken2);
        setSelectedToken2(selectedToken1);
      } else {
        setSelectedToken2(token);
      }
      return;
    }
    if (selectedToken2 !== null && selectedToken1 === null) {
      setSelectedToken2(token);
      return;
    }
    if (selectedToken2 !== null && selectedToken1 !== null) {
      if (token.id === selectedToken1.id) {
        setSelectedToken1(selectedToken2);
        setSelectedToken2(selectedToken1);
      } else {
        setSelectedToken2(token);
      }
      return;
    }
  };
  useEffect(() => {
    // // console.log(data);
    // // console.log(data.length);
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        switch (data[i].name) {
          case selectedToken1.name2:
            // // console.log("====================================");
            // // console.log(data[i]);
            // // console.log("====================================");
            // setNairaBalance(data[i]?.value === null ? "0" : data[i]?.value);
            // setSelectedToken1(...selectedToken1, selectedToken1.balancedata[i]?.value === null ? "0" : data[i]?.value);
            setSelectedToken1({
              ...selectedToken1,
              balance: data[i]?.value === null ? "0" : data[i]?.value,
            });
            // setEgaxBalanceUsd(
            //   data[i]?.usd_bal === null ? "0" : data[i]?.usd_bal
            // );
            break;
          case selectedToken2.name2:
            setSelectedToken2({
              ...selectedToken2,
              balance: data[i]?.value === null ? "0" : data[i]?.value,
            });
            // setUsdtBalance(data[i]?.value === null ? "0" : data[i]?.value);
            break;
        }
      }
      return;
    }
  }, [data]);

  // // console.log(data);
  const updatedTokenObject = TokenObject.map((token) => {
    // // console.log(data, "imade");
    let matchingNewToken;
    if (data.length >= 1) {
      matchingNewToken = data.find(
        (newToken) => newToken.symbol === token.symbol
      );
      // // console.log(data, "okey");
    }

    if (matchingNewToken) {
      token.balance =
        matchingNewToken.value == null ? 0 : matchingNewToken.value;
    }
    return token;
  });

  const fetchWalletTransactions = async () => {
    setContentLoadingTable(true);
    const response = await FETCH_WALLET_TRANSACTIONS();
    if (response.success === true) {
      setContentLoadingTable(false);
      setTableData(response.data);
    } else {
      setContentLoadingTable(true);
      //  setTableData([]);
    }
    //// // console.logog(response.data);
    //// // console.logog(response);
  };
  useEffect(() => {
    fetchWalletTransactions();
  }, []);

  const setPin = async () => {
    setPinModal(true);
  };

  useEffect(() => {
    //check if the pin is empty
    // if (
    //   (user && user?.user_pin === null) ||
    //   user?.user_pin === "" ||
    //   user?.user_pin === undefined
    // ) {
    //   setPin();
    // }

    if (!user) {
      setPinModal(false);
      return;
    }
    if (
      user.user_pin === null ||
      user.user_pin === undefined ||
      user.user_pin === ""
    ) {
      setPin();
      return;
    }
  }, [user]);

  const proceedToConfirm = () => {
    setPinModal(false);
    setConfirmPinModal(true);
  };
  const processPinRequest = async () => {
    if (pin !== confirmPin) {
      toast.warn("Pin does not match");
      //// // console.logog("pin does not match");
      return;
    }

    setPinProcessing(true);
    const response = await SET_USER_PIN({
      code: pin,
      type: "set",
    });

    //// // console.logog(response);

    if (response.success) {
      toast.success("Pin is set Successfully");
      navigate(0);
      return;
    }

    toast.warn(response.errorMessage);
  };

  const handleOnComplete1 = (e) => {
    const value = e.join("");
    setPinVal(value);
  };
  const handleOnComplete2 = (e) => {
    const value = e.join("");
    setConfirmPinVal(value);
  };
  const ToggleActiveTab = (e) => {
    setActiveTab(e.currentTarget.id);
  };

  const bridgeStep1 = async () => {
    // setBridgeCheckout(true);
    // setTokenBridgeExchange(false);
    //call api to submit information
  };
  const bridgeStep2 = () => {
    setBridgeCheckout(false);
    setBridgeCheckoutAddress(true);
    setTokenBridgeExchange(false);
  };
  const bridgeStep3 = () => {
    setBridgeCheckout(false);
    setBridgeCheckoutAddress(false);
    setTokenBridgeExchange(false);
    setBridgeExecution(true);
  };
  const OpenBridgeModal = () => {
    setTokenBridgeModal(true);
  };
  useEffect(() => {
    const bridgeStep = localStorage.getItem("BridgeStep");
    // console.log("====================================");
    // console.log(bridgeStep);
    // console.log("====================================");
    if (bridgeStep === "1") {
      return;
    }
    if (bridgeStep === "2") {
      setTokenBridgeModal(true);

      return;
    }
    if (bridgeStep === "3") {
      setTokenBridgeModal(true);

      return;
    }
    if (bridgeStep === "4") {
      setTokenBridgeModal(true);

      return;
    }
  }, []);
  return (
    <div className="ex_div">
      <section className="ex_section">
        <div className="container">
          {tokenBridgeModal ? (
            <BridgeModal />
          ) : (
            <div className="swapDivCont">
              <div className="swapDivCont_txt">
                <div className="swapDivCont_txt_1">
                  Exchange any crypto
                  <br />
                  <span className="swapDivCont_txt_1_span">instantly</span>
                </div>
              </div>
              <div className="liquidity_area">
                <div className="liquidity_area1">
                  <div className="swap_container_settings_cont">
                    <div
                      className={
                        activeTab === "bridge"
                          ? "swap_container_settings_cont_1_active"
                          : "swap_container_settings_cont_1"
                      }
                      id="bridge"
                      onClick={ToggleActiveTab}
                    >
                      Bridge
                    </div>
                    {/* <div
                      className={
                        activeTab === "ex"
                          ? "swap_container_settings_cont_1_active"
                          : "swap_container_settings_cont_1"
                      }
                      id="ex"
                      onClick={ToggleActiveTab}
                    >
                      Exchange
                    </div> */}
                  </div>
                  {activeTab === "ex" ? (
                    <div className="liquidity_cont">
                      <div className="liquidity_cont_body">
                        <div className="liquidity_cont_body_conts">
                          <div className="liquidity_cont_body_conts_cont1">
                            <div className="input_amnt_layer">
                              <div className="amnt_input">
                                <div className="amnt_input_layer1">
                                  <div className="amnt_input_layer1_input_div">
                                    <div className="amnt_input_layer1_input_div_span">
                                      You sell
                                    </div>
                                    {priceLoading ? (
                                      <ShimmerButton
                                        size="lg"
                                        className="custom_shimmer"
                                      />
                                    ) : (
                                      <input
                                        type="number"
                                        name="number"
                                        id="number"
                                        placeholder="0.00"
                                        className="amnt_input_field"
                                        autocomplete="off"
                                        onChange={onChangeSwapAmount}
                                        value={SwapAmount}
                                      />
                                    )}
                                  </div>

                                  <div className="Swap_icondropDownDiv">
                                    <span className="token_balances_span">
                                      Balance:{" "}
                                      {selectedToken1
                                        ? numberWithCommas(
                                            parseFloat(
                                              selectedToken1.balance == null
                                                ? 0
                                                : selectedToken1.balance
                                            ).toFixed(4)
                                          )
                                        : "0.00"}
                                    </span>

                                    <button
                                      className="display_tokens_drop"
                                      onClick={() => OpenTokenModal1()}
                                    >
                                      {selectedToken1 ? (
                                        <div className="selectedTokenInfo">
                                          <img
                                            src={selectedToken1.img}
                                            alt=""
                                            className="display_tokens_drop_img"
                                          />
                                          <span className="selectedTokenSymbol">
                                            {selectedToken1.symbol2}
                                          </span>
                                        </div>
                                      ) : (
                                        "Select Currency"
                                      )}
                                      <ArrowDropDownIcon className="liquidity_select_div_btn1_icon" />
                                    </button>
                                  </div>
                                </div>
                                <div className="amnt_input_layer2">
                                  <button
                                    className="amnt_input_layer2_cont1"
                                    disabled={
                                      selectedToken1 && selectedToken2
                                        ? false
                                        : true
                                    }
                                    onClick={() => add25Per()}
                                  >
                                    25%
                                  </button>
                                  <button
                                    className="amnt_input_layer2_cont1"
                                    disabled={
                                      selectedToken1 && selectedToken2
                                        ? false
                                        : true
                                    }
                                    onClick={() => add50Per()}
                                  >
                                    50%
                                  </button>
                                  <button
                                    className="amnt_input_layer2_cont1"
                                    disabled={
                                      selectedToken1 && selectedToken2
                                        ? false
                                        : true
                                    }
                                    onClick={() => add75Per()}
                                  >
                                    75%
                                  </button>
                                  <button
                                    className="amnt_input_layer2_cont1_last"
                                    disabled={
                                      selectedToken1 && selectedToken2
                                        ? false
                                        : true
                                    }
                                    onClick={() => add100Per()}
                                  >
                                    100%
                                  </button>
                                </div>
                              </div>
                            </div>

                            {/* <div className="plus_icon_layer"> */}
                            <SwapVertIcon
                              className="toggle_swap_inputs"
                              onClick={ToggleSwapInputs}
                            />

                            <div className="input_amnt_layer">
                              <div className="amnt_input">
                                <div className="amnt_input_layer1">
                                  <div className="amnt_input_layer1_input_div">
                                    <div className="amnt_input_layer1_input_div_span">
                                      You get
                                    </div>
                                    {priceLoading ? (
                                      <ShimmerButton
                                        size="lg"
                                        className="custom_shimmer"
                                      />
                                    ) : (
                                      <input
                                        type="number"
                                        name="number"
                                        id="number"
                                        placeholder="0.00"
                                        className="amnt_input_field"
                                        autocomplete="off"
                                        value={
                                          SwapAmount == "" ? " " : AmountOut
                                        }
                                      />
                                    )}
                                  </div>
                                  <div className="Swap_icondropDownDiv">
                                    <span className="token_balances_span">
                                      Balance:{" "}
                                      {selectedToken2
                                        ? numberWithCommas(
                                            parseFloat(
                                              selectedToken2.balance == null
                                                ? 0
                                                : selectedToken2.balance
                                            ).toFixed(4)
                                          )
                                        : "0.00"}
                                    </span>

                                    <button
                                      className="display_tokens_drop"
                                      onClick={() => OpenTokenModal2()}
                                    >
                                      {selectedToken2 ? (
                                        <div className="selectedTokenInfo">
                                          <img
                                            src={selectedToken2.img}
                                            alt=""
                                            className="display_tokens_drop_img"
                                          />
                                          <span className="selectedTokenSymbol">
                                            {selectedToken2.symbol2}
                                          </span>
                                        </div>
                                      ) : (
                                        "Select Currency"
                                      )}
                                      <ArrowDropDownIcon className="liquidity_select_div_btn1_icon" />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* </div> */}
                          </div>
                          <div className="swap_price_rate_div">
                            {priceLoading ? (
                              <ShimmerButton
                                size="sm"
                                className="custom_shimmer"
                              />
                            ) : (
                              <>
                                {" "}
                                <div className="swap_price_rate_div1">
                                  1
                                  {selectedToken1 == null ? (
                                    <ShimmerButton
                                      size="sm"
                                      className="custom_shimmer"
                                    />
                                  ) : (
                                    <> {selectedToken1.symbol}</>
                                  )}
                                </div>
                                <div className="swap_price_rate_div1">
                                  {selectedToken2 == null ? (
                                    <ShimmerButton
                                      size="sm"
                                      className="custom_shimmer"
                                    />
                                  ) : (
                                    <>
                                      {" "}
                                      {numberWithCommas(marketPrice)}{" "}
                                      {selectedToken2.symbol}
                                    </>
                                  )}
                                </div>
                              </>
                            )}
                          </div>

                          <SwapButton
                            SwapAmount={SwapAmount}
                            // swapDisable={true}
                            swapDisable={swapDisable}
                            selectedToken1={selectedToken1}
                            selectedToken2={selectedToken2}
                            onClick={process}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Bridge
                      OpenBridgeModal={OpenBridgeModal}
                      tokenBridgeModal={tokenBridgeModal}
                    />
                  )}
                </div>
              </div>
              {!user ? null : (
                <div className="DashboardWalletsDiv_area3">
                  <TablePagination
                    TableData={tableData}
                    contentLoading={contentLoadingTable}
                    dummyData={Staticdata.productsTableData.slice(0, 8)}
                    userName={user?.username}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        <div class="background_gradient-parent">
          <div class="background_gradient"></div>
        </div>
        <img
          src="/img/dark_home_sec_bg.svg"
          alt=""
          className="home_div_section1_bg"
        />
      </section>
      {tokenModal1 ? (
        <div className=" dark updatedTokenModal">
          <div
            className="updatedTokenModal_closeDiv"
            onClick={() => {
              setTokenModal1(!tokenModal1);
            }}
          ></div>
          <div className="updatedTokenModal_area">
            <div className="updatedTokenModal_area1">
              <div className="updatedTokenModal_area1_head">
                <span>Select a token </span>
                <CloseIcon
                  className="updatedTokenModal_area1_head_close_icon"
                  onClick={() => {
                    setTokenModal1(!tokenModal1);
                  }}
                />
              </div>
              <div className="updatedTokenModal_area1_para">
                You can search and select any token on EgoSwap
              </div>
              <div className="updatedTokenModal_area1_search">
                <input
                  type="search"
                  name=""
                  id=""
                  className="updatedTokenModal_area1_search_input"
                />
              </div>
            </div>
            <div className="updatedTokenModal_area_body">
              <div className="updatedTokenModal_area_body_area">
                {selectedToken1 == null ? (
                  <>
                    {" "}
                    {updatedTokenObject.map((token) => (
                      <button
                        id={token.id}
                        className="updatedTokenModal_area_body_area1"
                        onClick={() => closeTokenModal1(token)}
                      >
                        <div className="updatedTokenModal_area_body_area1_cont1">
                          <div className="updatedTokenModal_area_body_area1_cont1_div1">
                            <img
                              src={token.img}
                              alt=""
                              className="updatedTokenModal_area_body_area1_cont1_div1_img"
                            />
                          </div>
                          <div className="updatedTokenModal_area_body_area1_cont1_div2">
                            <div className="updatedTokenModal_area_body_area1_cont1_div2_cont1">
                              {token.symbol2}
                            </div>
                            <div className="updatedTokenModal_area_body_area1_cont1_div2_cont2">
                              {token.name}
                            </div>
                          </div>
                        </div>
                        <div className="updatedTokenModal_area_body_area1_cont2">
                          {numberWithCommas(
                            parseFloat(token.balance).toFixed(4)
                          )}
                        </div>
                      </button>
                    ))}
                  </>
                ) : (
                  <>
                    {updatedTokenObject.map((token) => (
                      <button
                        disabled={token.id === selectedToken1.id ? true : false}
                        id={token.id}
                        className="updatedTokenModal_area_body_area1"
                        onClick={() => closeTokenModal1(token)}
                      >
                        <div className="updatedTokenModal_area_body_area1_cont1">
                          <div className="updatedTokenModal_area_body_area1_cont1_div1">
                            <img
                              src={token.img}
                              alt=""
                              className="updatedTokenModal_area_body_area1_cont1_div1_img"
                            />
                          </div>
                          <div className="updatedTokenModal_area_body_area1_cont1_div2">
                            <div className="updatedTokenModal_area_body_area1_cont1_div2_cont1">
                              {token.symbol2}
                            </div>
                            <div className="updatedTokenModal_area_body_area1_cont1_div2_cont2">
                              {token.name}
                            </div>
                          </div>
                        </div>
                        <div className="updatedTokenModal_area_body_area1_cont2">
                          {numberWithCommas(
                            parseFloat(token.balance).toFixed(4)
                          )}
                        </div>
                      </button>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {tokenModal2 ? (
        <div className=" dark updatedTokenModal">
          <div
            className="updatedTokenModal_closeDiv"
            onClick={() => {
              setTokenModal2(!tokenModal2);
            }}
          ></div>
          <div className="updatedTokenModal_area">
            <div className="updatedTokenModal_area1">
              <div className="updatedTokenModal_area1_head">
                <span>Select a token </span>
                <CloseIcon
                  className="updatedTokenModal_area1_head_close_icon"
                  onClick={() => {
                    setTokenModal2(!tokenModal2);
                  }}
                />
              </div>
              <div className="updatedTokenModal_area1_para">
                You can search and select any token on egoswap
              </div>
              <div className="updatedTokenModal_area1_search">
                <input
                  type="search"
                  name=""
                  id=""
                  className="updatedTokenModal_area1_search_input"
                />
              </div>
            </div>
            <div className="updatedTokenModal_area_body">
              <div className="updatedTokenModal_area_body_area">
                {selectedToken2 == null ? (
                  <>
                    {" "}
                    {updatedTokenObject.map((token) => (
                      <button
                        id={token.id}
                        className="updatedTokenModal_area_body_area1"
                        onClick={() => closeTokenModal2(token)}
                      >
                        <div className="updatedTokenModal_area_body_area1_cont1">
                          <div className="updatedTokenModal_area_body_area1_cont1_div1">
                            <img
                              src={token.img}
                              alt=""
                              className="updatedTokenModal_area_body_area1_cont1_div1_img"
                            />
                          </div>
                          <div className="updatedTokenModal_area_body_area1_cont1_div2">
                            <div className="updatedTokenModal_area_body_area1_cont1_div2_cont1">
                              {token.symbol2}
                            </div>
                            <div className="updatedTokenModal_area_body_area1_cont1_div2_cont2">
                              {token.name}
                            </div>
                          </div>
                        </div>
                        <div className="updatedTokenModal_area_body_area1_cont2">
                          {numberWithCommas(
                            parseFloat(token.balance).toFixed(4)
                          )}
                        </div>
                      </button>
                    ))}
                  </>
                ) : (
                  <>
                    {updatedTokenObject.map((token) => (
                      <button
                        disabled={token.id == selectedToken2.id ? true : false}
                        id={token.id}
                        className="updatedTokenModal_area_body_area1"
                        onClick={() => closeTokenModal2(token)}
                      >
                        <div className="updatedTokenModal_area_body_area1_cont1">
                          <div className="updatedTokenModal_area_body_area1_cont1_div1">
                            <img
                              src={token.img}
                              alt=""
                              className="updatedTokenModal_area_body_area1_cont1_div1_img"
                            />
                          </div>
                          <div className="updatedTokenModal_area_body_area1_cont1_div2">
                            <div className="updatedTokenModal_area_body_area1_cont1_div2_cont1">
                              {token.symbol2}
                            </div>
                            <div className="updatedTokenModal_area_body_area1_cont1_div2_cont2">
                              {token.name}
                            </div>
                          </div>
                        </div>
                        <div className="updatedTokenModal_area_body_area1_cont2">
                          {numberWithCommas(
                            parseFloat(token.balance).toFixed(4)
                          )}
                        </div>
                      </button>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {pinModal2 ? (
        <WebPin
          isLoading={loading}
          btnFunc={TokenSwap}
          pinTitle="Enter Pin to validate Transaction"
          pinPara="Input your pin to complete this transaction."
          btnFuncTxt="Proceed"
          handleOnComplete={(e) => {
            const a = e.join("");
            setPin2(a);
            return;
          }}
          toggleWebpin={() => {
            setPinModal2(false);
          }}
        />
      ) : null}
      {successModal ? (
        <SuccessModal
          SuccesTxt={successTxt}
          successFunc={() => {
            window.location.href = "/wallet";
          }}
          txnHashDiv={false}
        />
      ) : null}
      {errorModal ? (
        <ErrorModal
          ErrorTxt={errorTxt}
          errorFunc={() => {
            setErrorModal(false);
          }}
        />
      ) : null}
      {pinModal && (
        <WebPin
          btnFunc={proceedToConfirm}
          btnFuncTxt={"Next"}
          handleOnComplete={handleOnComplete1}
          pinTitle={"Please Set a Pin"}
          pinPara={
            "You'll need to create a pin to be able to make transactions"
          }
          newUser={true}
        />
      )}
      {confirmPinModal && (
        <WebPin
          btnFunc={processPinRequest}
          isLoading={pinProcessing}
          btnFuncTxt={"Confirm"}
          handleOnComplete={handleOnComplete2}
          pinTitle={"Please Confirm Your Pin"}
          pinPara={"Just to be sure, we'll want you to confirm your pin "}
          newUser={true}
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default UpdatedSwap;
