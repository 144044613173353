import logo from "./logo.svg";
import "./App.css";
import RouteConfig from "./components/Cex/RouteConfig";
import "./stylesheet/darkMode.css";
import "./stylesheet/swapstyle2.css";
import "./stylesheet/tokenmodalstyle.css";
import "./stylesheet/StakingUpdate.css";

function App() {
  return (
    <div className="dark">
      <RouteConfig />
    </div>
  );
}

export default App;
