import axios from "axios";
import {
  FETCH_SWAP_PRICES,
  TOKEN_SWAP,
  TOKEN_FETCH_TICKERS,
  TOKEN_LIQUIDITY,
  INIT_BRIDGE_ROUTE,
  FETCH_BRIDGE_TRANSACTION,
  SUBMIT_SENT_FUNDS,
  ABORT_TRANSACTION,
  REQUERY_TRANSACTION,
} from "../core/ApiRoutes";
import setAuthToken from "../utils/setAuthToken";
export const GET_SWAP_PRICE = async ({ tokenA = "", tokenB = "" }) => {
  // setAuthToken(localStorage.getItem("x-token"));
  try {
    const res = await axios.get(`${FETCH_SWAP_PRICES}/${tokenA}/${tokenB}`);
    return res.data;
  } catch (error) {
    return error.response || error.message;
  }
};

export const SWAP = async (payload) => {
  //console.logog(payload);
  setAuthToken(localStorage.getItem("x-token"));
  try {
    const res = await axios.post(`${TOKEN_SWAP}`, payload);
    //console.logog(res.data.message);
    return res.data;
  } catch (error) {
    //console.logog(error.response || error.response.data.message);
    return error.response || error.message;
  }
};
export const FETCH_SWAP = async () => {
  //console.logog(payload);
  setAuthToken(localStorage.getItem("x-token"));
  try {
    const res = await axios.get(`${TOKEN_SWAP}`);
    //console.logog(res.data.message);
    return res.data;
  } catch (error) {
    //console.logog(error.response || error.response.data.message);
    return error.response || error.message;
  }
};
export const FETCH_TICKERS = async () => {
  //console.logog(payload);
  setAuthToken(localStorage.getItem("x-token"));
  try {
    const res = await axios.get(`${TOKEN_FETCH_TICKERS}`);
    //console.logog(res.data.message);
    return res.data;
  } catch (error) {
    //console.logog(error.response || error.response.data.message);
    return error.response || error.message;
  }
};
export const FETCH_BRIDGE_TX = async (tx) => {
  //console.logog(payload);
  setAuthToken(localStorage.getItem("x-token"));
  try {
    const res = await axios.get(`${FETCH_BRIDGE_TRANSACTION}/${tx}`);
    //console.logog(res.data.message);
    return res.data;
  } catch (error) {
    //console.logog(error.response || error.response.data.message);
    return error.response || error.message;
  }
};
export const SENT_FUNDS = async (tx) => {
  //console.logog(payload);
  setAuthToken(localStorage.getItem("x-token"));
  try {
    const res = await axios.get(`${SUBMIT_SENT_FUNDS}/${tx}`);
    //console.logog(res.data.message);
    return res.data;
  } catch (error) {
    //console.logog(error.response || error.response.data.message);
    return error.response || error.message;
  }
};
export const ABORT = async (tx) => {
  //console.logog(payload);
  setAuthToken(localStorage.getItem("x-token"));
  try {
    const res = await axios.get(`${ABORT_TRANSACTION}/${tx}`);
    //console.logog(res.data.message);
    return res.data;
  } catch (error) {
    //console.logog(error.response || error.response.data.message);
    return error.response || error.message;
  }
};
export const REQUERY_TRANSAC = async (tx) => {
  //console.logog(payload);
  setAuthToken(localStorage.getItem("x-token"));
  try {
    const res = await axios.get(`${REQUERY_TRANSACTION}/${tx}`);
    //console.logog(res.data.message);
    return res.data;
  } catch (error) {
    //console.logog(error.response || error.response.data.message);
    return error.response || error.message;
  }
};
export const LIQUIDITY = async (payload) => {
  //console.logog(payload);
  setAuthToken(localStorage.getItem("x-token"));
  try {
    const res = await axios.post(`${TOKEN_LIQUIDITY}`, payload);
    //console.logog(res.data.message);
    return res.data;
  } catch (error) {
    //console.logog(error.response || error.response.data.message);
    return error.response || error.message;
  }
};
export const INITIALIZE_BRIDGE_ROUTE = async (payload) => {
  console.log("====================================");
  console.log(payload);
  console.log("====================================");
  try {
    const res = await axios.post(`${INIT_BRIDGE_ROUTE}`, payload);
    //console.logog(res.data.message);
    return res.data;
  } catch (error) {
    //console.logog(error.response || error.response.data.message);
    return error.response || error.message;
  }
};
