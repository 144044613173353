import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { routeHelper } from "../../../../helper";
import useUserEligible from "../../hooks/useUserEligible";

const SwapButton = ({
  selectedToken1,
  selectedToken2,
  swapDisable,
  SwapAmount,
  onClick,
}) => {
  // const userEligible = useUserEligible();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  //check if the user is logged in

  if (user === null || user === undefined || !user) {
    return (
      <button
        id="generate"
        class="updatedSwapSwapBtn"
        onClick={() => {
          localStorage.setItem("RedirectRoute", window.location.href);
          navigate("/login");
        }}
      >
        Login/SignUp
      </button>
    );
  }

  if (selectedToken1.symbol === "EGAX" && selectedToken2.symbol === "USD") {
    return (
      <button class="updatedSwapSwapBtn" disabled>
        Swap
      </button>
    );
  }

  // if (!userEligible) {
  //   return (
  //     <button
  //       id="generate"
  //       class="updatedSwapSwapBtn"
  //       onClick={() => {
  //         navigate("/kyc");
  //       }}
  //     >
  //       Upgrade Account
  //     </button>
  //   );
  // }

  return (
    <div>
      {" "}
      <button
        id="generate"
        class="updatedSwapSwapBtn"
        disabled={swapDisable}
        onClick={onClick}
      >
        {selectedToken1 && selectedToken2 ? (
          <>
            {" "}
            {parseFloat(SwapAmount) > selectedToken1.balance
              ? "Insufficient Balance"
              : parseFloat(SwapAmount) < 0 || SwapAmount == ""
              ? "Enter an amount"
              : "Swap"}
          </>
        ) : (
          "Select a token"
        )}
      </button>
    </div>
  );
};

export default SwapButton;
