import React, { useEffect, useState } from "react";
import "../../assets/stylesheet/signupLogin.css";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/plain.css";
import Staticdata from "../../assets/json/Static";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useDispatch, useSelector } from "react-redux";
import ScaleLoader from "react-spinners/ScaleLoader";
import SuccessModal from "../../components/SuccessModal/SuccessModal";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import { RESEND_SMS_OTP } from "../../services/auth";
import { registerUser } from "../../features/auth/authActions";
import { setPayload } from "../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { VERIFY_OTP } from "../../services/auth";
import OtpModal from "../../components/OtpModal/OtpModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// dummySelectData;
const Signup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { payload, loading, error } = useSelector((state) => state.auth);
  const [defaultForm, setDefaultForm] = useState(true);
  const [submitDisable, setSubmitDisable] = useState(true);
  const [otpDisable, setOtpDisable] = useState(true);
  const [otpLoading, setOtpLoading] = useState(false);
  const [nextDisable, setNextDisable] = useState(true);
  const [errorModal, setErrorModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successTxt, setSuccessTxt] = useState("");
  const [errorTxt, setErrorTxt] = useState("");
  const [otpModal, setOtpModal] = useState(false);
  const [otp, setOtp] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordVisible2, setPasswordVisible2] = useState(false);
  const [mismatched, setMismatched] = useState(false);
  useEffect(() => {
    if (otp == "") {
      setOtpDisable(true);
    } else {
      setOtpDisable(false);
    }
  }, [otp]);

  useEffect(() => {
    if (
      payload.email === "" ||
      payload.username === "" ||
      payload.password === "" ||
      payload.confirm != payload.password
    ) {
      setSubmitDisable(true);
    } else {
      setSubmitDisable(false);
    }
  }, [payload.email, payload.username, payload.password, payload.confirm]);
  useEffect(() => {
    if (payload.confirm != payload.password) {
      setMismatched(true);
    } else {
      setMismatched(false);
    }
  }, [payload.password, payload.confirm]);
  useEffect(() => {
    // console.log("====================================");
    // console.log(payload.confirm);
    // console.log("====================================");
    if (payload.confirm === undefined || payload.confirm === "") {
      setMismatched(false);
      return;
    }
  }, [payload.confirm]);

  const ToggleDefaultForm = () => {
    setDefaultForm(!defaultForm);
  };

  const handleOnChange = (e) => {
    //// // console.logog(e);
    const { id, value } = e.target;
    dispatch(setPayload({ ...payload, [id]: value }));

    // payload.confirm != payload.password ? return <div className="password_mismatch_div">Passowrd does not match</div>
    // : null
  };

  const handleSignUp = async () => {
    setSubmitDisable(true);
    // const { email, password, username, phone } = payload;
    //// // console.logog(payload);

    const res = await dispatch(registerUser(payload));
    // setPayload(newPayload);
    if (res.payload.code === 200) {
      setSubmitDisable(false);
      setSuccess(true);
      setSuccessTxt("You have successfully signed up");
      return;
    }

    if (res.payload?.data?.success === false) {
      setSubmitDisable(false);
      setErrorModal(true);
      setErrorTxt(res.payload?.data?.errorMessage);
      // return alert(res.payload?.data?.errorMessage);
    }

    //// // console.logog("Failed");
  };
  const handleSignUpResend = async () => {
    const res = await RESEND_SMS_OTP({
      email: payload.email,
    });
    if (res.payload.success === false) {
      setSubmitDisable(false);
      setErrorModal(true);
      setErrorTxt(res.payload?.data?.errorMessage);
    }
  };

  if (error) {
    return <p>{error}</p>;
  }

  const handleChange = (enteredOtp) => {
    setOtp(enteredOtp);
  };
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const togglePasswordVisibility2 = () => {
    setPasswordVisible2(!passwordVisible2);
  };
  return (
    <div className="signup_div">
      <section
        className="signup_div_section"
        style={{ backgroundImage: "url(/img/signup_bg.png)" }}
      >
        <div className="custom_container">
          <div className="signup_div_section_div">
            <div className="signup_div_section_div_title">
              Get started with Egoswap
            </div>

            {defaultForm ? (
              <div className="signup_div_section_div_container_form_cont">
                <div className="signup_div_section_div_container_form">
                  <label
                    htmlFor="email"
                    className="signup_div_section_div_container_form_label"
                  >
                    *Email Address:
                  </label>
                  <input
                    type="email"
                    value={payload.email}
                    id="email"
                    name="email"
                    className="signup_div_section_div_container_form_input"
                    onChange={handleOnChange}
                    // autoComplete="off"
                  />

                  {/* ============ */}
                  {/* ============ */}
                  {/* ============ */}
                  {/* ============ */}
                  {/* ============ */}
                  <label
                    htmlFor="username"
                    className="signup_div_section_div_container_form_label"
                    style={{ display: "none" }}
                  >
                    *User Name:
                  </label>
                  <input
                    id="dyey"
                    type="text"
                    value
                    name="userName"
                    className="signup_div_section_div_container_form_input"
                    onChange={handleOnChange}
                    // autoComplete="on"
                    style={{ display: "none" }}
                  />
                  {/* ============ */}
                  {/* ============ */}
                  {/* ============ */}
                  {/* ============ */}
                  {/* ============ */}
                  <label
                    htmlFor="password"
                    className="signup_div_section_div_container_form_label"
                  >
                    *Password:
                  </label>

                  <div className="password_div">
                    <input
                      type={passwordVisible ? "text" : "password"}
                      id="password"
                      value={payload.password}
                      name="password"
                      className="signup_div_section_div_container_form_input_pasowrd"
                      onChange={handleOnChange}
                      autoComplete="off"
                    />
                    {passwordVisible ? (
                      <VisibilityOffIcon
                        onClick={togglePasswordVisibility}
                        className="otp_modal_container_body_icon2"
                      />
                    ) : (
                      <VisibilityIcon
                        onClick={togglePasswordVisibility}
                        className="otp_modal_container_body_icon2"
                      />
                    )}
                  </div>
                  {/* ============ */}
                  {/* ============ */}
                  {/* ============ */}
                  {/* ============ */}
                  {/* ============ */}
                  <label
                    htmlFor="password"
                    className="signup_div_section_div_container_form_label"
                  >
                    *Confirm Password:
                  </label>

                  <div className="password_div">
                    <input
                      type={passwordVisible2 ? "text" : "password"}
                      id="confirm"
                      value={payload.confirm}
                      name="confirm"
                      className="signup_div_section_div_container_form_input_pasowrd"
                      onChange={handleOnChange}
                      autoComplete="off"
                    />
                    {mismatched ? (
                      <div className="password_mismatch_div">
                        Passowrd does not match!
                      </div>
                    ) : null}
                  </div>
                  {/* ============ */}
                  {/* ============ */}
                  {/* ============ */}
                  {/* ============ */}
                  {/* ============ */}
                  <label
                    htmlFor="username"
                    className="signup_div_section_div_container_form_label"
                  >
                    *User Name:
                  </label>
                  <input
                    id="username"
                    type="text"
                    value={payload.username}
                    name="userName"
                    className="signup_div_section_div_container_form_input"
                    onChange={handleOnChange}
                    autoComplete="off"
                  />
                  {/* ============ */}
                  {/* ============ */}
                  {/* ============ */}
                  {/* ============ */}
                  {/* ============ */}
                  <label
                    htmlFor="referral"
                    className="signup_div_section_div_container_form_label"
                  >
                    Referral Code:
                  </label>
                  <input
                    id="referral"
                    type="text"
                    value={payload.referral}
                    name="userName"
                    className="signup_div_section_div_container_form_input"
                    onChange={handleOnChange}
                    autoComplete="off"
                    style={{ marginBottom: "5px" }}
                  />
                  <span
                    style={{
                      fontSize: "10px",
                      color: "gray",
                      marginBottom: "10px",
                    }}
                  >
                    * Optional
                  </span>
                  {/* ============ */}
                  {/* ============ */}
                  {/* ============ */}
                  {/* ============ */}
                  {/* ============ */}
                  <button
                    className="signup_div_section_div_container_form_btn"
                    onClick={handleSignUp}
                    disabled={submitDisable}
                  >
                    {loading ? (
                      <>
                        <ScaleLoader color="#446f59" height={20} />
                      </>
                    ) : (
                      " Create account"
                    )}
                  </button>
                </div>
              </div>
            ) : null}

            <div className="signup_div_section_div_para">
              Already have an acccount?{"   "}
              <a href="/login" className="signup_div_section_div_para_link">
                Login
              </a>
            </div>
          </div>
        </div>
        <div class="background_gradient-parent">
          <div class="background_gradient"></div>
        </div>
        <img
          src="/img/dark_home_sec_bg.svg"
          alt=""
          className="home_div_section1_bg"
        />
      </section>
      {/* {otpModal ? (
        <OtpModal
          handleChange={handleChange}
          otp={otp}
          handleVerifyOtp={handleVerifyOtp}
          payload={payload}
          otpDisable={otpDisable}
          otpLoading={otpLoading}
          resendOtp={handleSignUpResend}
        />
      ) : null} */}
      {success ? (
        <SuccessModal
          SuccesTxt={successTxt}
          successFunc={() => {
            window.location.href = "/login";
          }}
          txnHashDiv={false}
        />
      ) : null}
      {errorModal ? (
        <ErrorModal
          ErrorTxt={errorTxt}
          errorFunc={() => {
            setErrorModal(false);
          }}
        />
      ) : null}
    </div>
  );
};

export default Signup;
