import React, { useState, useEffect, useRef } from "react";
import "../../stylesheet/header.css";
import "./header.css";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import NightsStayIcon from "@mui/icons-material/NightsStay";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SettingsIcon from "@mui/icons-material/Settings";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { routeHelper } from "../../helper";
import { useSelector } from "react-redux";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
// import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
// import SettingsIcon from "@mui/icons-material/Settings";

const Header = ({ darkMode, togglemakeDark }) => {
  const { user } = useSelector((state) => state.auth);
  const [lightMode, setLightMode] = useState(true);
  const [tradeDrop, setTradeDrop] = useState(false);
  const [headerMenu, setHeaderMenu] = useState(false);
  const [settingsMenu, setSettingsMenu] = useState(false);
  const divRef = useRef(null);

  useEffect(() => {
    // Function to handle click events
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setSettingsMenu(false);
      }
    };

    // Add the event listener
    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const ToggleLightMode = () => {
    setLightMode(!lightMode);
  };
  const ToggleTradeDrop = () => {
    setTradeDrop(!tradeDrop);
  };
  const ToggleHeaderMenu = () => {
    setHeaderMenu(!headerMenu);
  };
  // console.log(user);

  const ToggleSettingsMenu = () => {
    setSettingsMenu(!settingsMenu);
  };
  return (
    <div className="header_div">
      <div className="container">
        <div className="header_div_area">
          <a href="/" className="header_div_area_cont1">
            <img
              src="/img/egax_logo.png"
              alt=""
              className="header_div_area_cont1_img"
            />
            Egoswap
          </a>

          <div className="header_div_area_cont3_div3">
            {user === null || user === undefined || !user ? null : (
              <div className="header_div_area_cont2">
                <a href="/wallet" className="header_div_area_cont2_link1">
                  Wallet
                </a>
                <a href="/earn" className="header_div_area_cont2_link1">
                  Earn
                </a>
              </div>
            )}

            {user === null || user === undefined || !user ? (
              <a href="/login">
                {" "}
                <button className="header_div_area_cont3_div3_btn">
                  Login/Signup
                </button>
              </a>
            ) : (
              <div className="header_div_area_cont3_div3_area">
                <div className="setings_div" ref={divRef}>
                  <SettingsIcon
                    className="settings_header_icon"
                    onClick={ToggleSettingsMenu}
                  />
                  {settingsMenu ? (
                    <div className="setings_div_cont">
                      <a href="/reset/pin" className="setings_div_cont_1">
                        Reset Pin{" "}
                        <KeyboardArrowRightIcon className="setings_div_cont_1_icon" />
                      </a>
                      <div className="header_div_area_cont3_div3_area_chain_wallet_bal_div">
                        <div className="header_div_area_cont3_div3_area_chain_wallet_bal_div_bal">
                          <AccountCircleIcon className="header_div_area_cont3_div3_area_chain_wallet_bal_div_bal_icon" />
                          <div className="header_div_area_cont3_div3_area_chain_wallet_bal_div_bal_amnt">
                            {user.username}
                          </div>
                        </div>
                        {/* <div className="header_div_area_cont3_div3_area_chain_wallet_bal_div_address">
                    {`${"23456".slice(0, 4)}...${"97899976".slice(5, 7)}`}
                  </div> */}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div
                  className="header_div_area_cont3_div2"
                  onClick={() => {
                    localStorage.removeItem("x-token");
                    window.location.href = "/login";
                  }}
                >
                  <PowerSettingsNewIcon
                    className="header_div_area_cont3_div2_icon"
                    style={{ color: "red" }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {headerMenu ? (
        <div className="headerMenuDiv">
          <div className="headerMenuDiv_cont">
            <CloseIcon
              className="header_div_area_cont3_icon2"
              onClick={ToggleHeaderMenu}
            />
            <a href="/" className="headerMenuDiv_cont_1">
              {" "}
              <span className="headerMenuDiv_cont_1_txt">Centralized Ex </span>
              <span className="headerMenuDiv_cont_1_icon">
                {" "}
                <KeyboardArrowRightIcon className="headerMenuDiv_cont_1_icon_icon" />{" "}
              </span>
            </a>
            <a
              href={routeHelper({ sub_domain: "dex", route: "" })}
              className="headerMenuDiv_cont_1"
            >
              {" "}
              <span className="headerMenuDiv_cont_1_txt">
                De-Centralized Ex{" "}
              </span>
              <span className="headerMenuDiv_cont_1_icon">
                {" "}
                <KeyboardArrowRightIcon className="headerMenuDiv_cont_1_icon_icon" />{" "}
              </span>
            </a>
            <a href="/wallet" className="headerMenuDiv_cont_1">
              {" "}
              <span className="headerMenuDiv_cont_1_txt">Wallet</span>
              <span className="headerMenuDiv_cont_1_icon">
                {" "}
                <KeyboardArrowRightIcon className="headerMenuDiv_cont_1_icon_icon" />{" "}
              </span>
            </a>
            <div className="headerMenuDiv_cont_1">
              {" "}
              <span className="headerMenuDiv_cont_1_txt">User Info</span>
              <span className="headerMenuDiv_cont_1_icon_btn">
                {user === null || user === undefined || !user ? (
                  <div className="header_div_area_cont3_div3_area">
                    <div className="header_div_area_cont3_div3_area_chain_wallet_bal_div">
                      <div className="header_div_area_cont3_div3_area_chain_wallet_bal_div_bal">
                        <AccountCircleIcon className="header_div_area_cont3_div3_area_chain_wallet_bal_div_bal_icon" />
                        <div className="header_div_area_cont3_div3_area_chain_wallet_bal_div_bal_amnt">
                          @...
                        </div>
                      </div>
                      <div className="header_div_area_cont3_div3_area_chain_wallet_bal_div_address">
                        000...
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="header_div_area_cont3_div3_area">
                    <div className="header_div_area_cont3_div3_area_chain_wallet_bal_div">
                      <div className="header_div_area_cont3_div3_area_chain_wallet_bal_div_bal">
                        <AccountCircleIcon className="header_div_area_cont3_div3_area_chain_wallet_bal_div_bal_icon" />
                        <div className="header_div_area_cont3_div3_area_chain_wallet_bal_div_bal_amnt">
                          {user.username}
                        </div>
                      </div>
                      <div className="header_div_area_cont3_div3_area_chain_wallet_bal_div_address">
                        {`${"23456".slice(0, 4)}...${"97899976".slice(5, 7)}`}
                      </div>
                    </div>
                  </div>
                )}
              </span>
            </div>
            <div className="headerMenuDiv_cont_1">
              {" "}
              <span className="headerMenuDiv_cont_1_txt">Logout</span>
              <span className="headerMenuDiv_cont_1_icon_btn">
                {user === null || user === undefined || !user ? null : (
                  <div
                    className="header_div_area_cont3_div2_mobile"
                    onClick={() => {
                      localStorage.removeItem("x-token");
                      window.location.href = "/login";
                    }}
                  >
                    <PowerSettingsNewIcon
                      className="header_div_area_cont3_div2_icon"
                      style={{ color: "red" }}
                    />
                  </div>
                )}
              </span>
            </div>
            <div className="headerMenuDiv_cont_1">
              {" "}
              <span className="headerMenuDiv_cont_1_txt">UI-mode</span>
              <span className="headerMenuDiv_cont_1_icon_btn">
                {darkMode ? (
                  <Brightness7Icon
                    className="header_div_area_cont3_div2_icon"
                    onClick={togglemakeDark}
                  />
                ) : (
                  <NightsStayIcon
                    className="header_div_area_cont3_div2_icon"
                    onClick={togglemakeDark}
                  />
                )}
              </span>
            </div>
            <div className="headerMenuDiv_cont_1">
              {" "}
              <span className="headerMenuDiv_cont_1_txt">Language</span>
              <span className="headerMenuDiv_cont_1_icon_btn">
                <img
                  src="/img/language_select_img1.svg"
                  alt=""
                  className="header_div_area_cont3_img"
                />
              </span>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Header;
