import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import JoinFullIcon from "@mui/icons-material/JoinFull";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import { useChannel } from "ably/react";
import SuccessModal from "../../../components/SuccessModal/SuccessModal";
import { Line, Circle } from "rc-progress";
import CheckIcon from "@mui/icons-material/Check";
import { REQUERY_TRANSAC } from "../../../services/swap_services";

const BridgeExecution = ({ tokenIn, tokenOut, networkIn, networkOut }) => {
  const [successModal, setSuccessModal] = useState(false);
  const [successTxt, setSuccessTxt] = useState("");

  const [lifecycle, setLifecycle] = useState("confirm-deposit");

  const [chainExplorer, setChainExplorer] = useState("");
  const [hash, setHash] = useState("");
  const [progressColor, setProgressColor] = useState("#22ad62");
  const [progressPercent, setProgressPercent] = useState(0);

  useEffect(() => {
    if (localStorage.getItem("lifecycle") === null) {
      localStorage.setItem("lifecycle", "confirm-deposit");
      return;
    }

    setLifecycle(localStorage.getItem("lifecycle"));
  }, []);

  const { channel } = useChannel("bridge-events", (message) => {
    // console.log(message, "ara");

    if (message.name === `${localStorage.getItem("txid")}/deposit/success`) {
      setLifecycle("bridging");
      localStorage.setItem("lifecycle", "bridging");

      return;
    }

    if (
      message.name === `${localStorage.getItem("txid")}/withdrawal/completed`
    ) {
      setLifecycle("sending-funds");
      localStorage.setItem("lifecycle", "sending-funds");

      localStorage.removeItem("txid");
      localStorage.removeItem("BridgeStep");
      localStorage.removeItem("lifecycle");
      setChainExplorer(message.data.explorer + "/" + message.data.hash);
      setHash(message.data.hash);

      //   const meta = JSON.parse(message.data.meta);
      //   if (meta.hash) {
      //     setChainExplorer("https://bscscan.com/tx/");
      //   }
      //   if (meta.transactionHash) {
      //     setChainExplorer("https://egoscan.io/tx/");
      //   }

      setTimeout(() => {
        setSuccessTxt(
          `You have successfully bridged your ${tokenIn}(${networkIn}) to ${tokenOut}(${networkOut}). `
        );
        setSuccessModal(true);
      }, 4000);
      return;
    }
  });

  const requeryTransaction = async () => {
    // if (localStorage.getItem("txid") !== null) {
    const response = await REQUERY_TRANSAC(localStorage.getItem("txid"));
    // console.log("====================================");
    // console.log(response);
    // console.log("====================================");
    //   return;
    // }
  };

  useEffect(() => {
    setInterval(requeryTransaction, 60000);
  }, []);

  useEffect(() => {
    // console.log("====================================");
    // console.log(lifecycle);
    // console.log("====================================");
  }, [lifecycle]);

  return (
    <div className="TokenBridgeModal_cont_2">
      <div className="TokenBridgeModal_cont_2_checkout_div1">
        <div className="TokenBridgeModal_cont_2_checkout_div1_title2">
          <div className="TokenBridgeModal_cont_2_checkout_div1_title2_conts">
            Bridging
            <div className="TokenBridgeModal_cont_2_checkout_div1_title2_range">
              <Line
                strokeWidth={0.5}
                percent={
                  lifecycle === "confirm-deposit"
                    ? 33.33
                    : lifecycle === "bridging"
                    ? 66.66
                    : lifecycle === "sending-funds"
                    ? 100
                    : 0
                }
                strokeColor={progressColor}
              />
            </div>
          </div>
        </div>

        <div className="bridge_exchange_body">
          <div className="bridge_exchange_body1">
            <div className="bridge_exchange_body1_body_icon">
              {lifecycle === "confirm-deposit" ? (
                <ClipLoader color="#27bf75" size={25} />
              ) : lifecycle === "bridging" || lifecycle === "sending-funds" ? (
                <CheckIcon className="TokenBridgeModal_cont_1_body_cont1_icon_div_icon_checked" />
              ) : (
                <ThumbUpAltIcon className="bridge_exchange_body1_body_icon_icon" />
              )}
            </div>
            <div className="bridge_exchange_body1_body">
              <div className="bridge_exchange_body1_body_title">
                Confirmation in progress
              </div>
              <div className="bridge_exchange_body1_body_para">
                Once {tokenIn}({networkIn}) is confirmed in the blockchain,
                we'll start bridging it to {tokenOut}({networkOut})
              </div>
            </div>
          </div>
          {/* ======== */}
          {/* ======== */}
          {/* ======== */}
          <div className="bridge_exchange_body1">
            <div className="bridge_exchange_body1_body_icon">
              {lifecycle === "bridging" ? (
                <ClipLoader color="#27bf75" size={25} />
              ) : lifecycle === "sending-funds" ? (
                <CheckIcon className="TokenBridgeModal_cont_1_body_cont1_icon_div_icon_checked" />
              ) : (
                <JoinFullIcon className="bridge_exchange_body1_body_icon_icon" />
              )}
            </div>

            <div className="bridge_exchange_body1_body">
              <div className="bridge_exchange_body1_body_title">
                Bridging {tokenIn} ({networkIn}) to {tokenOut} ({networkOut})
              </div>
              <div className="bridge_exchange_body1_body_para">
                The process will take a few minutes please wait
              </div>
            </div>
          </div>
          {/* ======== */}
          {/* ======== */}
          {/* ======== */}
          <div className="bridge_exchange_body1">
            <div className="bridge_exchange_body1_body_icon">
              {lifecycle === "sending-funds" ? (
                <ClipLoader color="#27bf75" size={25} />
              ) : successModal === true ? (
                <CheckIcon className="TokenBridgeModal_cont_1_body_cont1_icon_div_icon_checked" />
              ) : (
                <SystemUpdateAltIcon className="bridge_exchange_body1_body_icon_icon" />
              )}
            </div>
            <div className="bridge_exchange_body1_body">
              <div className="bridge_exchange_body1_body_title">
                Sending funds to your wallet
              </div>
              <div className="bridge_exchange_body1_body_para">
                Once the transaction is completed, your {tokenIn} ({networkIn})
                will be sent to your crypto wallet. Typically, it takes a few
                minutes for your funds to show up.
              </div>
            </div>
          </div>
        </div>
      </div>
      {successModal ? (
        <SuccessModal
          SuccesTxt={successTxt}
          successFunc={() => {
            window.location.href = "/";
          }}
          TxnHash={hash}
          chainExplorer={chainExplorer}
          // txnHashDiv={false}
        />
      ) : null}
    </div>
  );
};

export default BridgeExecution;
