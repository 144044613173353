const Staticdata = {
  egr_models_carous: [
    {
      id: 0,
      name: "EGORAS DUAL FUEL TRICYCLE",
      prodName: "EGORAS DUAL FUEL TRICYCLE",
      prodId: "e0887a46-84a2-4652-a764-77d263e04c64",
      img: "/img/egoras_gen_images/egoras_gen3.png",
      model: "EGC-80",
      start_price: "1400000",
      specTitle1: "DUAL FUEL CAPABILITY",
      specPara1: "LPG & PETROL",
      specTitle2: "CHASSIS",
      specPara2: "TYPE SEMI MONOCOQUE",
      specTitle3: "BRAKES",
      specPara3: "FRONT AND REAR HYDRAULIC",
    },
    {
      id: 1,
      name: "EGORAS 3KVA DUAL FUEL MANUAL GENERATOR ",
      prodName: "EGORAS  3KVA DUAL FUEL MANUAL GENERATOR (EGR-3000)",
      prodId: "55e36ba9-aa54-41ff-bb3b-6490604417c2",
      img: "/img/egr_gen1.png",
      model: "EGR 3000 M",
      start_price: "180000",
      specTitle1: "VOLTAGE",
      specPara1: "220V",
      specTitle2: "RUNNING CAPACITY",
      specPara2: "3KVA",
      specTitle3: "TYPE",
      specPara3: "MANUAL",
    },
    {
      id: 2,
      name: "EGORAS 3KVA DUAL FUEL AUTOMATIC GENERATOR",
      prodName: "EGORAS 3KVA DUAL FUEL AUTOMATIC GENERATOR (EGR-3000)",
      prodId: "c97bce4f-8fa8-4a72-958f-eaac514c3fbd",
      img: "/img/egoras_gen_images/egoras_gen2.png",
      model: "EGR 3000 A",
      start_price: "210000",
      specTitle1: "VOLTAGE",
      specPara1: "220V",
      specTitle2: "RUNNING CAPACITY",
      specPara2: "3KVA",
      specTitle3: "TYPE",
      specPara3: "AUTOMATIC",
    },
    {
      id: 3,
      name: "EGORAS DUAL FUEL 8KVA SOUNDPROOF GENERATOR",
      prodName: "EGORAS DUAL FUEL 8KVA SOUNDPROOF GENERATOR",
      prodId: "1addea5a-6a6f-44b0-a3c8-293db32288f9",
      img: "/img/egoras_gen_images/egoras_gen4.png",
      model: "EGR 8000 A",
      start_price: "600000",
      specTitle1: "CAPACITY",
      specPara1: "8KVA",
      specTitle2: "VOLTAGE",
      specPara2: "220V",
      specTitle3: "TYPE",
      specPara3: "SOUNDPROOF",
    },
    {
      id: 4,
      name: "EGORAS 3KVA AUTOGAS AUTOMATIC GENERATOR",
      prodName: "EGORAS 3KVA AUTOGAS AUTOMATIC  GENERATOR(EGR-3000G)",
      prodId: "15f46250-ff88-46a0-b641-70ecafba4fff",
      img: "/img/egoras_gen_images/egoras_gen5.png",
      model: "EGR-3000G",
      start_price: "200000",
      specTitle1: "CAPACITY",
      specPara1: "3KVA",
      specTitle2: "VOLTAGE",
      specPara2: "220V",
      specTitle3: "TYPE",
      specPara3: "AUTOGAS",
    },
  ],
  egr_models_carous2: [
    {
      id: "0",
      name: "EGORAS DUAL FUEL TRICYCLE",
      prodName: "EGORAS DUAL FUEL TRICYCLE",
      prodId: "e0887a46-84a2-4652-a764-77d263e04c64",
      img: "/img/egoras_gen_images/egoras_gen3.png",
      model: "EGC-80",
      start_price: "1400000",
      specTitle1: "DUAL FUEL CAPABILITY",
      specPara1: "LPG & PETROL",
      specTitle2: "CHASSIS",
      specPara2: "TYPE SEMI MONOCOQUE",
      specTitle3: "BRAKES",
      specPara3: "FRONT AND REAR HYDRAULIC",
    },
    {
      id: "1",
      name: "EGORAS 3KVA DUAL FUEL MANUAL GENERATOR ",
      prodName: "EGORAS  3KVA DUAL FUEL MANUAL GENERATOR (EGR-3000)",
      prodId: "55e36ba9-aa54-41ff-bb3b-6490604417c2",
      img: "/img/egr_gen1.png",
      model: "EGR 3000 M",
      start_price: "165000",
      specTitle1: "VOLTAGE",
      specPara1: "220V",
      specTitle2: "RUNNING CAPACITY",
      specPara2: "3KVA",
      specTitle3: "TYPE",
      specPara3: "MANUAL",
    },
    {
      id: "2",
      name: "EGORAS 3KVA DUAL FUEL AUTOMATIC GENERATOR",
      prodName: "EGORAS 3KVA DUAL FUEL AUTOMATIC GENERATOR (EGR-3000)",
      prodId: "c97bce4f-8fa8-4a72-958f-eaac514c3fbd",
      img: "/img/egoras_gen_images/egoras_gen2.png",
      model: "EGR 3000 A",
      start_price: "190000",
      specTitle1: "VOLTAGE",
      specPara1: "220V",
      specTitle2: "RUNNING CAPACITY",
      specPara2: "3KVA",
      specTitle3: "TYPE",
      specPara3: "AUTOMATIC",
    },
    {
      id: "3",
      name: "EGORAS DUAL FUEL 8KVA SOUNDPROOF GENERATOR",
      prodName: "EGORAS DUAL FUEL 8KVA SOUNDPROOF GENERATOR",
      prodId: "1addea5a-6a6f-44b0-a3c8-293db32288f9",
      img: "/img/egoras_gen_images/egoras_gen4.png",
      model: "EGR 8000 A",
      start_price: "500000",
      specTitle1: "CAPACITY",
      specPara1: "8KVA",
      specTitle2: "VOLTAGE",
      specPara2: "220V",
      specTitle3: "TYPE",
      specPara3: "SOUNDPROOF",
    },
    {
      id: "4",
      name: "EGORAS 3KVA AUTOGAS AUTOMATIC GENERATOR",
      prodName: "EGORAS 3KVA AUTOGAS AUTOMATIC  GENERATOR(EGR-3000G)",
      prodId: "15f46250-ff88-46a0-b641-70ecafba4fff",
      img: "/img/egoras_gen_images/egoras_gen5.png",
      model: "EGR-3000G",
      start_price: "155000",
      specTitle1: "CAPACITY",
      specPara1: "3KVA",
      specTitle2: "VOLTAGE",
      specPara2: "220V",
      specTitle3: "TYPE",
      specPara3: "AUTOGAS",
    },
  ],
  options: [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ],
  options2: [
    { value: "0", label: "River state" },
    { value: "1", label: "Bayelsa state" },
    { value: "2", label: "Lagos state" },
    { value: "3", label: "Edo state" },
  ],
  productsTableData: [
    {
      prodName: "Egoras Dual Fuel",
      amount: 200000,
      prodBrand: "Egoras",
      Date: "21 MAY 2021",
      status: "Ordered",
    },
    {
      prodName: "Egoras Dual Fuel",
      amount: 200000,
      prodBrand: "Egoras",
      Date: "21 MAY 2021",
      status: "Ordered",
    },
    {
      prodName: "Egoras Dual Fuel",
      amount: 200000,
      prodBrand: "Egoras",
      Date: "21 MAY 2021",
      status: "Ordered",
    },
    {
      prodName: "Egoras Dual Fuel",
      amount: 200000,
      prodBrand: "Egoras",
      Date: "21 MAY 2021",
      status: "Ordered",
    },
    {
      prodName: "Egoras Dual Fuel",
      amount: 200000,
      prodBrand: "Egoras",
      Date: "21 MAY 2021",
      status: "Ordered",
    },
    {
      prodName: "Egoras Dual Fuel",
      amount: 200000,
      prodBrand: "Egoras",
      Date: "21 MAY 2021",
      status: "Ordered",
    },
    {
      prodName: "Egoras Dual Fuel",
      amount: 200000,
      prodBrand: "Egoras",
      Date: "21 MAY 2021",
      status: "Ordered",
    },
    {
      prodName: "Egoras Dual Fuel",
      amount: 200000,
      prodBrand: "Egoras",
      Date: "21 MAY 2021",
      status: "Ordered",
    },
    {
      prodName: "Egoras Dual Fuel",
      amount: 200000,
      prodBrand: "Egoras",
      Date: "21 MAY 2021",
      status: "Ordered",
    },
    {
      prodName: "Egoras Dual Fuel",
      amount: 200000,
      prodBrand: "Egoras",
      Date: "21 MAY 2021",
      status: "Ordered",
    },
    {
      prodName: "Egoras Dual Fuel",
      amount: 200000,
      prodBrand: "Egoras",
      Date: "21 MAY 2021",
      status: "Ordered",
    },
    {
      prodName: "Egoras Dual Fuel",
      amount: 200000,
      prodBrand: "Egoras",
      Date: "21 MAY 2021",
      status: "Ordered",
    },
    {
      prodName: "Egoras Dual Fuel",
      amount: 200000,
      prodBrand: "Egoras",
      Date: "21 MAY 2021",
      status: "Ordered",
    },
  ],
  referralLeaderBoard: [
    {
      id: 0,
      userName: "Cyntax",
      emailAddress: "samuelify225@gmail.com",
      refEarning: 100000,
      timeStamp: "22-10-23",
      refCount: "10",
    },
    {
      id: 1,
      userName: "devy",
      emailAddress: "devon25@gmail.com",
      refEarning: 95000,
      timeStamp: "22-10-23",
      refCount: "9",
    },
    {
      id: 2,
      userName: "blessed",
      emailAddress: "blessing25@gmail.com",
      refEarning: 80000,
      timeStamp: "22-10-23",
      refCount: "8",
    },
    {
      id: 3,
      userName: "Vixen",
      emailAddress: "victoria25@gmail.com",
      refEarning: 60000,
      timeStamp: "22-10-23",
      refCount: "7",
    },
    {
      id: 4,
      userName: "Kingzy",
      emailAddress: "kingsley25@gmail.com",
      refEarning: 40000,
      timeStamp: "22-10-23",
      refCount: "6",
    },
    {
      id: 5,
      userName: "stelzy",
      emailAddress: "stella25@gmail.com",
      refEarning: 35000,
      timeStamp: "22-10-23",
      refCount: "5",
    },
    {
      id: 6,
      userName: "Ebrinix",
      emailAddress: "EbriGoodness@gmail.com",
      refEarning: 20000,
      timeStamp: "22-10-23",
      refCount: "2",
    },
    {
      id: 7,
      userName: "Sharpman",
      emailAddress: "sharpman220@gmail.com",
      refEarning: 10000,
      timeStamp: "22-10-23",
      refCount: "1",
    },
    {
      id: 8,
      userName: "Lanxy",
      emailAddress: "Alex240@gmail.com",
      refEarning: 150000,
      timeStamp: "22-10-23",
      refCount: "15",
    },
    {
      id: 9,
      userName: "Tracy",
      emailAddress: "Tracy450@gmail.com",
      refEarning: 200000,
      timeStamp: "22-10-23",
      refCount: "25",
    },
    {
      id: 10,
      userName: "Harry",
      emailAddress: "Harrison450@gmail.com",
      refEarning: 250000,
      timeStamp: "22-10-23",
      refCount: "28",
    },
    {
      id: 11,
      userName: "User11",
      emailAddress: "user11@example.com",
      refEarning: 74790,
      timeStamp: "22-10-23",
      refCount: "13",
    },
    {
      id: 12,
      userName: "User12",
      emailAddress: "user12@example.com",
      refEarning: 27766,
      timeStamp: "22-10-23",
      refCount: "24",
    },
    {
      id: 13,
      userName: "User13",
      emailAddress: "user13@example.com",
      refEarning: 9156,
      timeStamp: "22-10-23",
      refCount: "23",
    },
    {
      id: 14,
      userName: "User14",
      emailAddress: "user14@example.com",
      refEarning: 57991,
      timeStamp: "22-10-23",
      refCount: "2",
    },
    {
      id: 15,
      userName: "User15",
      emailAddress: "user15@example.com",
      refEarning: 43339,
      timeStamp: "22-10-23",
      refCount: "15",
    },
    {
      id: 16,
      userName: "User16",
      emailAddress: "user16@example.com",
      refEarning: 40023,
      timeStamp: "22-10-23",
      refCount: "19",
    },
    {
      id: 17,
      userName: "User17",
      emailAddress: "user17@example.com",
      refEarning: 42716,
      timeStamp: "22-10-23",
      refCount: "5",
    },
    {
      id: 18,
      userName: "User18",
      emailAddress: "user18@example.com",
      refEarning: 37670,
      timeStamp: "22-10-23",
      refCount: "2",
    },
    {
      id: 19,
      userName: "User19",
      emailAddress: "user19@example.com",
      refEarning: 85195,
      timeStamp: "22-10-23",
      refCount: "25",
    },
    {
      id: 20,
      userName: "User20",
      emailAddress: "user20@example.com",
      refEarning: 92036,
      timeStamp: "22-10-23",
      refCount: "18",
    },
    {
      id: 21,
      userName: "User21",
      emailAddress: "user21@example.com",
      refEarning: 39718,
      timeStamp: "22-10-23",
      refCount: "10",
    },
    {
      id: 22,
      userName: "User22",
      emailAddress: "user22@example.com",
      refEarning: 29244,
      timeStamp: "22-10-23",
      refCount: "0",
    },
    {
      id: 23,
      userName: "User23",
      emailAddress: "user23@example.com",
      refEarning: 85795,
      timeStamp: "22-10-23",
      refCount: "17",
    },
    {
      id: 24,
      userName: "User24",
      emailAddress: "user24@example.com",
      refEarning: 61578,
      timeStamp: "22-10-23",
      refCount: "18",
    },
    {
      id: 25,
      userName: "User25",
      emailAddress: "user25@example.com",
      refEarning: 15168,
      timeStamp: "22-10-23",
      refCount: "8",
    },
    {
      id: 26,
      userName: "User26",
      emailAddress: "user26@example.com",
      refEarning: 15418,
      timeStamp: "22-10-23",
      refCount: "5",
    },
    {
      id: 27,
      userName: "User27",
      emailAddress: "user27@example.com",
      refEarning: 31488,
      timeStamp: "22-10-23",
      refCount: "17",
    },
    {
      id: 28,
      userName: "User28",
      emailAddress: "user28@example.com",
      refEarning: 18249,
      timeStamp: "22-10-23",
      refCount: "29",
    },
    {
      id: 29,
      userName: "User29",
      emailAddress: "user29@example.com",
      refEarning: 68400,
      timeStamp: "22-10-23",
      refCount: "28",
    },
    {
      id: 30,
      userName: "User30",
      emailAddress: "user30@example.com",
      refEarning: 13936,
      timeStamp: "22-10-23",
      refCount: "0",
    },
    {
      id: 31,
      userName: "User31",
      emailAddress: "user31@example.com",
      refEarning: 6750,
      timeStamp: "22-10-23",
      refCount: "29",
    },
    {
      id: 32,
      userName: "User32",
      emailAddress: "user32@example.com",
      refEarning: 10703,
      timeStamp: "22-10-23",
      refCount: "4",
    },
    {
      id: 33,
      userName: "User33",
      emailAddress: "user33@example.com",
      refEarning: 57737,
      timeStamp: "22-10-23",
      refCount: "27",
    },
    {
      id: 34,
      userName: "User34",
      emailAddress: "user34@example.com",
      refEarning: 60009,
      timeStamp: "22-10-23",
      refCount: "9",
    },
    {
      id: 35,
      userName: "User35",
      emailAddress: "user35@example.com",
      refEarning: 96604,
      timeStamp: "22-10-23",
      refCount: "22",
    },
    {
      id: 36,
      userName: "User36",
      emailAddress: "user36@example.com",
      refEarning: 95149,
      timeStamp: "22-10-23",
      refCount: "11",
    },
    {
      id: 37,
      userName: "User37",
      emailAddress: "user37@example.com",
      refEarning: 92500,
      timeStamp: "22-10-23",
      refCount: "13",
    },
    {
      id: 38,
      userName: "User38",
      emailAddress: "user38@example.com",
      refEarning: 77273,
      timeStamp: "22-10-23",
      refCount: "24",
    },
    {
      id: 39,
      userName: "User39",
      emailAddress: "user39@example.com",
      refEarning: 57657,
      timeStamp: "22-10-23",
      refCount: "13",
    },
    {
      id: 40,
      userName: "User40",
      emailAddress: "user40@example.com",
      refEarning: 68108,
      timeStamp: "22-10-23",
      refCount: "24",
    },
    {
      id: 41,
      userName: "User41",
      emailAddress: "user41@example.com",
      refEarning: 36752,
      timeStamp: "22-10-23",
      refCount: "0",
    },
    {
      id: 42,
      userName: "User42",
      emailAddress: "user42@example.com",
      refEarning: 34915,
      timeStamp: "22-10-23",
      refCount: "12",
    },
    {
      id: 43,
      userName: "User43",
      emailAddress: "user43@example.com",
      refEarning: 55899,
      timeStamp: "22-10-23",
      refCount: "20",
    },
    {
      id: 44,
      userName: "User44",
      emailAddress: "user44@example.com",
      refEarning: 3287,
      timeStamp: "22-10-23",
      refCount: "1",
    },
    {
      id: 45,
      userName: "User45",
      emailAddress: "user45@example.com",
      refEarning: 46605,
      timeStamp: "22-10-23",
      refCount: "3",
    },
    {
      id: 46,
      userName: "User46",
      emailAddress: "user46@example.com",
      refEarning: 45401,
      timeStamp: "22-10-23",
      refCount: "0",
    },
    {
      id: 47,
      userName: "User47",
      emailAddress: "user47@example.com",
      refEarning: 59870,
      timeStamp: "22-10-23",
      refCount: "11",
    },
    {
      id: 48,
      userName: "User48",
      emailAddress: "user48@example.com",
      refEarning: 50542,
      timeStamp: "22-10-23",
      refCount: "23",
    },
    {
      id: 49,
      userName: "User49",
      emailAddress: "user49@example.com",
      refEarning: 56897,
      timeStamp: "22-10-23",
      refCount: "16",
    },
    {
      id: 50,
      userName: "User50",
      emailAddress: "user50@example.com",
      refEarning: 73288,
      timeStamp: "22-10-23",
      refCount: "24",
    },
    {
      id: 51,
      userName: "User51",
      emailAddress: "user51@example.com",
      refEarning: 8228,
      timeStamp: "22-10-23",
      refCount: "12",
    },
    {
      id: 52,
      userName: "User52",
      emailAddress: "user52@example.com",
      refEarning: 50944,
      timeStamp: "22-10-23",
      refCount: "11",
    },
    {
      id: 53,
      userName: "User53",
      emailAddress: "user53@example.com",
      refEarning: 54422,
      timeStamp: "22-10-23",
      refCount: "18",
    },
    {
      id: 54,
      userName: "User54",
      emailAddress: "user54@example.com",
      refEarning: 548,
      timeStamp: "22-10-23",
      refCount: "18",
    },
    {
      id: 55,
      userName: "User55",
      emailAddress: "user55@example.com",
      refEarning: 70131,
      timeStamp: "22-10-23",
      refCount: "5",
    },
    {
      id: 56,
      userName: "User56",
      emailAddress: "user56@example.com",
      refEarning: 48374,
      timeStamp: "22-10-23",
      refCount: "0",
    },
    {
      id: 57,
      userName: "User57",
      emailAddress: "user57@example.com",
      refEarning: 5909,
      timeStamp: "22-10-23",
      refCount: "27",
    },
    {
      id: 58,
      userName: "User58",
      emailAddress: "user58@example.com",
      refEarning: 18261,
      timeStamp: "22-10-23",
      refCount: "7",
    },
    {
      id: 59,
      userName: "User59",
      emailAddress: "user59@example.com",
      refEarning: 97865,
      timeStamp: "22-10-23",
      refCount: "28",
    },
    {
      id: 60,
      userName: "User60",
      emailAddress: "user60@example.com",
      refEarning: 35800,
      timeStamp: "22-10-23",
      refCount: "6",
    },
    {
      id: 61,
      userName: "User61",
      emailAddress: "user61@example.com",
      refEarning: 74010,
      timeStamp: "22-10-23",
      refCount: "15",
    },
    {
      id: 62,
      userName: "User62",
      emailAddress: "user62@example.com",
      refEarning: 65376,
      timeStamp: "22-10-23",
      refCount: "13",
    },
    {
      id: 63,
      userName: "User63",
      emailAddress: "user63@example.com",
      refEarning: 73854,
      timeStamp: "22-10-23",
      refCount: "13",
    },
    {
      id: 64,
      userName: "User64",
      emailAddress: "user64@example.com",
      refEarning: 60015,
      timeStamp: "22-10-23",
      refCount: "26",
    },
    {
      id: 65,
      userName: "User65",
      emailAddress: "user65@example.com",
      refEarning: 64951,
      timeStamp: "22-10-23",
      refCount: "19",
    },
    {
      id: 66,
      userName: "User66",
      emailAddress: "user66@example.com",
      refEarning: 31769,
      timeStamp: "22-10-23",
      refCount: "5",
    },
    {
      id: 67,
      userName: "User67",
      emailAddress: "user67@example.com",
      refEarning: 25173,
      timeStamp: "22-10-23",
      refCount: "27",
    },
    {
      id: 68,
      userName: "User68",
      emailAddress: "user68@example.com",
      refEarning: 8389,
      timeStamp: "22-10-23",
      refCount: "8",
    },
    {
      id: 69,
      userName: "User69",
      emailAddress: "user69@example.com",
      refEarning: 6895,
      timeStamp: "22-10-23",
      refCount: "20",
    },
    {
      id: 70,
      userName: "User70",
      emailAddress: "user70@example.com",
      refEarning: 73498,
      timeStamp: "22-10-23",
      refCount: "4",
    },
    {
      id: 71,
      userName: "User71",
      emailAddress: "user71@example.com",
      refEarning: 94878,
      timeStamp: "22-10-23",
      refCount: "13",
    },
    {
      id: 72,
      userName: "User72",
      emailAddress: "user72@example.com",
      refEarning: 86891,
      timeStamp: "22-10-23",
      refCount: "28",
    },
    {
      id: 73,
      userName: "User73",
      emailAddress: "user73@example.com",
      refEarning: 62457,
      timeStamp: "22-10-23",
      refCount: "17",
    },
    {
      id: 74,
      userName: "User74",
      emailAddress: "user74@example.com",
      refEarning: 89438,
      timeStamp: "22-10-23",
      refCount: "4",
    },
    {
      id: 75,
      userName: "User75",
      emailAddress: "user75@example.com",
      refEarning: 78571,
      timeStamp: "22-10-23",
      refCount: "25",
    },
    {
      id: 76,
      userName: "User76",
      emailAddress: "user76@example.com",
      refEarning: 11673,
      timeStamp: "22-10-23",
      refCount: "12",
    },
    {
      id: 77,
      userName: "User77",
      emailAddress: "user77@example.com",
      refEarning: 23285,
      timeStamp: "22-10-23",
      refCount: "27",
    },
    {
      id: 78,
      userName: "User78",
      emailAddress: "user78@example.com",
      refEarning: 3222,
      timeStamp: "22-10-23",
      refCount: "29",
    },
    {
      id: 79,
      userName: "User79",
      emailAddress: "user79@example.com",
      refEarning: 69898,
      timeStamp: "22-10-23",
      refCount: "12",
    },
    {
      id: 80,
      userName: "User80",
      emailAddress: "user80@example.com",
      refEarning: 57169,
      timeStamp: "22-10-23",
      refCount: "23",
    },
    {
      id: 81,
      userName: "User81",
      emailAddress: "user81@example.com",
      refEarning: 11048,
      timeStamp: "22-10-23",
      refCount: "8",
    },
    {
      id: 82,
      userName: "User82",
      emailAddress: "user82@example.com",
      refEarning: 80860,
      timeStamp: "22-10-23",
      refCount: "20",
    },
    {
      id: 83,
      userName: "User83",
      emailAddress: "user83@example.com",
      refEarning: 31459,
      timeStamp: "22-10-23",
      refCount: "25",
    },
    {
      id: 84,
      userName: "User84",
      emailAddress: "user84@example.com",
      refEarning: 13405,
      timeStamp: "22-10-23",
      refCount: "4",
    },
    {
      id: 85,
      userName: "User85",
      emailAddress: "user85@example.com",
      refEarning: 25742,
      timeStamp: "22-10-23",
      refCount: "10",
    },
    {
      id: 86,
      userName: "User86",
      emailAddress: "user86@example.com",
      refEarning: 39797,
      timeStamp: "22-10-23",
      refCount: "2",
    },
    {
      id: 87,
      userName: "User87",
      emailAddress: "user87@example.com",
      refEarning: 35365,
      timeStamp: "22-10-23",
      refCount: "20",
    },
    {
      id: 88,
      userName: "User88",
      emailAddress: "user88@example.com",
      refEarning: 84097,
      timeStamp: "22-10-23",
      refCount: "13",
    },
    {
      id: 89,
      userName: "User89",
      emailAddress: "user89@example.com",
      refEarning: 37184,
      timeStamp: "22-10-23",
      refCount: "3",
    },
    {
      id: 90,
      userName: "User90",
      emailAddress: "user90@example.com",
      refEarning: 43261,
      timeStamp: "22-10-23",
      refCount: "18",
    },
    {
      id: 91,
      userName: "User91",
      emailAddress: "user91@example.com",
      refEarning: 86295,
      timeStamp: "22-10-23",
      refCount: "24",
    },
    {
      id: 92,
      userName: "User92",
      emailAddress: "user92@example.com",
      refEarning: 36734,
      timeStamp: "22-10-23",
      refCount: "17",
    },
    {
      id: 93,
      userName: "User93",
      emailAddress: "user93@example.com",
      refEarning: 49313,
      timeStamp: "22-10-23",
      refCount: "21",
    },
    {
      id: 94,
      userName: "User94",
      emailAddress: "user94@example.com",
      refEarning: 50962,
      timeStamp: "22-10-23",
      refCount: "26",
    },
    {
      id: 95,
      userName: "User95",
      emailAddress: "user95@example.com",
      refEarning: 79656,
      timeStamp: "22-10-23",
      refCount: "29",
    },
    {
      id: 96,
      userName: "User96",
      emailAddress: "user96@example.com",
      refEarning: 86857,
      timeStamp: "22-10-23",
      refCount: "6",
    },
    {
      id: 97,
      userName: "User97",
      emailAddress: "user97@example.com",
      refEarning: 99693,
      timeStamp: "22-10-23",
      refCount: "0",
    },
    {
      id: 98,
      userName: "User98",
      emailAddress: "user98@example.com",
      refEarning: 47681,
      timeStamp: "22-10-23",
      refCount: "12",
    },
    {
      id: 99,
      userName: "User99",
      emailAddress: "user99@example.com",
      refEarning: 79398,
      timeStamp: "22-10-23",
      refCount: "25",
    },
    {
      id: 100,
      userName: "User100",
      emailAddress: "user100@example.com",
      refEarning: 15496,
      timeStamp: "22-10-23",
      refCount: "26",
    },
  ],
  TransactionsDummy: [
    {
      id: 275,
      email: "ugojivincentuk@gmail.com",
      to_email: "samuelify225@gmail.com",
      meta: '{"symbol":"NGN","to_username":"cyntax","from_username":"Vincent_LivingLarge1"}',
      amount: "10.000000000000000000000000000000",
      type: "INTERNAL",
      status: "SUCCESS",
      approved_by: "N/A",
      createdAt: "2023-10-07T15:59:24.000Z",
      updatedAt: "2023-10-07T15:59:24.000Z",
      image: "https://i.imgur.com/JXm7zwC.png",
      current_price: "755.000000000000000000000000000000",
    },
    {
      id: 274,
      email: "samuelify225@gmail.com",
      to_email: "ugojivincentuk@gmail.com",
      meta: '{"symbol":"NGN","to_username":"Vincent_LivingLarge1","from_username":"cyntax"}',
      amount: "50.000000000000000000000000000000",
      type: "INTERNAL",
      status: "SUCCESS",
      approved_by: "N/A",
      createdAt: "2023-10-07T15:53:47.000Z",
      updatedAt: "2023-10-07T15:53:47.000Z",
      image: "https://i.imgur.com/JXm7zwC.png",
      current_price: "755.000000000000000000000000000000",
    },
    {
      id: 221,
      email: "samuelify225@gmail.com",
      to_email: "samuelify225@gmail.com",
      meta: '{"symbol":"NGN","txh":"b926298c11a342cbb5cd0ca6e9aedea3","confirmation":"3/3","network":"Watu","wallet_address":"n/a"}',
      amount: "100.000000000000000000000000000000",
      type: "Credit",
      status: "SUCCESS",
      approved_by: "N/A",
      createdAt: "2023-10-07T09:27:18.000Z",
      updatedAt: "2023-10-07T09:27:18.000Z",
      image: "https://i.imgur.com/JXm7zwC.png",
      current_price: "755.000000000000000000000000000000",
    },
    {
      id: 106,
      email: "hitechsuite@gmail.com",
      to_email: "samuelify225@gmail.com",
      meta: '{"symbol":"NGN","to_username":"cyntax","from_username":"wrapsticks"}',
      amount: "1.000000000000000000000000000000",
      type: "INTERNAL",
      status: "SUCCESS",
      approved_by: "N/A",
      createdAt: "2023-10-06T06:27:17.000Z",
      updatedAt: "2023-10-06T06:27:17.000Z",
      image: "https://i.imgur.com/JXm7zwC.png",
      current_price: "755.000000000000000000000000000000",
    },
    {
      id: 104,
      email: "hitechsuite@gmail.com",
      to_email: "samuelify225@gmail.com",
      meta: '{"symbol":"NGN","to_username":"cyntax","from_username":"wrapsticks"}',
      amount: "10.000000000000000000000000000000",
      type: "INTERNAL",
      status: "SUCCESS",
      approved_by: "N/A",
      createdAt: "2023-10-06T06:04:05.000Z",
      updatedAt: "2023-10-06T06:04:05.000Z",
      image: "https://i.imgur.com/JXm7zwC.png",
      current_price: "755.000000000000000000000000000000",
    },
    {
      id: 103,
      email: "egorasmarket@gmail.com",
      to_email: "samuelify225@gmail.com",
      meta: '{"symbol":"NGN","to_username":"cyntax","from_username":"harry"}',
      amount: "1000.000000000000000000000000000000",
      type: "INTERNAL",
      status: "SUCCESS",
      approved_by: "N/A",
      createdAt: "2023-10-06T05:29:39.000Z",
      updatedAt: "2023-10-06T05:29:39.000Z",
      image: "https://i.imgur.com/JXm7zwC.png",
      current_price: "755.000000000000000000000000000000",
    },
    {
      id: 102,
      email: "samuelify225@gmail.com",
      to_email: "egorasmarket@gmail.com",
      meta: '{"symbol":"NGN","to_username":"harry","from_username":"cyntax"}',
      amount: "5.000000000000000000000000000000",
      type: "INTERNAL",
      status: "SUCCESS",
      approved_by: "N/A",
      createdAt: "2023-10-06T05:24:30.000Z",
      updatedAt: "2023-10-06T05:24:30.000Z",
      image: "https://i.imgur.com/JXm7zwC.png",
      current_price: "755.000000000000000000000000000000",
    },
    {
      id: 86,
      email: "fortapphq@gmail.com",
      to_email: "samuelify225@gmail.com",
      meta: '{"symbol":"NGN","to_username":"cyntax","from_username":"Fortapp"}',
      amount: "70.000000000000000000000000000000",
      type: "INTERNAL",
      status: "SUCCESS",
      approved_by: "N/A",
      createdAt: "2023-10-05T19:54:25.000Z",
      updatedAt: "2023-10-05T19:54:25.000Z",
      image: "https://i.imgur.com/JXm7zwC.png",
      current_price: "755.000000000000000000000000000000",
    },
    {
      id: 80,
      email: "hitechsuite@gmail.com",
      to_email: "samuelify225@gmail.com",
      meta: '{"symbol":"NGN","to_username":"cyntax","from_username":"wrapsticks"}',
      amount: "10.000000000000000000000000000000",
      type: "INTERNAL",
      status: "SUCCESS",
      approved_by: "N/A",
      createdAt: "2023-10-05T11:14:13.000Z",
      updatedAt: "2023-10-05T11:14:13.000Z",
      image: "https://i.imgur.com/JXm7zwC.png",
      current_price: "755.000000000000000000000000000000",
    },
    {
      id: 79,
      email: "hitechsuite@gmail.com",
      to_email: "samuelify225@gmail.com",
      meta: '{"symbol":"NGN","to_username":"cyntax","from_username":"wrapsticks"}',
      amount: "10.000000000000000000000000000000",
      type: "INTERNAL",
      status: "SUCCESS",
      approved_by: "N/A",
      createdAt: "2023-10-05T11:12:42.000Z",
      updatedAt: "2023-10-05T11:12:42.000Z",
      image: "https://i.imgur.com/JXm7zwC.png",
      current_price: "755.000000000000000000000000000000",
    },
    {
      id: 78,
      email: "hitechsuite@gmail.com",
      to_email: "samuelify225@gmail.com",
      meta: '{"symbol":"NGN","to_username":"cyntax","from_username":"wrapsticks"}',
      amount: "10.000000000000000000000000000000",
      type: "INTERNAL",
      status: "SUCCESS",
      approved_by: "N/A",
      createdAt: "2023-10-05T11:09:42.000Z",
      updatedAt: "2023-10-05T11:09:42.000Z",
      image: "https://i.imgur.com/JXm7zwC.png",
      current_price: "755.000000000000000000000000000000",
    },
    {
      id: 75,
      email: "hitechsuite@gmail.com",
      to_email: "samuelify225@gmail.com",
      meta: '{"symbol":"NGN","to_username":"cyntax","from_username":"wrapsticks"}',
      amount: "20.000000000000000000000000000000",
      type: "INTERNAL",
      status: "SUCCESS",
      approved_by: "N/A",
      createdAt: "2023-10-05T10:35:06.000Z",
      updatedAt: "2023-10-05T10:35:06.000Z",
      image: "https://i.imgur.com/JXm7zwC.png",
      current_price: "755.000000000000000000000000000000",
    },
    {
      id: 62,
      email: "hitechsuite@gmail.com",
      to_email: "samuelify225@gmail.com",
      meta: '{"symbol":"NGN","to_username":"cyntax","from_username":"wrapsticks"}',
      amount: "10.000000000000000000000000000000",
      type: "INTERNAL",
      status: "SUCCESS",
      approved_by: "N/A",
      createdAt: "2023-10-03T19:39:35.000Z",
      updatedAt: "2023-10-03T19:39:35.000Z",
      image: "https://i.imgur.com/JXm7zwC.png",
      current_price: "755.000000000000000000000000000000",
    },
    {
      id: 61,
      email: "hitechsuite@gmail.com",
      to_email: "samuelify225@gmail.com",
      meta: '{"symbol":"NGN","to_username":"cyntax","from_username":"wrapsticks"}',
      amount: "10.000000000000000000000000000000",
      type: "INTERNAL",
      status: "SUCCESS",
      approved_by: "N/A",
      createdAt: "2023-10-03T18:25:23.000Z",
      updatedAt: "2023-10-03T18:25:23.000Z",
      image: "https://i.imgur.com/JXm7zwC.png",
      current_price: "755.000000000000000000000000000000",
    },
  ],
};
export default Staticdata;
