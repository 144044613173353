import React from "react";
import { Route, Routes } from "react-router-dom";
import CexRoutes from "./Routes";
import Signup from "./pages/Signup/Signup";
import { useSelector } from "react-redux";
import { Provider } from "react-redux";
import { store } from "./app/store";
import Login from "./pages/Login/Login";
import KycPage from "./pages/KYC/KycPage";
import DashboardWallets from "./pages/Wallet/DashboardWallets";
import Swap from "./pages/Swap/Swap";
import EmailVerification from "./pages/Verification/Appverification";
import Home from "./pages/Home/Index";
import DashboardReferral from "./pages/Referral/DashboardReferral";
import ResetPin from "./pages/ResetPin/ResetPin";
// import store from "./app/store";
const RouteConfig = () => {
  const { user } = useSelector((state) => state.auth);
  // console.log(localStorage.getItem("x-token"));
  // console.log("......");
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <CexRoutes
            // darkMode={darkMode}
            // togglemakeDarkUi={togglemakeDarkUi}
            />
          }
        >
          {/* <Route path="" element={<Swap />} /> */}

          {/* {localStorage.getItem("x-token") == null ? (
            <Route path="" element={<Home />} />
          ) : (
            <Route path="" element={<Swap />} />
          )} */}
          <Route path="" element={<Swap />} />
          <Route path="signup" element={<Signup />} />
          <Route path="earn" element={<DashboardReferral />} />
          <Route path="reset/pin" element={<ResetPin />} />
          <Route path="login" element={<Login />} />
          <Route path="kyc" element={<KycPage />} />
          <Route path="wallet" element={<DashboardWallets />} />
          <Route
            path="verify/email/address/:id"
            element={<EmailVerification />}
          />
        </Route>
      </Routes>
    </>
  );
};

export default RouteConfig;
